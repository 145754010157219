<a class="fest-item-wrapper" *ngIf="festivalItem" (click)="navigateFestival(festivalItem)">
  <div class="inner">
    <picture class="img-wrapper">
      <img [appCacheImage]="festivalItem.logoUrl" [alt]="festivalItem?.festival?.name">
    </picture>

    <div class="meta-block">
      <h3>
        {{festivalItem?.festival?.name}}
      </h3>

      <h4>
        {{festivalItem.dateInterval}}
      </h4>

      <h5 *ngIf="festivalItem.country">
        {{festivalItem.country}}
      </h5>
    </div>
  </div>
</a>

<a class="fest-item-wrapper" *ngIf="collectionItem" (click)="navigateCollection(collectionItem)">
  <div class="inner">
    <picture class="img-wrapper">
      <img [appCacheImage]="collectionItem.logoUrl" [alt]="collectionItem.name">
    </picture>

    <div class="meta-block">
      <h3>
        {{collectionItem.name}}
      </h3>
    </div>
  </div>
</a>
