import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from "@angular/router";
import { AuthenticationService } from "../authentications/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authenticationService.isAuthenticated) {
      try {
        await this.authenticationService.refreshToken().toPromise();
        if (!this.authenticationService.isAuthenticated) {
          this.router.navigateByUrl('/').then();
          return false;
        }
      } catch (error) {
        this.router.navigateByUrl('/').then();
        return false;
      }
    }
    return true;
  }
}
