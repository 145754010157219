import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { IFilmDto } from '../../dtos/film-dto';
import { IProvider } from '../../interfaces/provider.interface';
const NUMBER_OF_FILMS_TO_LOAD = 5;

@Component({
  selector: 'fspro-project-grid',
  templateUrl: './project-grid.component.html',
  styleUrls: ['./project-grid.component.scss']
})
export class ProjectGridComponent  {


  private _provider: IProvider;

  private _currentProviderSubscribe: Subscription;

  @Input() public set provider(value: IProvider) {
    if (value) {
      // Tobe sure that if the previous call still waiting for the result,
      // We should skip this.
      this._currentProviderSubscribe?.unsubscribe();
      this._provider = value;
      this.films = [];
      this.page = 1;
      this.fullyLoaded = false;
      this.loading = true;
      this.loadFilms();
    }
  };

  @Input() isFetchDataOnAction = false;

  public get provider(): IProvider {
    return this._provider;
  }

  @Input() public isAdmin: boolean = false;
  @Input() public translations: any;
  public films: IFilmDto[] = [];
  public loading: boolean = true;
  private fullyLoaded: boolean = false;
  private page: number = 1;

  constructor() { }

  loadFilms(): void {
     this._currentProviderSubscribe = this.provider.getEntities((this.page - 1) * NUMBER_OF_FILMS_TO_LOAD, NUMBER_OF_FILMS_TO_LOAD).subscribe(
      (data: IFilmDto[]) => {
        data.map((film) => this.films.push(film));
        this.loading = false;
        if (data.length === 0) {
          this.fullyLoaded = true;
        }
        this.page++;
      },
      (error) => console.log(error)
    );
  }

  onScroll(): void {
    if (!this.loading && !this.fullyLoaded) {
      this.loading = true;
      this.loadFilms();
    }
  }


}
