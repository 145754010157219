import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { finalize } from "rxjs/operators";
import { IFooterItem } from "src/app/dtos/footer-item.dto";
import { FooterService } from "src/app/services/footer.service";

@Component({
  selector: 'fspro-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isLoadingFooter = false;
  @Output() toggleContactEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  footerItems = [];

  public currentYear;

  constructor(private footerService: FooterService) {
    this.currentYear =  new Date().getFullYear();

  }
  ngOnInit(): void {
    this.isLoadingFooter = true;
    this.footerService.getFooter().pipe(finalize(() => this.isLoadingFooter = false)).subscribe((items: IFooterItem[]) => {
      this.footerItems = items.filter(i => i.is_active && i.is_footer).sort(function(a, b){return a.position - b.position});;
    });
  }



}
