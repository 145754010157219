import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFooterItem } from '../dtos/footer-item.dto';
import { parameters } from '../shared/parameters';

@Injectable()
export class FooterService {

constructor(private http: HttpClient) { }
private readonly FOOTER_URL = 'rest/dynamic-pages/footer';

getFooter(): Observable<IFooterItem[]>{
  return this.http.get<IFooterItem[]>(`${parameters.baseServiceUrl}${this.FOOTER_URL}`);
}

}
