import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FSProService } from './fspro-service';
import { parameters } from '../parameters';
import { IUser } from '../entities/user';
import { IUserNew } from "../entities/user.new";
import { MustVerifyCodeDto } from '../dtos/must-verify-code-dto';


@Injectable()
export class UserService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/users';

  constructor(private http: HttpClient) {
    super();
  }

  shouldVerify(): Observable<boolean> {
    return this.http.get<{should_verify: boolean}>(this.urlBase + '/should-verify').pipe(map(data => {
      return data.should_verify;
    }));
  }

  mustChangePassword(): Observable<boolean> {
    return this.http.get<{must_change_password: boolean}>(this.urlBase + '/must-change-password').pipe(map(data => {
      return data.must_change_password;
    }));
  }

  /**
   * Saves a new user based on given form data
   */
  saveUser(user: IUser): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(this.urlBase, user).pipe(
      map(data => data)
    );
  }

  /**
   * Request user update with form data
   */
  requestUserUpdate(user: IUser): Observable<any> {
    return this.http.post<IUser>(this.urlBase + '/request-update', user);
  }

  /**
   * Update user subscription to the newsletter
   */
  newsletterSubscription(formData: FormData): Observable<IUser> {
    return this.http.post<IUser>(this.urlBase + '/newsletter-subscribe', formData);
  }


  public requestPasswordResetLink(email: string): Observable<any> {
    return this.http.post<any>(this.urlBase + '/request-reset-password-link', { 'email': email }, { headers: this.skipRouteCheckHeaders });
  }

  public changePasswordWithLink(emailBase64Encoded: string, userHash: string, password: string): Observable<any> {
    return this.http.post<any>(this.urlBase + '/change-password-with-link', {
      'emailBase64Encoded': emailBase64Encoded,
      'userHash': userHash,
      'password': password
    });
  }

  public requestActiveAccount(password: string, token: string): Observable<any> {
    return this.http.put<any>(this.urlBase + '/confirm', { password }, { headers: { Authorization: `Bearer ${token}` } });
  }

  public authenticate(emailBase64Encoded: string, userHash: string): Observable<{ token: string }> {
    return this.http.get<{ token: string }>(this.urlBase + `/authenticate/${emailBase64Encoded}/${userHash}`);
  }

  public canAccessBookingFeature(): Observable<{ access: boolean }> {
    return this.http.get<{ access: boolean }>(this.urlBase + '/can-access-booking-feature');
  }

  public getCurrentUserDetails(): Observable<IUserNew> {
    return this.http.get<IUser>(this.urlBase + '/me');
  }

  /**
   * Verify user with form data
   */
  verifyUser(user: IUser): Observable<any> {
    return this.http.post<IUser>(this.urlBase + '/verify-user', user);
  }

  public checkUserDevice(email: string): Observable<MustVerifyCodeDto> {
    return this.http.post<MustVerifyCodeDto>(this.urlBase + '/check-user-device', {email});
  }

  public verifyCode(code: string, email: string): Observable<any> {
    return this.http.post<any>(this.urlBase + '/verify-code', { 'code': code, email: email });
  }

  public logUserAuthentication(): Observable<any> {
    return this.http.get<any>(this.urlBase + '/log-user-authentication');
  }
}
