import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';


@Component({
  selector: 'fspro-forgot-password',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})

export class ForgotPasswordPageComponent implements OnInit {
  @Input() translations: any;
  constructor(private _userService: UserService,
    private el: ElementRef,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private readonly router: Router) { }

  public forgotForm: FormGroup;

  public isSending = false;
  ngOnInit(): void {

    this.forgotForm = new FormGroup({

      emailField: new FormControl('', [Validators.required, Validators.email])

    });

  }

  public onSubmit(): void {
    this.forgotForm.markAllAsTouched();
    if (this.forgotForm.valid) {
      this.isSending = true;

      const email = this.forgotForm?.value['emailField'] || '';

      this._userService.requestPasswordResetLink(email).pipe(finalize(() => this.isSending = false)).subscribe(() => {
        this.alertMessage(this.translateService.instant('fspro.front.forgotten_password.form.message_sent'), true);
      }, (httpError: HttpErrorResponse) => {
        this.alertMessage(httpError?.error?.message || this.translateService.instant('fspro.front.forgotten_password.form.user_not_found'))
      });
    }

    this.autoFocusInValidField();
    return;
  }

  private alertMessage(message: string, redirect = false) {
    this.snackbar.open(
      message,
      'Close',
      { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
    ).afterDismissed().subscribe(() => {
      if (redirect) {
       this.router.navigate(['/']);
      }
    });
  }

  private autoFocusInValidField(): void {
    for (const key of Object.keys(this.forgotForm.controls)) {
      if (this.forgotForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }
}

