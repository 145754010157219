<div [class.modal--open]="isToggled" [class.joinform__popup--open]="isToggled" [class.joinform__popup--responsive--open]="isToggled" class="joinform__popup joinform__popup--responsive modal">
  <div class="joinform__popup__content joinform__popup__content--responsive">
    <form *ngIf="sendMailForm" [formGroup]="sendMailForm" (ngSubmit)="onSubmit()" class="recaptcha-form">
      <header class="reports__header">
        <h1 class="reports__title">Contact Us</h1>
      </header>
      <div role="group" class="joinform__fieldset">
        <div class="joinform__fieldgroup joinform__fieldgroup--full    ">
          <input name="email" type="email" id="feedback_email_first" formControlName="email" class="joinform__input" required [class.joinform__input--nonempty]="hasValue('email')" autocomplete="off">
          <label class="joinform__label" for="feedback_email_first">E-mail<sup class="joinform__required">*</sup></label>
        </div>
        <div class="joinform__fieldgroup joinform__fieldgroup--full    ">
          <input name="confirmEmail" type="email" id="feedback_email_second" formControlName="confirmEmail" required class="joinform__input" [class.joinform__input--nonempty]="hasValue('confirmEmail')" autocomplete="off">
          <label class="joinform__label" for="feedback_email_second">E-mail confirmation<sup class="joinform__required">*</sup></label>
        </div>

        <div class="joinform__fieldgroup">
          <input name="firstName" type="text" id="feedback_firstName" formControlName="firstName" class="joinform__input" [class.joinform__input--nonempty]="hasValue('firstName')" autocomplete="off">
          <label class="joinform__label" for="feedback_firstName">First Name</label>
        </div>

        <div class="joinform__fieldgroup">
          <input name="lastName" type="text" id="feedback_lastName" formControlName="lastName" class="joinform__input" [class.joinform__input--nonempty]="hasValue('lastName')" autocomplete="off">
          <label class="joinform__label" for="feedback_lastName">Last Name</label>
        </div>

        <div class="joinform__fieldgroup joinform__fieldgroup--full">
          <textarea name="body" formControlName="body" id="feedback_body" required class="joinform__input joinform__input--textarea resizing" [style.height]="'auto'" autocomplete="off"></textarea>
          <label class="joinform__label" for="feedback_body">Please enter your message here<sup class="joinform__required">*</sup></label>
        </div>

        <div class="joinform__fieldgroup joinform__fieldgroup--center joinform__fieldgroup--full"> </div>
        <div class="joinform__fieldgroup">
          <label class="formcontrol">
            <input name="isSendCopy" type="checkbox" formControlName="isSendACopy" class="formcontrol__input formcontrol__input--nonempty" value="1">
            <span class="formcontrol__indicator formcontrol__indicator--checkbox"></span>
            <span class="formcontrol__text">Send me a copy</span>
          </label>
        </div>
        <div class="joinform__popup__actionbuttonswrap">
          <nav class="joinform__popup__actionbuttons">
            <button type="button" (click)="dismiss()" class="joinform__popup__close joinform__popup__close--responsive joinform__popup__anactionbutton">Close</button>
            <button type="submit" [class.joinform__nextbutton--disabled]="sendMailForm.invalid" class="joinform__nextbutton joinform__popup__anactionbutton">Send</button>
          </nav>
        </div>
      </div>
    </form>
  </div>
</div>
