import { AfterViewChecked, Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: 'input[AutoFocus]'
})
export class AutoFocusDirective implements AfterViewChecked {

  constructor(private readonly el: ElementRef) { }
  ngAfterViewChecked(): void {

    var myElement = (this.el.nativeElement as HTMLElement).querySelector('input');

    if (!myElement) {

      this.el.nativeElement.focus();

    } else {

      myElement?.focus();

    };

  }

  // ngOnInit(): void {

  //   //(this.el.nativeElement as HTMLElement).querySelector('input')?.focus();

  // }

}
