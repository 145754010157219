import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentications/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserBlockedGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(): Observable<boolean> {
    if (this.authenticationService.isAuthenticated) {
      return forkJoin([
        this.userService.shouldVerify(),
        this.userService.mustChangePassword()
      ]).pipe(
        map(([shouldVerify, mustChangePassword]) => {
          if (shouldVerify) {
            this.router.navigate(['/my-account', 'verify']);
            return false;
          } else if (mustChangePassword) {
            this.router.navigate(['/my-account', 'must-change-password']);
            return false;
          } else {
            return true;
          }
        })
      );
    } else {
      return of(true);
    }
  }
}
