import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { parameters } from '../shared/parameters';
import { CookieService } from "ngx-cookie-service";
import { IUserDto } from '../shared/dtos/user-dto';
import { FSProService } from "../shared/services/fspro-service";

@Injectable()
export class AuthenticationService  extends FSProService {
  private readonly _token$ = new BehaviorSubject<string>(undefined);
  private readonly _user$ = new BehaviorSubject<IUserDto>(undefined);
  public _impersonation$ = new BehaviorSubject<string | null>(null);
  public hasTryToRefresh = new BehaviorSubject<Date | null>(null);
  public static IMPERSONATION_COOKIE_NAME = '_wanna_be';
  public static ACCESS_TOKEN_NAME = 'jwt_hp';
  public static ACCESS_TOKEN_SIGNATURE_NAME = 'jwt_s';
  public static REFRESH_TOKEN_NAME = 'refresh_token';

  get token(): string {
    return this._token$.getValue() || this.cookieService.get(AuthenticationService.ACCESS_TOKEN_NAME);
  }

  get user() {
    return this._user$.getValue() || this.getTokenInfo(this.token);
  }

  get isAuthenticated(): boolean {
    if (!this.token) {
      return false;
    }
    return this.isCurrentTokenValid();
  }

  get isUserType() {
    return this.user?.userType === 'user';
  }

  get isRhContact() {
    return this.user?.userType === 'rhContact';
  }

  get isAdmin(): boolean {
    return this.hasAnyRoles('ROLE_ADMIN', 'ROLE_SUPER_ADMIN');
  }


  get isApproved(): boolean {
    return this.user?.isApproved;
  }

  public hasAnyRoles(...roles: string[]): boolean {
    return this.user?.roles && this.user.roles.some(_role => (roles || []).includes(_role));
  }

  public hasRole(role: string): boolean {
    return this.user?.roles?.includes(role);
  }

  constructor(private readonly http: HttpClient, private cookieService: CookieService) {
    super();
    const impersonation = this.cookieService.get(AuthenticationService.IMPERSONATION_COOKIE_NAME);
    if (impersonation !== null && impersonation !== '') {
      this._impersonation$.next(impersonation.split('|')[0]);
    }
  }

  authenticate(credentials: unknown): Observable<unknown> {
    return this.http.post(`${parameters.baseServiceUrl}api/login_check`, credentials).pipe(
      tap(() => {
        const token = this.cookieService.get(AuthenticationService.ACCESS_TOKEN_NAME);
        this.setToken(token);
      })
    );
  }

  authenticateInvitation(): void {
    const token = this.cookieService.get(AuthenticationService.ACCESS_TOKEN_NAME);
    this.setToken(token);
  }

  refreshToken(): Observable<unknown> {
    return this.http.post(`${parameters.baseServiceUrl}api/token/refresh`, {}, { headers: this.skipRouteCheckHeaders }).pipe(
      tap(() => {
        const token = this.cookieService.get(AuthenticationService.ACCESS_TOKEN_NAME);
        this.setToken(token);
      })
    );
  }

  public isRhUser(): boolean {
    if (this._impersonation$.getValue()) {
      return this.cookieService.get(AuthenticationService.IMPERSONATION_COOKIE_NAME).split('|')[1] === 'true';
    } else {
      return this.hasRole('ROLE_RHLU');
    }
  }

  /**
   * Store the token into the localStorage
   * @param token JWT token
   */
  public setToken(token: string) {
    const user = this.getTokenInfo(token);
    if (user) {
      this._token$.next(token);
      this._user$.next(user);
    }
  }

  async signOut(): Promise<void> {
    await this.http.get<any>('/api/token/invalidate').toPromise().then((response) => {
    }, (error) => {
      console.log(error);
    });
    this.cookieService.delete(AuthenticationService.ACCESS_TOKEN_NAME, '/');
    this._user$.next(undefined);
    this._token$.next(undefined);
  }

  private getTokenInfo(token: string): IUserDto {

    if (!token) return null;

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  exitImpersonation() {
    this.cookieService.delete(AuthenticationService.IMPERSONATION_COOKIE_NAME, '/');
    window.location.replace('/admin/users/');
  }

  private isCurrentTokenValid(): boolean {
    const { exp } = this.user;
    return exp * 1000 - new Date().getTime() > 0;
  }
}
