<div class="fest-vertical-list" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
  <ng-container *ngIf="listData.length > 0">
    <fspro-festival-card *ngFor="let item of listData" [collectionItem]="itemType === 'collection' ? toCollection(item) : null"
      [festivalItem]="itemType === 'festival' ? toFestival(item) : null"
    ></fspro-festival-card>
  </ng-container>

  <ng-container *ngIf="loading">
    <fspro-skeleton-festival-card *ngFor="let _ of [].constructor(listData.length === 0 ? 6 : listData.length % 3 === 0 ? 3 : 1)"></fspro-skeleton-festival-card>
  </ng-container>
</div>
