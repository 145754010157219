import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authentications/authentication.service';
import { IFilmBookingsDto } from '../dtos/booking-films-dto';
import { ICountryDto } from '../dtos/country-dto';
import { IFilmDto } from '../dtos/film-dto';
import { ISectionDto } from '../dtos/section-dto';
import { FilterType, IFilter } from '../entities/filter';
import { parameters } from '../parameters';
import { FSProService } from './fspro-service';

@Injectable()
export class FilmBookingService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/booking-requests/';

  constructor(private http: HttpClient, private readonly authenticationService: AuthenticationService) {
    super();
  }

  createBooking(data: IFilmBookingsDto): Observable<IFilmBookingsDto> {
    return this.http.post<IFilmBookingsDto>(this.urlBase, data);
  }

  updateBooking(data: IFilmBookingsDto): Observable<IFilmBookingsDto> {
    return this.http.put<IFilmBookingsDto>(`${this.urlBase}${data.id!}`, data);
  }

  getBookingFilms(start: number, limit: number, filters: IFilter[] = null): Observable<IFilmBookingsDto[]> {
    let fullUrl = this.buildListUrl(`${this.urlBase}`, start, limit, null, null, filters);
    return this.http.get<IFilmBookingsDto[]>(fullUrl);
  }

  getNoDecisionBookingFilmByFilmSlug(filmSlug: string): Observable<IFilmBookingsDto> {
    let fullUrl = `${this.urlBase}slug/${filmSlug}`;
    return this.http.get<IFilmBookingsDto>(fullUrl, { headers: this.skipRouteCheckHeaders });
  }

  uploadRequirement(bookingId: number, formData: FormData): Observable<unknown> {
    return this.http.post<unknown>(`${this.urlBase}/upload-requirement-file/${bookingId}`, formData).pipe(
      map(data => data)
    );
  }

  getBookingRequests(start: number, limit: number, filters: IFilter[] = null): Observable<IFilmBookingsDto[]> {
    let fullUrl = this.buildListUrl(`${this.urlBase}right-holders`, start, limit, null, null, filters);
    return this.http.get<IFilmBookingsDto[]>(fullUrl, { headers: this.skipRouteCheckHeaders });
  }

  getCountries(): Observable<ICountryDto[]> {
    const url = `${this.urlBase}right-holders/countries`;
    return this.http.get<ICountryDto[]>(url);
  }

  getFilms(): Observable<IFilmDto[]> {
    const url = `${this.urlBase}right-holders/films`;
    return this.http.get<IFilmDto[]>(url);
  }

  getStatuses(): Observable<unknown[]> {
    const url = `${this.urlBase}right-holders/statuses`;
    return this.http.get<unknown[]>(url);
  }

  getSections(): Observable<ISectionDto[]> {
    const url = `${this.urlBase}right-holders/sections`;
    return this.http.get<ISectionDto[]>(url);
  }

  getPendingRequest(): Observable<any> {
    const url = `${this.urlBase}right-holders/count/pending`;
    return this.http.get<any>(url);
  }

  getExportFileUrl(filters: IFilter[]): string {
    let params = new HttpParams();
    if (filters !== null) {
      for (const filter of filters) {
        params = params.set((filter.type == FilterType.DoNotMatch ? '-' : '') + filter.field, filter.value);
      }
    }
    /* if (this.authenticationService.isAuthenticated) {
      params = params.append('bearer', this.authenticationService.token);
    } */
    if (this.authenticationService._impersonation$.value) {
      params = params.append('X-Switch-User', this.authenticationService._impersonation$.value);
    }

    return `${this.urlBase}export?${params.toString()}`;
  }

  getExportPdfFileUrl(id: number): Observable<string> {
    return this.authenticationService.refreshToken().pipe(
      map(() => {
        let params = new HttpParams();

        if (this.authenticationService._impersonation$.value) {
          params = params.append('X-Switch-User', this.authenticationService._impersonation$.value);
        }

        return `${this.urlBase}${id}/pdf-export?${params.toString()}`;
      })
    );
  }

  approveRequest(id: number, comment: string|null): Observable<any> {
    const url = `${this.urlBase}${id}/approve`;
    return this.http.put<any>(url, {comment});
  }

  denyRequest(id: number, comment: string|null): Observable<any> {
    const url = `${this.urlBase}${id}/deny`;
    return this.http.put<any>(url, {comment});
  }

  getDateList(filters: IFilter[] = null): Observable<{ creationDateFormatted: string }[]> {
    let fullUrl = this.buildListUrl(`${this.urlBase}date-list`, 0, 0, null, null, filters);
    return this.http.get<any>(fullUrl);
  }

  sendMessage(bookingId: number, body: string, sendCopy: boolean) {
    let fullUrl = `${this.urlBase}contact/${bookingId}`
    return this.http.post(fullUrl, {
      body: body,
      sendCopy: sendCopy
    })
  }

  sendMessageToOperator(bookingId: number, body: string, sendCopy: boolean) {
    let fullUrl = `${this.urlBase}contact-operator/${bookingId}`
    return this.http.post(fullUrl, {
      body: body,
      sendCopy: sendCopy
    })
  }

  getBookingById(bookingId: number): Observable<IFilmBookingsDto> {
    let fullUrl = `${this.urlBase}${bookingId}`;
    return this.http.get<IFilmBookingsDto>(fullUrl, { headers: this.skipRouteCheckHeaders });
  }
}
