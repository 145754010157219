import { HttpHeaders, HttpParams } from '@angular/common/http';
import { FilterType, IFilter } from '../entities/filter';
import { ISort, SortDirection } from '../entities/sort';

export class FSProService {

  /**
   * Converts all the date fields from an invitation campaign from string to Date object
   *
   * @param obj
   */
  protected convertDates(obj: any) {

    if (obj.createdAt != null) {
      obj.createdAt = new Date(obj.createdAt);
    }

    if (obj.updatedAt != null) {
      obj.updatedAt = new Date(obj.updatedAt);
    }

    if (obj.expiresAt != null) {
      obj.expiresAt = new Date(obj.expiresAt);
    }
  }

  /**
   * Builds a list url based on the given parameters
   *
   * @param baseUrl
   * @param start
   * @param limit
   * @param sort
   * @param search
   * @param filters
   */
  public buildListUrl(baseUrl: string, start: number, limit: number, sort: ISort | ISort[], search: string, filters: IFilter[]): string {

    let params = new HttpParams();

    if (start != 0) {
      params = params.set('start', start.toString());
    }

    if (limit != 0) {
      params = params.set('limit', limit.toString());
    }

    if (sort instanceof Array) {
      params = params.set('sort', sort.map(item => (item.direction == SortDirection.Desc ? '-' : '') + item.field).join(','));

    } else if (sort != null && sort.direction != SortDirection.None) {
      params = params.set('sort', (sort.direction == SortDirection.Desc ? '-' : '') + sort.field);
    }

    if (search != null && search != '') {
      params = params.set('search', '%' + search + '%');
    }

    if (filters != null) {
      for (const filter of filters) {
        params = params.set((filter.type == FilterType.DoNotMatch ? '-' : '') + filter.field, filter.value);
      }
    }

    return `${baseUrl}?${params.toString()}`;
  }

  /**
   * The header called skip-route-check with a value of true to the HTTP request,
   * and then check for this header in the interceptor.
   * If the header is present and has a value of true, the interceptor can skip the route redirect binding
   * Such as the 404 not found page, 403 page
   * @returns HttpHeaders with 'skip-route-check', 'true'
   */
  get skipRouteCheckHeaders() {
    return new HttpHeaders().set('skip-route-check', 'true');
  }


}
