<button mat-icon-button disableRipple class="close-btn" (click)="onClose()"></button>

<form class="form-wrapper" [formGroup]="sendNoteForm" (ngSubmit)="onSubmit()">
  <header class="section-header">
    <h1>{{'fspro.front.film.reports.label.title' | translate }}</h1>
  </header>

  <div class="field-wrapper">
    <div class="fspro-control-input-wrapper" *ngIf="!isLoading">
      <mat-form-field>
        <mat-label>{{'fspro.front.film.reports.label.message' | translate}}</mat-label>
        <textarea
          name="notes"
          matInput
          formControlName="notes"
          autocomplete="off"
          autocapitalize="off"
          cdkTextareaAutosize
          class="input-note"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="fspro-control-input-wrapper" *ngIf="isLoading">
      <ngx-skeleton-loader appearance="line" animation="pulse"
        [theme]="{ minWidth: '30px', minHeight: '50px', height: '100%', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
      </ngx-skeleton-loader>
    </div>
  </div>


  <div class="action-button" style="justify-content: center;">
    <div class="inner btn-wrapper">
      <button class="btn-cancel" type="button" (click)="onClose()">Back</button>
      <button class="btn-send" type="submit" [disabled]="isSending" >{{'Save' | translate }}</button>
    </div>
  </div>
</form>
