import { Component, ElementRef, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { IFilmNoteDto } from "../../dtos/film-note-dto";
import { FilmNoteService } from "../../services/film-note.service";


@Component({
  selector: 'fspro-note-dialog',
  templateUrl: 'note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']
})

export class NoteDialog {

  public sendNoteForm: FormGroup;

  private _destroyed = new Subject<void>();

  isLoading = true;
  isSending = false;

  constructor(public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { slug: string },
    private filmNoteService: FilmNoteService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private el: ElementRef) {
    filmNoteService.getFilmNote(data?.slug).pipe(takeUntil(this._destroyed), finalize(() => this.isLoading = false)).subscribe((data: IFilmNoteDto) => {
      this.sendNoteForm.setValue({ notes: data.notes });
    },
      (error) => {
        // TODO NEED TO update API
      });

    this.sendNoteForm = new FormGroup({
      notes: new FormControl('', Validators.required)
    });

  }

  onSubmit() {
    if (this.sendNoteForm.valid) {
      this.isSending = true;

      this.filmNoteService.setFilmNote(this.data?.slug, this.sendNoteForm.value).subscribe(() => {
        this.snackbar.open(
          this.translateService.instant('The note was successfully saved!'),
          'Close',
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
        );
        this.onClose(this.sendNoteForm.value.notes);
        () => {
          this.snackbar.open(
            this.translateService.instant('Cannot saved the note!'),
            'Close',
            { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
          );
          this.onClose();
        }
      });

    }
  }

  onClose(value: string = null): void {
    this._destroyed.next();
    this._destroyed.complete();
    this.dialogRef.close(value);
  }

  private autoFocusInValidField(): void {
    for (const key of Object.keys(this.sendNoteForm.controls)) {
      if (this.sendNoteForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }
}
