<mat-form-field appearance="outline" *ngIf="sections.length > 0">
  <mat-select [(ngModel)]="selectedTabIndex">
    <mat-option *ngFor="let section of sections; let i = index" [value]="i"  >
      {{ section.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-tab-group
  matMultilineTab
  class="{{ sections.length >= 8 ? 'align-left' : '' }} {{ inkBarColorClass !== null ? inkBarColorClass : '' }} {{ sections.length === 1 ? 'single' : '' }}"
  mat-align-tabs="center"
  dynamicHeight *ngIf="this.sections.length > 0"
  selectedIndex="{{ selectedTabIndex !== null ? selectedTabIndex : 0 }}"
  (selectedTabChange)="onTabChange($event)"
>
  <mat-tab *ngFor="let section of sections" label="{{ section.name }}">
    <ng-template mat-tab-label>
      <span class="tab-special">
        {{ section.name }}
      </span>
    </ng-template>
    <ng-template matTabContent>
      <section>
        <div class="description" markdown [data]="section.description" *ngIf="section.description !== null && section.description !== ''"></div>
      </section>
      <section *ngIf="orderedAwards.length > 0">
        <ul class="awards">
          <li *ngFor="let orderedAward of orderedAwards">
            <a [routerLink]="orderedAward.isClickable ? orderedAward.filmSlug : null">
              <span class="jury-name">{{ orderedAward?.filmTitle }}</span>
              <ng-container *ngFor="let award of orderedAward?.filmAwards; let index = index">
                <span class="award">{{ award.name }}</span> <ng-container *ngIf="orderedAward?.filmAwards.length > index + 1"><span class="delimiter">|</span></ng-container>
              </ng-container>
            </a>
          </li>
        </ul>
      </section>
      <fspro-project-grid
      *ngIf="provider$ | async"
      [provider]="provider$ | async">
      </fspro-project-grid>
    </ng-template>
  </mat-tab>
</mat-tab-group>
