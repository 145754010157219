import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FilmService } from "./film.service";

export interface IEmailMessageRequest {
  subject: string;
  message: string;
  isSendCopy: boolean;
}

export interface IEmailMessageResponse {
  code: string | number;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class SendMessageService {
  constructor(private readonly filmService: FilmService) {
  }

  sendAMessageToCompany(emailMessage: IEmailMessageRequest, companyId: number, filmId: number): Observable<IEmailMessageResponse> {
    return this.filmService.sendContactFilmCompany(emailMessage, filmId, companyId);
  }

  sendMessageToDirector(emailMessage: IEmailMessageRequest, directorSlug: string) {
    return this.filmService.sendMessageToDirector(emailMessage, directorSlug);
  }

  sendMessageToTalent(emailMessage: IEmailMessageRequest, directorSlug: string) {
    return this.filmService.sendMessageToTalent(emailMessage, directorSlug);
  }

}
