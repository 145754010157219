import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFilterData } from '../components/filter-group/filter-group.component';
import { IFilmNoteDto } from '../dtos/film-note-dto';
import { IFilter } from '../entities/filter';
import { parameters } from '../parameters';
import { FSProService } from './fspro-service';

@Injectable()
export class FilmNoteService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/film-notes';

  constructor(private http: HttpClient) {
    super();
  }

  searchFilmNotes(start: number, limit: number, filters: IFilter[] = null): Observable<IFilmNoteDto[]> {
    let fullUrl = this.buildListUrl(this.urlBase + '/hydrated', start, limit, null, null, filters);
    return this.http.get<IFilmNoteDto[]>(fullUrl);
  }

  public getFilms(): Observable<IFilterData[]> {
    const fullUrl = `${this.urlBase}/films`;
    return this.http.get(fullUrl).pipe(map((data: any) => data.map(x => ({
      id: x.id,
      name: x.internationalTitle
    }) as IFilterData)));

  }

  public getPeriods(): Observable<IFilterData[]> {
    const fullUrl = `${this.urlBase}/periods`;
    return this.http.get<IFilterData[]>(fullUrl);

  }


  /**
   * Return film's note by given slug
   * @param slug film slug
   */
  public getFilmNote(slug: string): Observable<IFilmNoteDto> {
    const fullUrl = `${this.urlBase}/${slug}`;

    return this.http.get<IFilmNoteDto>(fullUrl,  { headers: this.skipRouteCheckHeaders });

  }

  /**
   * Set a note to given film slug
   * @param slug film slug
   * @param body
   * @returns
   */
  public setFilmNote(slug: string, body: { notes: string }): Observable<IFilmNoteDto> {

    const fullUrl = `${this.urlBase}/${slug}`;


    return this.http.post<IFilmNoteDto>(fullUrl, body);

  }

  public removeFilmNote(slug: Number): Observable<IFilmNoteDto> {
    const fullUrl = `${this.urlBase}/${slug}`;

    return this.http.delete<IFilmNoteDto>(fullUrl);

  }

}
