import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { finalize, switchMap } from "rxjs/operators";
import { UserService } from "src/app/shared/services/user.service";
import { mustMatchValidator } from "src/app/shared/validators/custom-validators";
import { passwordPatternValidator } from "../../shared/validators/password-pattern.validator";

@Component({
  selector: 'fspro-active-account',
  templateUrl: './active-account.component.html',
  styleUrls: ['./active-account.component.scss']
})
export class ActiveAccountComponent {
  public activeForm: FormGroup;
  public isSending = false;

  constructor(private _userService: UserService,
    private readonly el: ElementRef,
    private readonly snackbar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.activeForm = new FormGroup({
      passwordField: new FormControl('', [
        Validators.required,
        Validators.minLength(12),
        passwordPatternValidator
      ]),
      confirmPasswordField: new FormControl('', [Validators.required]),
      isConfirmTermAndCondition: new FormControl(false, [Validators.requiredTrue])
    }, { validators: mustMatchValidator });
  }

  public onSubmit(): void {

    this.activeForm.markAllAsTouched();

    if (this.activeForm.valid) {
      this.isSending = true;
      const { encodedEmail, userHash } = this.activatedRoute.snapshot.params;
      const { passwordField } = this.activeForm.getRawValue();
      this._userService.authenticate(encodedEmail, userHash).pipe(
        switchMap(({ token }) => {
          return this._userService.requestActiveAccount(passwordField, token);
        }),
        finalize(() => this.isSending = false)
      ).subscribe(() => {
        this.alertMessage(this.translateService.instant('fspro.front.active-account.form.message_sent'), true);
      }, (httpError: HttpErrorResponse) => {
        this.alertMessage(httpError?.error?.message || this.translateService.instant('fspro.front.active-account.form.user_not_found'))
      });
    }

    this.autoFocusInValidField();
    return;
  }

  private alertMessage(message: string, redirect = false) {
    this.snackbar.open(
      message,
      'Close',
      { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
    ).afterDismissed().subscribe(() => {
      if (redirect) {
        this.router.navigate(['/']);
      }
    });
  }

  private autoFocusInValidField(): void {
    for (const key of Object.keys(this.activeForm.controls)) {
      if (this.activeForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }
}
