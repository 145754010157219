import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISort } from '../entities/sort';
import { FSProService } from './fspro-service';
import { parameters } from '../parameters';
import { IGenre } from '../entities/genre';

@Injectable()
export class GenreService extends FSProService {
  private urlBase = parameters.baseServiceUrl + 'rest/films/genres';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Use this service the get a list of campaigns
   */
  getList(
    start = 0,
    limit = 0,
    sort: ISort = null,
    search = null,
    filters = null
  ): Observable<IGenre[]> {
    const fullUrl = this.buildListUrl(
      this.urlBase,
      start,
      limit,
      sort,
      search,
      filters
    );

    return this.http.get<IGenre[]>(fullUrl).pipe(
      map((data) => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      })
    );
  }
}
