import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFilter } from '../entities/filter';
import { IRHGroup } from '../entities/rh-group';
import { ISort } from '../entities/sort';
import { parameters } from '../parameters';
import { FSProService } from './fspro-service';

@Injectable()
export class RhGroupService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/rh-groups';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Use this service the get a list of campaigns
   *
   * @param start
   * @param limit
   * @param sort
   */
  getRhGroupList(start = 0, limit = 0, sort: ISort = null, search = null, filters: IFilter[] = null): Observable<IRHGroup[]> {

    const fullUrl = this.buildListUrl(this.urlBase, start, limit, sort, search, filters);

    console.log(fullUrl);

    return this.http.get<IRHGroup[]>(fullUrl).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      }));
  }

  /**
   * This method return list of RHGroup inviting from
   * @returns list of RHGroup
   */
  getRHGroupInvitingFrom(): Observable<IRHGroup[]> {
    const url = `${this.urlBase}/inviting`;
    return this.http.get<IRHGroup[]>(url);
  }

}
