import { FilmService } from "../services/film.service";
import { Observable } from "rxjs";
import { IProvider } from "../interfaces/provider.interface";
import { IFilmHydratedSearchDto, IFilmDto } from "../dtos/film-dto";

// interface IFilmProviderOptions {
//   method?: string,
//   body?: IFilmHydratedSearchDto
// }

export class PreviousWorkProvider implements IProvider {

  constructor(private filmService: FilmService,
    private filmId: string) {
  }

  getEntities(startAt: number, limit: number): Observable<IFilmDto[]> {
    return this.filmService.getPreviousWork(startAt, limit, this.filmId);
  }
}
