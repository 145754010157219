<footer class="page-footer">
  <nav>
    <ul class="nav-list">
      <ng-container *ngIf="isLoadingFooter" >
        <li class="nav-item" *ngFor="let i of [].constructor(5)">
          <ngx-skeleton-loader appearance="line" animation="pulse"
          [theme]="{ height: '18px', 'border-radius': 0, width: '100px', 'margin-bottom': '-4px' }">
        </ngx-skeleton-loader>
        </li>
      </ng-container>

      <ng-container *ngIf="!isLoadingFooter" >
      <li class="nav-item" *ngFor="let item of footerItems" >
        <a [routerLink]="'/page/'+ item.slug" class="pagefooter__navlink">{{ item.title }}</a>
      </li>
    </ng-container>

      <li class="nav-item">
        <a class="pagefooter__navlink pagefooter__navlink__contact" style="cursor: pointer;"
          (click)="toggleContactEmitter.emit(true)">{{'fspro.front.header.footer.label.contact_us' | translate }}</a>

      </li>
    </ul>
  </nav>
  <div class="pagefooter__extras">
    <img src="/assets/mat-icon/logo-festival-scope-pro.svg" alt="Festival Scope Pro " class="pagefooter__logoimage"
      width="250">
    <p class="pagefooter__copyright"><span [innerHTML]="'fspro.front.header.footer.label.copyright' | translate"></span> {{currentYear}}</p>
    <ul class="pagefooter__socials">
      <li class="pagefooter__asocial">
        <a href="https://fr-fr.facebook.com/FestivalScope/" class="pagefooter__sociallink" target="_blank">
          <img src="assets/images/facebook-logo.svg" alt="facebook">
        </a>
      </li>
      <li class="pagefooter__asocial">
        <a href="https://twitter.com/festivalscope" class="pagefooter__sociallink" target="_blank">
          <img src="assets/images/x-logo.svg" alt="X">
        </a>
      </li>
      <li class="pagefooter__asocial">
        <a href="https://www.instagram.com/festival.scope/" class="pagefooter__sociallink" target="_blank">
          <img src="assets/images/instagram-logo.svg" alt="instagram">
        </a>
      </li>
    </ul>
  </div>
  <div class="pagefooter__creativeeurope">
    <img src="/assets/images/logo-creative-europe.svg" loading="lazy"
      alt="Co-funded by the Creative Europe Media Programme of the European Union">
  </div>
</footer>
