import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IScreeningService } from 'src/app/interfaces/request-service.interface';
import { parameters } from '../parameters';
import { FSProService } from './fspro-service';
import { AuthenticationService } from "../../authentications/authentication.service";

@Injectable()
export class ScreeningService extends FSProService implements IScreeningService {
  private urlBase = parameters.baseServiceUrl + 'rest/screenings';

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    super();
  }

  public archiveScreening(filmId: number): Observable<IArchiveScreeningResponseDto> {
    const url = `${this.urlBase}/archive/${filmId}`;
    return this.http.put<IArchiveScreeningResponseDto>(url, null);
  }

  public canDisplayFreshStart(slug: string): Observable<ICanDisplayFreshStartResponseDto> {
    const url = `${this.urlBase}/can-display-fresh-start/${slug}`;
    return this.http.get<ICanDisplayFreshStartResponseDto>(url);
  }

  getScreeningCountries() : Observable<IFilterItem[]>{
    return this.http.get<IFilterItem[]>(this.urlBase+'/countries/');
  }

  getProfessionalCategories(): Observable<IFilterItem[]>{
    return this.http.get<IFilterItem[]>(this.urlBase+'/professional-categories/');
  }

  getSections() : Observable<IFilterItem[]> {
    return this.http.get<Array<any>>(this.urlBase+'/sections/').pipe(map((data) => {
      data = data.map(item => {return {name: item.name, id : item.parentIdentifier}});
      return data;
      }));
  }

  getFilms(): Observable<IFilterItem[]>{
    return this.http.get<Array<any>>(this.urlBase+'/films/').pipe(map((data) => {
      data = data.map(item => {return {name: item.internationalTitle, id : item.id}});
      return data;
      }));;
  }

  getScreenings(start: number, limit: number,searchText: string, country: string, profession: string, section: string, film: string){
    let screeningUrl = this.urlBase+'/hydrated/';
    let params = this.constructFilterParams(searchText, country, profession, section, film);

    if (start != 0) {
      params = params.set('start', start.toString());
    }
    if (limit != 0) {
      params = params.set('limit', limit.toString());
    }
    screeningUrl = `${screeningUrl}?${params.toString()}`;
    return this.http.get<object[]>(screeningUrl);
  }

  getExportUrl(searchText: string, country: string, profession: string, section: string, film: string) : string
  {
    let params = this.constructFilterParams(searchText, country, profession, section, film);
    /*if (this.authenticationService.isAuthenticated) {
      params = params.append('bearer', this.authenticationService.token);
    }*/
    let exportUrl = `${this.urlBase}/export?${params.toString()}`;
    if (this.authenticationService._impersonation$.value) {
      exportUrl = `${exportUrl}&X-Switch-User=${this.authenticationService._impersonation$.value}`;
    }
    return exportUrl;
  };

  addAllowedScreeningCount(invitationId: string) : Observable<any> {
    let addAllowedScreeningCountUrl = `${parameters.baseServiceUrl}rest/invitations/increment/${invitationId}`;
    return this.http.put(addAllowedScreeningCountUrl, null);
  }

  private constructFilterParams(searchText: string, country: string, profession: string, section: string, film: string) : HttpParams {
    let params = new HttpParams();

    if (country) {
      params = params.append('country', country);
    }
    if (profession) {
      params = params.append('professional-field', profession);
    }
    if (section) {
      params = params.append('section', section);
    }
    if (film) {
      params = params.append('film', film);
    }
    if (searchText) {
      params = params.append('search', searchText);
    }
    return params;
  }

  sendContactMessage(screeningId: string, body: string, sendCopy: boolean, subject: string){
    let sendScreeningMessageUrl = `${this.urlBase}/contact/${screeningId}`
    return this.http.post(sendScreeningMessageUrl, {
      body: body,
      subject: subject,
      sendCopy: sendCopy
    })
  }
}

export interface IArchiveScreeningResponseDto {
  success: boolean;
}

export interface ICanDisplayFreshStartResponseDto {
  display: boolean;
}

export interface IFilterItem {
  name: string;
  id: string;
}
