<div class="grid" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">

  <ng-container *ngIf="films.length > 0">
    <fspro-project-card *ngFor="let film of films" [film]="film">
    </fspro-project-card>

  </ng-container>
  <ng-container *ngIf="loading">
    <fspro-skeleton-project-card
      *ngFor="let i of [].constructor(this.films.length === 0 ? 6 : this.films.length % 3 === 0 ? 3 : 1)">
    </fspro-skeleton-project-card>
  </ng-container>
</div>
