import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { parameters } from 'src/app/shared/parameters';
import { FSProService } from 'src/app/shared/services/fspro-service';
import { ICarouselInitiativeDto } from '../dtos/carousel-initiative-dto';
import { map } from "rxjs/operators";

@Injectable()
export class InitiativeDisplayService extends FSProService {

  private urlBase =  parameters.baseServiceUrl + 'rest/home-initiative/';

  constructor(private http: HttpClient) {
    super();
  }

  getInitiatives(): Observable<ICarouselInitiativeDto[]> {
    return this.http.get<ICarouselInitiativeDto[]>(this.urlBase).pipe(
      map((elements) => {
        elements.map((element) => {
          switch (element.type) {
            case 'label':
              element.pageUrl = '/label/' + element.slug;
              break;
            case 'lineup':
              element.pageUrl = '/lineup/' + element.slug;
              break;
            case 'director':
              element.pageUrl = '/director/' + element.slug;
              break;
            case 'film':
              element.pageUrl = '/film/' + element.slug;
              break;
          }
        });
        return elements;
      })
    );
  }
}
