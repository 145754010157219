import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IScreeningService } from 'src/app/interfaces/request-service.interface';
import { parameters } from '../parameters';
import { IEmailMessageResponse } from './contact-to-companyservice';
import { FSProService } from './fspro-service';
import { IFilterItem } from './screening.service';
import { AuthenticationService } from "../../authentications/authentication.service";

@Injectable()
export class ScreenRequestService extends FSProService implements IScreeningService {

  private urlBase = parameters.baseServiceUrl + 'rest/screening-requests';

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    super();
  }

  sendRequest(message: string, isSendCopy: boolean = false, filmSlug: string) {
    const url = `${this.urlBase}/${filmSlug}`;

    const body = {
      isSendCopy: isSendCopy,
      message: message
    };

    return this.http.post<IEmailMessageResponse>(url, body);
  }

  getScreeningCountries(): Observable<IFilterItem[]> {
    return this.http.get<IFilterItem[]>(this.urlBase + '/countries/');
  }

  getProfessionalCategories(): Observable<IFilterItem[]> {
    return this.http.get<IFilterItem[]>(this.urlBase + '/professional-categories/');
  }

  getSections(): Observable<IFilterItem[]> {
    return this.http.get<Array<any>>(this.urlBase + '/sections/').pipe(map((data) => {
      data = data.map(item => { return { name: item.name, id: item.parentIdentifier } });
      return data;
    }));
  }

  getFilms(): Observable<IFilterItem[]> {
    return this.http.get<Array<any>>(this.urlBase + '/films/').pipe(map((data) => {
      data = data.map(item => { return { name: item.internationalTitle, id: item.id } });
      return data;
    }));
  }

  getScreenings(start: number, limit: number, searchText: string, country: string, profession: string, section: string, film: string, status: string) {
    let screeningUrl = this.urlBase + '/hydrated/';
    let params = this.constructFilterParams(searchText, country, profession, section, film, status);

    if (start != 0) {
      params = params.set('start', start.toString());
    }
    if (limit != 0) {
      params = params.set('limit', limit.toString());
    }
    screeningUrl = `${screeningUrl}?${params.toString()}`;
    return this.http.get<object[]>(screeningUrl);
  }

  getExportUrl(searchText: string, country: string, profession: string, section: string, film: string, status: string): string {
    let params = this.constructFilterParams(searchText, country, profession, section, film, status);
    /* if (this.authenticationService.isAuthenticated) {
      params = params.append('bearer', this.authenticationService.token);
    } */
    let exportUrl = `${this.urlBase}/export?${params.toString()}`;
    if (this.authenticationService._impersonation$.value) {
      exportUrl = `${exportUrl}&X-Switch-User=${this.authenticationService._impersonation$.value}`;
    }
    return exportUrl;
  };

  private constructFilterParams(searchText: string, country: string, profession: string, section: string, film: string, status: string = ''): HttpParams {
    let params = new HttpParams();

    if (country) {
      params = params.append('country', country);
    }
    if (profession) {
      params = params.append('professional-field', profession);
    }
    if (section) {
      params = params.append('section', section);
    }
    if (film) {
      params = params.append('film', film);
    }
    if (searchText) {
      params = params.append('search', searchText);
    }
    if (status) {
      params = params.append('status', status);
    }

    return params;
  }

  sendContactMessage(screeningId: string, body: string, sendCopy: boolean, subject: string) {
    let sendScreeningMessageUrl = `${this.urlBase}/contact/${screeningId}`
    return this.http.post(sendScreeningMessageUrl, {
      body: body,
      subject: subject,
      sendCopy: sendCopy
    })
  }

  approveScreening(screeningId: string, hash?: string): Observable<any> {
    let approveScreeningUrl = `${this.urlBase}/approve/${screeningId}`;
    approveScreeningUrl += hash ? `/${hash}` : '';
    return this.http.put(approveScreeningUrl, null)
  }

  denyScreening(screeningId: string, hash?: string): Observable<any> {
    let denyScreeningUrl = `${this.urlBase}/deny/${screeningId}`;
    denyScreeningUrl += hash ? `/${hash}` : '';
    return this.http.put(denyScreeningUrl, null)
  }

  countPendingScreeningRequests(): Observable<number> {
    let url = `${this.urlBase}/count/pending`;
    return this.http.get<number>(url);
  }

}
