import { Injectable } from "@angular/core";
import { FSProService } from "./fspro-service";
import { parameters } from "../parameters";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ITalentDto } from "../dtos/talent-dto";

@Injectable()
export class TalentService extends FSProService {
  private urlBase = parameters.baseServiceUrl + 'rest/talents';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Get talent by slug
   *
   * @param slug Talent's slug
   * @returns Talent Data
   */
  public getTalentBySlug(slug: string): Observable<ITalentDto> {
    const fullUrl = `${this.urlBase}/by-slug/${slug}`;
    return this.http.get<ITalentDto>(fullUrl);
  }
}
