import { Component, Input } from '@angular/core';
import { ICollectionDto } from 'src/app/shared/dtos/collection-dto';

@Component({
  selector: 'fspro-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss']
})
export class CollectionCardComponent {
  @Input() public collectionItem: ICollectionDto = null;

  @Input() public isHighlight = false;

  get collectionUrl() {
    let url: string;
    if (this.collectionItem.type === 'lineup') {
      url = `/line-up/show/${this.collectionItem.slug}`;
    }
    else if (this.collectionItem.type === 'commission') {
      url = `/film-commission/show/${this.collectionItem.slug}`;
    }
    else {
      url = `/${this.collectionItem.type}/show/${this.collectionItem.slug}`;
    }
    return url;
  }
}
