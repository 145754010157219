import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { IFilmDto } from "../dtos/film-dto";
import { ImageUtils } from "./image-utils";

@Directive({
  selector: '[appCacheImage]'
})
export class CacheImageDirective extends ImageUtils {

  private alreadyTriedUrls: string[] = [];

  @Input('appCacheImage') set cacheImage(value: string | { film: IFilmDto, resolution: string }) {
    if (typeof value === 'string') {
      this.originalUrl = value.replace('http://', 'https://');
      this.handleString(value);
    } else if (value && 'film' in value && 'resolution' in value) {
      this.film = value.film;
      this.handleFilmDto(value.film, value.resolution);
    } else {
      console.error('Wrong value provided.', value);
    }
  }

  constructor(private el: ElementRef) {
    super();
  }

  private handleString(url: string) {
      this.setUrl(this.getCacheUrl(url));
  }

  private handleFilmDto(film: IFilmDto, resolution: string) {
    const url = this.getUrlFromFilm(film, resolution);
    this.setUrl(url);
  }

  private setUrl(url: string, cache: boolean = true) {
    if (
      this.el.nativeElement.parentElement.tagName.toLowerCase() === 'picture' &&
      this.el.nativeElement.parentElement.classList.contains('picture-responsive')
    ) {
      const imageElement = this.el.nativeElement.parentElement.querySelector('img');
      if (cache) {
        this.el.nativeElement.srcset = this.getCacheUrl(url);
        imageElement.src = this.getCacheUrl(url);
      } else {
        const resolution = this.getPictureResolution();
        const parent = this.el.nativeElement.parentElement;
        const sourceElement = parent.querySelectorAll(`.resolution-${resolution}`)[0];
        sourceElement.srcset = url;
        imageElement.src = url;
      }
    } else {
      this.el.nativeElement.src = cache ? this.getCacheUrl(url) : url;
    }
  }

  private getUrl(): string {
    if (this.el.nativeElement.tagName.toLowerCase() === 'img') {
      return this.el.nativeElement.src;
    } else if (this.el.nativeElement.tagName.toLowerCase() === 'source') {
      return this.el.nativeElement.srcset;
    }
    return '';
  }

  private getOriginalUrlForResolution() {
    if (!this.film) {
      console.error('No film provided');
      return '';
    }

    let resolution = this.getPictureResolution();

    const selectedPictureDto = this.film.pictures.find(
      (pictureDto) => {
        return pictureDto.size === resolution
      }
    );

    if (selectedPictureDto) {
      return selectedPictureDto.url;
    }

    return selectedPictureDto.url;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (
      this.el.nativeElement.parentElement.tagName.toLowerCase() === 'picture' &&
      this.el.nativeElement.parentElement.classList.contains('picture-responsive')
    ) {
      const newUrl = this.getOriginalUrlForResolution();
      const cacheUrl = this.getCacheUrl(newUrl);
      const imageElement = this.el.nativeElement.parentElement.querySelector('img');
      imageElement.src = cacheUrl;
    }
  }

  @HostListener('error', ['$event'])
  onError() {
    if (!this.alreadyTriedUrls.includes(this.getUrl())) {
      if (
        this.el.nativeElement.parentElement.tagName.toLowerCase() === 'picture' &&
        this.el.nativeElement.parentElement.classList.contains('picture-responsive')
      ) {
        const url = this.getOriginalUrlForResolution();
        this.setUrl(url, false);
        this.alreadyTriedUrls.push(url);
      } else {
        this.setUrl(this.originalUrl, false);
        this.alreadyTriedUrls.push(this.originalUrl);
      }
    } else {
      console.error('Already tried to generate image unsuccessfully!');
    }
    this.el.nativeElement.onerror = null;
  }
}
