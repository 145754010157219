import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMediumDto } from '../../dtos/medium-dto';

@Component({
  selector: 'fspro-photo-slider',
  templateUrl: './photo-slider.component.html',
  styleUrls: ['./photo-slider.component.scss']
})

export class PhotoSliderComponent {
  @Input() public activeItem;
  @Input() public isShowDownloadButton = false;
  @Input() public downloadUrl = '';
  @Input() public photos: IMediumDto[] = [];

  @Output() onChangeScrollBar: EventEmitter<any> = new EventEmitter();
  @Output() onChangeSource: EventEmitter<any> = new EventEmitter();

  @Input() translations: any = null;

  public onChangeItemInScrollBar(index: number) {
    this.onChangeScrollBar.emit(index);
  }

  public onChangeItemInSource(index: number) {
    this.scrollIntoItem(index);

    this.onChangeSource.emit(index);
  }

  public scrollIntoItem(index: number) {
    let element;

    if (index > this.activeItem) {
      if (index >= 3) {
        element = document.getElementById(`slider-source-${index + 2}`);

        if (!element) {
          element = document.getElementById(`slider-source-${index + 1}`);
        }
      }
    } else {
      element = document.getElementById(`slider-source-${index - 2}`);

      if (!element) {
        element = document.getElementById(`slider-source-${index - 1}`);
      }
    }

    if (element) {
      element.scrollIntoView();
    }
  }
}
