<div mat-dialog-title>
  <div class="simple-message__close">
    <button mat-icon-button class="simple-message__close-icon" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="simple-message__info">
    <h1 class="simple-message__title">
      <mat-icon class="message-mail-icon">mail_outline</mat-icon>
      {{ data?.fields?.title }}
    </h1>
  </div>
</div>
<form class="form-wrapper" [formGroup]="sendMailForm" (ngSubmit)="onSubmit()">
    <div class="field-wrapper">
      <p class="simple-message__description" *ngIf="data?.fields?.description">
        {{ data?.fields?.description }}
      </p>

      <div *ngIf="data?.enableSubject" class="fspro-control-input-wrapper">
        <mat-form-field>
          <mat-label>{{ data?.fields?.subject }}</mat-label>
          <textarea
            name="subjectField"
            matInput
            formControlName="subjectField"
            autocomplete="off"
            autocapitalize="off"
            cdkTextareaAutosize
            class="input-note"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="fspro-control-input-wrapper">
        <mat-form-field>
          <mat-label>{{ data?.fields?.body }}</mat-label>
          <textarea
            name="messageField"
            matInput
            formControlName="messageField"
            autocomplete="off"
            autocapitalize="off"
            cdkTextareaAutosize
            class="input-note"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="action-button">
      <div class="fspro-control-checkbox-wrapper">
        <mat-checkbox formControlName="sendACopy">{{data?.fields?.sendCopy}}</mat-checkbox>
      </div>

      <div class="btn-wrapper">
        <button class="btn-cancel" type="alertMessageButton" (click)="onClose()">Cancel</button>
        <button class="btn-send" type="submit" [disabled]="isSending">{{ data?.fields?.sendButton }}</button>
      </div>
    </div>

</form>
