<h1 mat-dialog-title [innerHtml]="'fspro.front.my-infos.cancelpopin.label.wish_cancel_title' | translate"></h1>
<div mat-dialog-content>
  <p  [innerHtml]="'fspro.front.my-infos.cancelpopin.label.renew_advice' | translate"></p>
  <p  [innerHtml]=" 'fspro.front.my-infos.cancelpopin.label.membership_date_expiring' | translate:{ endDate: data.expiryDate } "></p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'fspro.front.my-infos.cancelpopin.label.back' | translate }}</button>
  <button mat-raised-button color="primary" (click)="cancelMembership()" [disabled]="this.loading" cdkFocusInitial>
    <span *ngIf="!this.loading">{{ 'fspro.front.my-infos.cancelpopin.button.cancel' | translate }}</span>
    <span *ngIf="this.loading">Please wait...</span>
  </button>
</div>
