<mat-form-field appearance="outline" *ngIf="_listFilmTabConfiguration.sections.length > 1">
  <mat-select [(ngModel)]="_listFilmTabConfiguration.selectedTabIndex">
    <mat-option *ngFor="let section of _listFilmTabConfiguration.sections; let i = index" [value]="i"  >
      {{ section.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-tab-group
  matMultilineTab
  class="{{ _listFilmTabConfiguration.sections.length >= 8 ? 'align-left' : '' }} {{ _listFilmTabConfiguration.inkBarColorClass !== null ? _listFilmTabConfiguration.inkBarColorClass : '' }} {{ _listFilmTabConfiguration.sections.length === 1 ? 'single' : '' }}"
  mat-align-tabs="center"
  dynamicHeight
  *ngIf="this._listFilmTabConfiguration.sections.length > 0"
  selectedIndex="{{ _listFilmTabConfiguration.selectedTabIndex !== null ? _listFilmTabConfiguration.selectedTabIndex : 0 }}"
  (selectedTabChange)="onTabChange($event)"
>
  <mat-tab *ngFor="let section of _listFilmTabConfiguration.sections" label="{{ section.name }}">
    <ng-template mat-tab-label>
      <span class="tab-special" >
        {{ section.name }}
      </span>
    </ng-template>
    <ng-template matTabContent>
      <section>
        <div class="description" markdown [data]="section.description" *ngIf="section.description !== null && section.description !== ''"></div>
      </section>
      <section *ngIf="orderedAwards.length > 0">
        <ul class="awards">
          <li *ngFor="let orderedAward of orderedAwards">
            <a [routerLink]="orderedAward.isClickable ? orderedAward.filmSlug : null">
              <span class="jury-name">{{ orderedAward?.filmTitle }}</span>
              <ng-container *ngFor="let award of orderedAward?.filmAwards; let index = index">
                <span class="award">{{ award.name }}</span> <ng-container *ngIf="orderedAward?.filmAwards.length > index + 1"><span class="delimiter">|</span></ng-container>
              </ng-container>
            </a>
          </li>
        </ul>
      </section>
      <fspro-film-grid
        *ngIf="!section.directorSection && provider$ | async"
        [provider]="provider$ | async"
        [isAdmin]="_listFilmTabConfiguration.isAdmin"
      ></fspro-film-grid>
      <fspro-director-grid
        *ngIf="section.directorSection && provider$ | async"
        [provider]="provider$ | async"
        [isSection]="true"
        [showType]="section.displayTalentType"
      ></fspro-director-grid>
    </ng-template>
  </mat-tab>
</mat-tab-group>
