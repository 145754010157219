<div class="fest-item-wrapper skeleton-festival-card">
  <div class="inner">
    <ngx-skeleton-loader appearance="line" animation="pulse" class="img-wrapper skeleton-img" [theme]="{ minWidth: '100px', minHeight: '60px', height: '100%', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>

    <div class="meta-block skeleton-meta-block">
      <ngx-skeleton-loader appearance="line" animation="pulse" class="h3" [theme]="{ height: '100%', 'border-radius': 0, width: '100%' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" animation="pulse" class="h4" [theme]="{ height: '100%', 'border-radius': 0, width: '100%' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line" animation="pulse" class="h5" [theme]="{ height: '100%', 'border-radius': 0, width: '100%' }"></ngx-skeleton-loader>
    </div>
  </div>
</div>
