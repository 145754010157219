import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ICinematographerDto } from "../../dtos/cinematographer-dto";

@Component({
  selector: 'fspro-director-header-block',
  templateUrl: './director-header-block.component.html',
  styleUrls: ['./director-header-block.component.scss']
})
export class DirectorHeaderBlockComponent {
  @Input() cinematographer: ICinematographerDto;
  @Input() blockMode: 'single' | 'multi' = 'single';
  @Input() isShowSendMessageButton: boolean = false;
  @Input() isLinkListEnabled: boolean = false;
  @Output() openSendDirector = new EventEmitter<any>();

  constructor(
  ) { }

  onClickSendMessage() {
    this.openSendDirector.emit(this.cinematographer);
  }
}
