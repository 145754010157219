import { DOCUMENT } from "@angular/common";
import { Component, EventEmitter, HostListener, Inject, Input, Output } from "@angular/core";

@Component({
  selector: 'fspro-header-widget',
  templateUrl: './header-widget.html',
  styleUrls: ['./header-widget.scss']
})
export class HeaderWidgetComponent {
  @Input() isToggled: boolean;
  @Output() isToggledChange = new EventEmitter();
  @Input() topSpacing: number = 0;

  get verticalOffset(): number {
    return this.document.defaultView.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
  }
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  coverHeight: string = '0px';

  @HostListener('window:scroll', [])
  onScroll(): void {
    if(this.isToggled) {
      this.coverHeight = `${this.document.body.clientHeight + this.verticalOffset - this.topSpacing}px`;
    }
  }

  open(): void {
    this.coverHeight = `${this.document.body.clientHeight + this.verticalOffset - this.topSpacing}px`;
    this.isToggled = true;
    this.isToggledChange.emit(true);
  }

  dismiss(): void {
    this.isToggled = false;
    this.isToggledChange.emit(false);
  }
}
