import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MarkdownModule } from 'ngx-markdown';
import { MatGridListModule, MatGridTile } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';
import { FilmImagePipe } from './pipes/film-image.pipe';
import { SkeletonFilmCardComponent } from './components/skeleton-film-card/skeleton-film-card.component';
import { ShareInvitationDialog } from './components/share-invitation-dialog/share-invitation-dialog.component';
import { FilmCardComponent } from './components/film-card/film-card.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MatButtonModule } from "@angular/material/button";
import { AuthenticationService } from '../authentications/authentication.service';
import { BubbleComponent } from './components/bubble/bubble.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SkeletonFestivalCardComponent } from './components/skeleton-festival-card/skeleton-festival-card.component';
import { FestivalCardComponent } from './components/festival-card/festival-card.component';
import { CollectionVerticalListComponent } from './components/collection-vertical-list/collection-vertical-list.component';
import { SendMessageDialog } from './components/send-message-dialog/send-message-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SkeletonDirectorPageComponent } from './components/skeleton-director-page/skeleton-director-page.component';
import { MAT_SELECT_SCROLL_STRATEGY, MatSelectModule } from '@angular/material/select';
import { PhotoSliderComponent } from './components/photo-slider/photo-slider.component';
import { FilmDetailsExtraDialog } from './components/film-details-extra-dialog/film-details-extra-dialog.component';
import { PhotoGalleryDialogComponent } from './components/photo-gallery-dialog/photo-gallery-dialog.component';
import { ScreeningLeftDialog } from './components/screening-left-dialog/screening-left-dialog.component';
import { DirectorHeaderBlockComponent } from './components/director-header-block/director-header-block.component';
import { SendRequestDialog } from './components/send-request-dialog/send-request-dialog.component';
import { NoteDialog } from './components/note-dialog/note-dialog.component';
import { RouterModule } from '@angular/router';
import { FilmHelperService } from './services/film.helper.service';
import { CinematographerCard } from './components/director-card/cinematographer-card.component';
import { DirectorGridComponent } from './components/director-grid/director-grid.component';
import { FilmGridComponent } from './components/film-grid/film-grid.component';
import { ListFilmTabsComponent } from './components/list-film-tabs/list-film-tabs.component';
import { SkeletonCollectionDetailsComponent } from './components/skeleton-collection-details/skeleton-collection-details.component';
import { SkeletonDirectorCardComponent } from './components/skeleton-director-card/skeleton-director-card.component';
import { SkeletonFilmGridComponent } from './components/skeleton-film-grid/skeleton-film-grid.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProfessionalFieldService } from './services/professional-field.service';
import { MatGridListDirective } from './directives/mat-grid-list.directive';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';
import { FilmAvailableToWatchService } from './services/film-available-to-watch.service';
import { GenreService } from './services/genre.service';
import { RuntimeService } from './services/runtime.service';
import { YearService } from './services/year.service';
import { RhGroupService } from './services/rh-group.service';
import { CollectionService } from './services/collection.service';
import { FilmService } from './services/film.service';
import { DirectorService } from './services/director.service';
import { ScreeningService } from './services/screening.service';
import { ScreenRequestService } from './services/screen-request.service';
import { FilmNoteService } from './services/film-note.service';
import { InitiativeDisplayService } from './services/initiative-display.service';
import { SalesService } from './services/sales.service';
import { TagService } from './services/tag.service';
import { LanguageService } from './services/language.service';
import { FestivalYearService } from './services/festival-year.service';
import { FestivalService } from './services/festival.service';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { ListProjectTabsComponent } from './components/list-project-tabs/list-project-tabs.component';
import { ProjectGridComponent } from './components/project-grid/project-grid.component';
import { SkeletonProjectCardComponent } from './components/skeleton-project-card/skeleton-project-card.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMultilineTabDirective } from "../categories-page/directives/mat-multiline-tab-directive";
import { SendSimpleMessageDialog } from './components/send-simple-message/send-simple-message-dialog.component';
import { ContactUsService } from './services/contact-us.service';
import { FsproStripeService } from "../stripe-subscription/services/fspro-stripe.service";
import { RequestUpdateDialog } from './components/request-update-dialog/request-update-dialog.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MembershipService } from './services/membership.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { SubscriptionStatusComponent, MembershipCancelDialogComponent } from './components/subscription-status/subscription-status.component';
import { PriceService } from './services/price.service';
import { MatListModule } from '@angular/material/list';
import { ListControlsComponent } from './components/list-controls/list-control.component';
import { FilmNoteFilterComponent } from './components/film-note-filter/film-note-filter.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { BookingConditionsDialogComponent } from './components/booking-conditions-dialog/booking-conditions-dialog.component';
import { FilmBookingService } from './services/film-booking.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { BackOfficeAccessComponent } from './components/back-office-access/back-office-access.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { CollectionSectionService } from './services/collection-section.service';
import { TalentService } from "./services/talent.service";
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MatStepperModule } from '@angular/material/stepper';
import { CinematographerService } from "./services/cinematographer.service";
import { CacheImageDirective } from "./directives/cache-image.directive";
import { AdminUrlService } from "./services/admin-url.service";
import {PictureSourceCacheUrlDirective} from "./directives/picture-source-cache-url";
import { digitStringDirective } from './directives/digit-string.directive';
import { SkeletonCollectionCardComponent } from './components/skeleton-collection-card/skeleton-collection-card.component';
import { CollectionGridComponent } from './components/collection-grid/collection-grid.component';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { CollectionFiltersComponent } from './components/collection-filters/collection-filters.component';
import { CollectionHighlightComponent } from './components/collection-highlight/collection-highlight.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    type: 'bullets',
  }
};

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

const COMPONENTS = [
  SkeletonFilmCardComponent,
  SkeletonFestivalCardComponent,
  SkeletonCollectionCardComponent,
  SkeletonDirectorPageComponent,
  SkeletonDirectorCardComponent,
  SkeletonFilmGridComponent,
  SkeletonCollectionDetailsComponent,
  ObserveVisibilityDirective,
  FilmImagePipe,
  ShareInvitationDialog,
  FilmCardComponent,
  SafeHtmlPipe,
  BubbleComponent,
  FestivalCardComponent,
  CollectionCardComponent,
  CollectionVerticalListComponent,
  SendMessageDialog,
  SendRequestDialog,
  FilmDetailsExtraDialog,
  ScreeningLeftDialog,
  NoteDialog,
  FilmGridComponent,
  CollectionGridComponent,
  PhotoSliderComponent,
  PhotoGalleryDialogComponent,
  DirectorHeaderBlockComponent,
  DirectorGridComponent,
  CinematographerCard,
  ListFilmTabsComponent,
  ContactComponent,
  MatGridListDirective,
  FilterGroupComponent,
  ProjectCardComponent,
  ListProjectTabsComponent,
  ProjectGridComponent,
  SkeletonProjectCardComponent,
  MatMultilineTabDirective,
  SendSimpleMessageDialog,
  RequestUpdateDialog,
  SubscriptionStatusComponent,
  MembershipCancelDialogComponent,
  ListControlsComponent,
  FilmNoteFilterComponent,
  AutoFocusDirective,
  BookingConditionsDialogComponent,
  ConfirmDialogComponent,
  BackOfficeAccessComponent,
  DragAndDropDirective,
  CacheImageDirective,
  PictureSourceCacheUrlDirective,
  digitStringDirective
];

const SHARED_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatIconModule,
  NgxSkeletonLoaderModule,
  MatTabsModule,
  InfiniteScrollModule,
  MatExpansionModule,
  MatGridListModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  SwiperModule,
  NgxCaptchaModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonModule,
  MaterialFileInputModule,
  FontAwesomeModule,
  MatAutocompleteModule,
  MatListModule,
  MatStepperModule
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    BackOfficeAccessComponent,
    CollectionFiltersComponent,
    CollectionHighlightComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ...SHARED_MODULES,
    MarkdownModule.forRoot({ sanitize: SecurityContext.NONE }),
    TranslateModule.forChild(),

  ],
  exports: [
    ...SHARED_MODULES,
    ...COMPONENTS,
    MarkdownModule,
    CollectionFiltersComponent,
    CollectionHighlightComponent
  ],
  providers: [
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
    FilmHelperService,
    DatePipe,
    { provide: MatGridTile, useValue: {} },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    AuthenticationService,
    ProfessionalFieldService,
    FilmAvailableToWatchService,
    YearService,
    RuntimeService,
    GenreService,
    RhGroupService,
    CollectionService,
    FilmService,
    DirectorService,
    CinematographerService,
    ScreeningService,
    ScreenRequestService,
    FilmNoteService,
    InitiativeDisplayService,
    SalesService,
    TagService,
    LanguageService,
    FestivalYearService,
    FestivalService,
    ContactUsService,
    FsproStripeService,
    PriceService,
    MembershipService,
    FilmBookingService,
    CollectionSectionService,
    TalentService,
    AdminUrlService
  ]
})
export class SharedModule {

}
