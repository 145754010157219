<button mat-icon-button disableRipple class="close-btn" (click)="onClose()"></button>

<!-- get link -->
<div class="description" *ngIf="!isGetLinkSuccess">

  <p *ngIf="!(data.film.InvitationInfoDto.hasUnlimitedSharesAllowed)"
    [innerHTML]="'fspro.front.filmcard.label.share_message' | translate: { filmTitle: data?.film?.internationalTitle , availableShare: data.film.InvitationInfoDto.maxSharesAllowed } | safeHtml">
  </p>

  <p *ngIf="data.film.InvitationInfoDto.hasUnlimitedSharesAllowed"
     [innerHTML]="'fspro.front.filmcard.label.unlimited_share_message' | translate:{filmTitle: data?.film?.internationalTitle} | safeHtml"
  ></p>

  <p>{{ 'fspro.front.filmcard.label.advertisment' | translate }}</p>


  <button class="btn-get-link" (click)="getLink()">
    {{ 'fspro.front.sentinvitations.invitationlabel.get_link' | translate }}
    {{data.film.InvitationInfoDto.sharedCounter || 0}} /
    {{data.film.InvitationInfoDto.hasUnlimitedSharesAllowed ? '∞' : (data.film.InvitationInfoDto.maxSharesAllowed || 0) }}
  </button>
</div>

<!-- copy link -->
<div class="description" *ngIf="isGetLinkSuccess">
  <p
    [innerHTML]="'fspro.front.filmcard.label.final_message' | translate:{filmTitle: data?.film?.internationalTitle}  | safeHtml ">
  </p>

  <input name="link" [(ngModel)]="link" #ctrl="ngModel" id="inputLink" #inputLink autocomplete="off">

  <button class="btn-get-link" style="margin-top: 1em;" (click)="copyLink()">{{ 'fspro.front.filmcard.label.copy_link' |
    translate}}</button>
</div>
