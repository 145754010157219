<nav *ngIf="shouldDisplayNavBar" class="pageheader__nav" [class.pageheader__nav--open]="isToggled">
  <ul class="pageheader__navlist" [ngClass]="'pageheader__navlist--' + (activatedItem$ | async)?.name">
    <li class="pageheader__navitem pageheader__navitem--mobileonly">
      <button class="pageheader__widgetsclose" (click)="dismiss()">Close</button>
    </li>
    <ng-container *ngFor="let item of items">
      <ng-container  *ngTemplateOutlet="item.isJoin ? joinTpl : defaultTpl; context: { $implicit: item, activated: activatedItem$ | async }"></ng-container>
    </ng-container>
  </ul>
</nav>
<ng-template let-item #defaultTpl>
  <li class="pageheader__navitem" [ngClass]="'pageheader__navitem--' + item.name" *ngIf="!item.with || item.with()">
    <a [routerLink]="item.route" class="pageheader__navlink" (click)="itemClicked()">{{ item.title | translate}}</a>
  </li>
</ng-template>
<ng-template let-item let-activated="activated" #joinTpl>
  <li class="pageheader__navitem" [ngClass]="['pageheader__join', 'pageheader__join--' + activated?.name]" *ngIf="!item.with || item.with()">
    <a [routerLink]="item.route" class="pageheader__navlink" (click)="itemClicked()">{{ item.title | translate}}</a>
  </li>
</ng-template>
