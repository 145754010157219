import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICollectionDto } from 'src/app/shared/dtos/collection-dto';
import { IFestivalDto } from '../../dtos/festival-dto';

@Component({
  selector: 'fspro-festival-card',
  templateUrl: './festival-card.component.html',
  styleUrls: ['./festival-card.component.scss']
})
export class FestivalCardComponent implements OnInit {
  @Input() public collectionItem: ICollectionDto = null;
  @Input() public festivalItem: IFestivalDto = null;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigateCollection(item) {
    let url;
    if (item.type === 'lineup') {
      url = `/line-up/show/${item.slug}`;
    }
    else if (item.type === 'commission') {
      url = `/film-commission/show/${item.slug}`;
    }
    else {
      url = `/${item.type}/show/${item.slug}`;
    }
    this.router.navigate([url]);
  }

  navigateFestival(item) {
    const url =  `/${item.festival.coproduction ? 'coproduction' : 'festival'}/show/${item.festival.slug}/${item.year}`;
    this.router.navigate([url]);
  }
}
