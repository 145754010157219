import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FSProService } from './fspro-service';
import { parameters } from '../parameters';
import { IPrice } from '../entities/price';

@Injectable()
export class PriceService  extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/stripe/prices';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Use this service the get a list of prices
   */
  getList(): Observable<IPrice[]> {
    return this.http.get<IPrice[]>(this.urlBase).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      }));
  }
}
