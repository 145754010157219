import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { mustMatchValidator } from 'src/app/shared/validators/custom-validators';
import { passwordPatternValidator } from "../../shared/validators/password-pattern.validator";

@Component({
  selector: 'fspro-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent {
  private readonly _destroy$ = new Subject();
  resetPasswordForm: FormGroup;
  isUpdatePasswordInform$: Observable<boolean>;
  type: string|null = null;

  public isSending = false;
  constructor(private _userService: UserService,
    private readonly el: ElementRef,
    private readonly snackbar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute
  ) {

    this.isUpdatePasswordInform$ = this.activatedRoute.queryParams.pipe(
      takeUntil(this._destroy$),
      map(({ isUpdatePasswordInform }) => isUpdatePasswordInform)
    );

    this.type = this.activatedRoute.snapshot.data['type'];

    this.resetPasswordForm = this.formBuilder.group({
      passwordField: ['', [
        Validators.required,
        Validators.minLength(12),
        passwordPatternValidator
      ]],
      confirmPasswordField: ['', [Validators.required]]
    }, { validators: mustMatchValidator });

  }

  public onSubmit(): void {
    const { encodedEmail, userHash } = this.activatedRoute.snapshot.params;
    this.resetPasswordForm.markAllAsTouched();
    if (this.resetPasswordForm.valid) {
      this.isSending = true;
      const password = this.resetPasswordForm.value['passwordField'];
      this._userService.changePasswordWithLink(encodedEmail, userHash, password).pipe(finalize(() => this.isSending = false))
        .subscribe(() => {
          this.alertMessage(this.translateService.instant('fspro.front.update_lost_password.form.success'), true);
        },
          (httpError: HttpErrorResponse) => {
            this.alertMessage(httpError?.error?.message || this.translateService.instant('An error occurred!'))
          });
    }
    else {
      this.autoFocusInValidField();
    }
  }

  private alertMessage(message: string, redirect = false) {
    this.snackbar.open(
      message,
      'Close',
      { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
    ).afterDismissed().toPromise().then(() => {
      if (redirect) {
        window.location.replace('/');
      }
    });
  }

  private autoFocusInValidField(): void {
    for (const key of Object.keys(this.resetPasswordForm.controls)) {
      if (this.resetPasswordForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }

}
