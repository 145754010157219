import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IFilmDto } from "../../dtos/film-dto";
import { FilmService } from "../../services/film.service";

@Component({
  selector: 'fspro-share-invitation-dialog',
  templateUrl: 'share-invitation-dialog.component.html',
  styleUrls: ['./share-invitation-dialog.component.scss']
})

export class ShareInvitationDialog {
  isGetLinkSuccess: boolean = false;
  link: string = '';

  @ViewChild("inputLink") inputLink: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ShareInvitationDialog>,
    private filmService: FilmService,
    @Inject(MAT_DIALOG_DATA) public data: { film: IFilmDto }) {
  }

  copyLink() {
    const inputLink = <HTMLInputElement>this.inputLink.nativeElement;

    /* Select the text field */
    inputLink?.select();
    inputLink?.setSelectionRange(0, 99999); /* For mobile devices */

    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(this.link);
    }
  }

  getLink() {
    this.filmService.getInvitationLink(this.data.film.InvitationInfoDto.shareInvitationUrl).subscribe(data => {
      this.isGetLinkSuccess = true;
      const port = window.location.port !== '' ? ':' + window.location.port : '';
      this.link = window.location.protocol + '//' + window.location.hostname + port + data.url;
    });
  }

  onClose(): void {
    this.dialogRef.close({ isGetLinkSuccess: this.isGetLinkSuccess });
  }
}
