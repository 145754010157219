import { Component, ElementRef, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { IEmailMessageResponse } from "../../dtos/email-message.dto";
import { IContactDto } from "../../dtos/film-dto";
import { SendMessageService } from "../../services/contact-to-companyservice";

@Component({
  selector: 'fspro-send-message-dialog',
  templateUrl: 'send-message-dialog.component.html',
  styleUrls: ['./send-message-dialog.component.scss']
})

export class SendMessageDialog {

  public sendMailForm: FormGroup;
  public isSending = false;

  public isCompanyContact = false;
  public isTalentContact = false;


  constructor(public dialogRef: MatDialogRef<SendMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { contact: any, companyName?: string, filmId: number, isCompanyContact: boolean, directorSlug: string, isTalentContact: boolean },
    private el: ElementRef,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private sendMessageService: SendMessageService
  ) {

    this.isCompanyContact = data?.isCompanyContact || false;
    this.isTalentContact = data?.isTalentContact || false;

    this.sendMailForm = new FormGroup({
      receiver: new FormControl(this.isCompanyContact ? data?.companyName : data?.contact.name),
      subjectField: new FormControl(null, Validators.required),
      messageField: new FormControl(null, Validators.required),
      sendACopy: new FormControl(false)
    });
    this.isSending = false;
  }



  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.sendMailForm.markAllAsTouched();

    if (this.sendMailForm.valid) {

      this.isSending = true;

      if (this.isCompanyContact) {
        this.sendMessageToCompany();
      } else {
        if (this.isTalentContact) {
          this.sendMessageToTalent();
        } else {
          this.sendMessageToDirector();
        }
      }


    } else {
      this.autoFocusInValidField();
    }
  }

  private sendMessageToCompany() {
    const formValue = this.sendMailForm.value;
    this.sendMessageService.sendAMessageToCompany({
      isSendCopy: formValue.sendACopy,
      message: formValue.messageField,
      subject: formValue.subjectField,
    }, this.data?.contact.id
      , this.data?.filmId).subscribe((data: IEmailMessageResponse) => {

        this.altertMessage(this.translateService.instant('fspro.front.film.filmproduction.button.message_sent'));

        this.dialogRef.close();
      }, (httpError) => {

        this.altertMessage(httpError?.error.message || 'Something went wrong!');
        this.dialogRef.close();

      });
  }

  private sendMessageToDirector(){
    const formValue = this.sendMailForm.value;
    this.sendMessageService.sendMessageToDirector({
      isSendCopy: formValue.sendACopy,
      message: formValue.messageField,
      subject: formValue.subjectField,
    }, this.data?.directorSlug).subscribe((data: IEmailMessageResponse) => {

        this.altertMessage(this.translateService.instant('fspro.front.film.director.button.message_sent'));

        this.dialogRef.close();
      }, (httpError) => {

        this.altertMessage(httpError?.error || 'Something went wrong!');
        this.dialogRef.close();

      });
  }
  private sendMessageToTalent(){
    const formValue = this.sendMailForm.value;
    this.sendMessageService.sendMessageToTalent({
      isSendCopy: formValue.sendACopy,
      message: formValue.messageField,
      subject: formValue.subjectField,
    }, this.data?.directorSlug).subscribe((data: IEmailMessageResponse) => {

        this.altertMessage(this.translateService.instant('fspro.front.film.director.button.message_sent'));

        this.dialogRef.close();
      }, (httpError) => {

        this.altertMessage(httpError?.error || 'Something went wrong!');
        this.dialogRef.close();

      });
  }

  private altertMessage(message: string) {
    this.snackbar.open(
      message,
      'Close',
      { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
    );
  }

  private autoFocusInValidField(): void {
    for (const key of Object.keys(this.sendMailForm.controls)) {
      if (this.sendMailForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }
}
