<ng-container [ngSwitch]="type">
  <nav *ngSwitchCase="'rubix'" class="bubble" [class.bubble--open]="isToggled" [class.bubble--popup]="isPopup" [ngClass]="additionalClass">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </nav>
  <section *ngSwitchCase="'section'" class="bubble" [class.bubble--open]="isToggled" [class.bubble--popup]="isPopup" [ngClass]="additionalClass">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </section>
  <div *ngSwitchDefault class="bubble" [class.bubble--open]="isToggled" [class.bubble--popup]="isPopup" [ngClass]="additionalClass">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </div>
</ng-container>

<ng-template #contentTpl>
  <ng-container *ngIf="templateRef; else templateRef">
    <ng-content></ng-content>
  </ng-container>
</ng-template>

<ng-template #templateRef [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ isPopup, isToggled, data }"></ng-template>
