<div class="photo-slider-display" *ngIf="photos[activeItem]">
  <div>
    <img [appCacheImage]="photos[activeItem].fileLocation" [alt]="photos[activeItem].fileTitle">
  </div>
</div>

<div class="photo-slider-source">
  <div *ngFor="let photo of photos; let i = index" class="slider-item"
    [ngClass]="activeItem === i ? 'active' : ''" (click)="onChangeItemInSource(i)" [id]="'slider-source-' + i">
    <img [appCacheImage]="photo.fileLocation" />
  </div>
</div>

<div class="photo-slider-scrollbar">
  <button *ngFor="let photo of photos; index as i" [ngClass]="activeItem === i ? 'active' : ''"
    (click)="onChangeItemInScrollBar(i)"></button>
</div>

<div class="photo-slider-actions" *ngIf="isShowDownloadButton">
  <a target="_blank" [href]="downloadUrl" class="download">{{ 'fspro.front.film.extrasposters.button.download' | translate }}</a>
</div>
