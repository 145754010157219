import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IFilmDto } from '../../dtos/film-dto';
import { IProvider } from '../../interfaces/provider.interface';

const NUMBER_OF_FILMS_TO_LOAD = 18;

@Component({
  selector: 'fspro-film-grid',
  templateUrl: './film-grid.component.html',
  styleUrls: ['./film-grid.component.scss']
})
export class FilmGridComponent {

  private _provider: IProvider;
  private _currentProviderSubscribe: Subscription;
  private fullyLoaded: boolean = false;
  private page: number = 1;

  public films: IFilmDto[] = [];
  public loading: boolean = true;

  @Input('isCoproduction') isCoproduction = false;
  @Input() public set provider(value: IProvider) {
    if (value && value) {
      // Tobe sure that if the previous call still waiting for the result,
      // We should skip this.
      this._currentProviderSubscribe?.unsubscribe();
      this._provider = value;
      this.films = [];
      this.page = 1;
      this.fullyLoaded = false;
      this.loading = true;
      this.loadFilms();
    }
  };
  public get provider(): IProvider {
    return this._provider;
  }

  @Input() public isMyFilmsEnabled = false;
  @Input() public isFetchDataOnAction = false;
  @Input() public isAdmin: boolean = false;
  @Input() public isAllowToShare = false;
  @Input() public theme: 'DarkMode' | 'LightMode' = 'LightMode';
  @Input() public isHiddenDirectorName = false;

  /**
   * To inform the parent component that the it has data
   */
  @Output() hasData = new EventEmitter<boolean>();

  constructor() { }

  loadFilms(): void {
    this._currentProviderSubscribe = this.provider.getEntities((this.page - 1) * NUMBER_OF_FILMS_TO_LOAD, NUMBER_OF_FILMS_TO_LOAD)
      .subscribe(
    (data: IFilmDto[]) => {
          data.map((film) => this.films.push(film));
          this.loading = false;
          // in case the amount of films is less than the films based on pages
          // we consider it has been done for the loader
          if (data.length === 0 || this.films.length < this.page  * NUMBER_OF_FILMS_TO_LOAD) {
            this.fullyLoaded = true;
          }
          this.page++;
          this.hasData.emit(this.films.length > 0);
      },
    (error) => console.log(error)
    );
  }

  onScroll(): void {
    if (!this.loading && !this.fullyLoaded) {
      this.loading = true;
      this.loadFilms();
    }
  }

  removeFromWatchList(film: IFilmDto): void {
    if (this.isFetchDataOnAction){
      const keyToDelete = this.films.findIndex(filmInList => filmInList.id === film.id);
      this.films.splice(keyToDelete, 1);
    }
  }
}
