import {  Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { IFilmDto, IContactDto } from '../../dtos/film-dto';
import { IProvider } from '../../interfaces/provider.interface';
import { PreviousWorkProvider } from '../../providers/previous-work.provider';
import { FilmService } from '../../services/film.service';
import { SendMessageDialog } from '../send-message-dialog/send-message-dialog.component';

@Component({
  selector: 'fspro-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent {
  isShowMoreInfo = false;
  previousWorkProvider: IProvider;
  private _film: IFilmDto;

  displayPreworkTitle = true;

  @Input() set film(film: IFilmDto) {
    if (film) {
      this._film = film;
      this.previousWorkProvider = new PreviousWorkProvider(this.filmService, film.id.toString());
    }
  };

  get film(): IFilmDto {
    return this._film;
  }

  constructor(private dialog: MatDialog,
    private filmService: FilmService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private readonly translateService: TranslateService,
    private snackbar: MatSnackBar) {
    this.matIconRegistry.addSvgIcon('play_arrow', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/mat-icon/play_arrow_black_24dp.svg'));
    this.matIconRegistry.addSvgIcon('play_arrow_outline', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/mat-icon/play_arrow_outline_black_24dp.svg'));
    this.matIconRegistry.addSvgIcon('watch_later', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/mat-icon/watch_later_black_24dp.svg'));
    this.matIconRegistry.addSvgIcon('remove', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/mat-icon/remove_black_24dp.svg'));
    this.matIconRegistry.addSvgIcon('close', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/mat-icon/close_black_24dp.svg'));
    this.matIconRegistry.addSvgIcon('remove_circle_outline', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/mat-icon/remove_circle_outline_black_24dp.svg'));
  }

  openSendMail(contact: IContactDto) {
    this.dialog.open(SendMessageDialog, {
      data: { contact: contact, filmId: this.film.id },
      panelClass: 'send-message-dialog-wrapper',
      width: '480px',
      autoFocus: false,
      disableClose: true,
      position: {
        top: "50px"
      }
    });
  }

  addToWatchList(): void {
    this.filmService.addToWatchList(this.film.id).subscribe(
      (film) => {
        this.snackbar.open(
          this.translateService.instant('alert-add-watchlist', {film_title: film.internationalTitle}),
          'Close',
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar'}
        );
        this.film.inWatchList = true;
      }
    );
  }

  removeFromWatchList(): void {
    this.filmService.removeFromWatchList(this.film.id).subscribe(
      (film) => {
        this.snackbar.open(
          this.translateService.instant('alert-remove-watchlist',  {film_title: film.internationalTitle}),
          'Close',
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar'}
        );
        this.film.inWatchList = false;
      }
    );
  }


}
