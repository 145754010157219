import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISort } from '../entities/sort';
import { FSProService } from './fspro-service';
import {parameters} from '../parameters';
import { IProfessionalField } from '../entities/professional-field';

@Injectable()
export class ProfessionalFieldService  extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/professional-fields';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Use this service the get a list of campaigns
   *
   * @param start
   * @param limit
   * @param sort
   */
  getList(start = 0, limit=0, sort: ISort = null, search = null, filters = null): Observable<IProfessionalField[]> {

    const fullUrl = this.buildListUrl(this.urlBase,start,limit,sort,search,filters);

    return this.http.get<IProfessionalField[]>(fullUrl).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      }));
  }

}
