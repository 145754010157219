<div class="grid" [ngClass]="{ 'dark': theme === 'DarkMode'}" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
  <ng-container *ngIf="cinematographers.length > 0">
    <ng-container *ngFor="let director of cinematographers">
      <fspro-director-card
        *ngIf="shouldDisplay(director)"
        [cinematographer]="director"
        [theme]="theme"
        [showType]="showType"
      ></fspro-director-card>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="loading">
    <fspro-skeleton-director-card *ngFor="let i of [].constructor(this.cinematographers.length === 0 ? 6 : this.cinematographers.length % 3 === 0 ? 3 : 1)"></fspro-skeleton-director-card>
  </ng-container>
</div>
