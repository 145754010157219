import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Directive, Input, OnInit } from "@angular/core";
import { MatGridTile } from "@angular/material/grid-list";

interface IResponseColSpan {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

@Directive({
  selector: '[responsiveColSpan]',
})
export class MatGridListDirective implements OnInit {
  @Input('responsiveColSpan') responsiveColSpan: IResponseColSpan;

  constructor(private breakpointObserver: BreakpointObserver, private readonly tile: MatGridTile) {
  }

  DEFAULT_COL_VALUE = {
    XS: 12,
    SM: 12,
    MD: 6,
    LG: 6,
    XL: 6
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.tile.colspan = this.responsiveColSpan.xs || this.DEFAULT_COL_VALUE.XS;
        }

        if (result.breakpoints[Breakpoints.Small]) {
          this.tile.colspan = this.responsiveColSpan.sm || this.DEFAULT_COL_VALUE.SM;
        }

        if (result.breakpoints[Breakpoints.Medium]) {
          this.tile.colspan = this.responsiveColSpan.md || this.DEFAULT_COL_VALUE.MD;
        }

        if (result.breakpoints[Breakpoints.Large]) {
          this.tile.colspan = this.responsiveColSpan.lg || this.DEFAULT_COL_VALUE.LG;
        }

        if (result.breakpoints[Breakpoints.XLarge]) {
          this.tile.colspan = this.responsiveColSpan.xl || this.DEFAULT_COL_VALUE.XL;
        }
      }
    });
  }
}
