<article class="active-account-container">
  <form autocomplete="off" [formGroup]="activeForm" (ngSubmit)="onSubmit()">
    <div class="delimiter-with-content">
      <h1>{{ 'fspro.front.active-account.header.label.title'| translate }}</h1>
    </div>

    <p class="description" [innerHtml]="'fspro.front.active-account.header.label.text' | translate"></p>
    <p class="description" [innerHtml]="'fspro.front.active-account.header.label2.text' | translate"></p>

    <div class="container-spaced">
      <div class="fspro-control-input-wrapper row">
        <div class="col">
          <mat-form-field>
            <mat-label>{{ 'fspro.front.user.form.label.password' | translate}} </mat-label>
            <input name="passwordField" matInput autocomplete="off" autocapitalize="off" class="input-note" type="password" formControlName="passwordField">
            <mat-error *ngIf="activeForm.hasError('required', 'passwordField')">
              {{'Password cannot be empty!' | translate }}
            </mat-error>
            <mat-error *ngIf="activeForm.hasError('minlength', 'passwordField')">
              {{'Password must have at least 12 characters!' | translate }}
            </mat-error>
            <mat-error *ngIf="activeForm.hasError('patternNotMatched', 'passwordField')">
              {{ activeForm.get('passwordField').errors['patternNotMatched'].message }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>{{ 'fspro.front.user.form.label.password_repeat' | translate}} </mat-label>
            <input
              name="confirmPasswordField"
              matInput
              autocomplete="off"
              autocapitalize="off"
              class="input-note"
              type="password"
              formControlName="confirmPasswordField"
            >
            <mat-error *ngIf="activeForm.hasError('required', 'confirmPasswordField')">
              {{'Password cannot be empty!' | translate }}
            </mat-error>
            <mat-error
              *ngIf="activeForm.hasError('mustMatch', 'confirmPasswordField') && activeForm.get('confirmPasswordField').dirty">
              {{'fspro.front.user.form.error.invalid_password_repeat' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-checkbox formControlName="isConfirmTermAndCondition" color="primary">
            {{ 'fspro.front.subscription.userAddress.label.terms' | translate }}
          </mat-checkbox>

          <mat-error *ngIf="activeForm.hasError('required', 'isConfirmTermAndCondition') && activeForm.controls['isConfirmTermAndCondition']?.touched">
            {{ 'You must to read and agree the terms and conditions' | translate }}
          </mat-error>

        </div>
      </div>
    </div>

    <div class="delimiter-with-content submit-wrapper">
      <div>
        <button class="btn-common-send" mat-flat-button color="primary" type="submit" [disabled]="isSending">
          <mat-spinner [color]="'primary'" [diameter]="20" *ngIf="isSending"
            style="display: inline-block; margin-right: 5px;">
          </mat-spinner>
          <span> {{ 'fspro.front.active-account.form.label.next'| translate }}</span>
        </button>

      </div>
    </div>
  </form>
</article>
