<div class="film-card" [ngClass]="{
  'border-bottom': borderBottom,
  'coproduction': isCoproduction,
  'dark': theme === 'DarkMode',
  'no-border': isPermissionsOpened,
  'my-films': isMyFilmsEnabled
}">
  <div class="poster-container">
    <a *ngIf="this.film?.availabilityWrapper?.canAccessPage" [routerLink]="['/', 'film', film.slug]" style="cursor: pointer;" [attr.aria-label]="'view film page ' + film.internationalTitle">
      <ng-container *ngTemplateOutlet="summarize"></ng-container>
    </a>

    <a *ngIf="!this.film?.availabilityWrapper?.canAccessPage" style="cursor: default;" [attr.aria-label]="'view film page ' + film.internationalTitle">
      <ng-container *ngTemplateOutlet="summarize"></ng-container>
    </a>

    <ng-template #summarize>
      <img [appCacheImage]="film.posterUrl" class="poster" alt="poster for {{ film.internationalTitle }}"/>
      <div
        *ngIf="restrictionMessage !== null"
        class="error-message"
        [innerHTML]="restrictionMessage"
        [style.top.px]="this.titleInPoster ? 0 : 'unset'"
        [style.bottom.px]="!this.titleInPoster ? 0 : 'unset'"
      ></div>
      <div *ngIf="titleInPoster" class="title-overlay">
        {{ film.internationalTitle }}
        <div *ngIf="film.originalTitle" class="original-title">{{ '(' + film.originalTitle + ')' }}</div>
      </div>
    </ng-template>
    <button mat-mini-fab *ngIf="displayRemoveButton" (click)="removeRecommendation()" class="remove-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="!titleInPoster" class="title" [ngClass]="{ 'hidden': isPermissionsOpened }">
    <a *ngIf="this.film?.availabilityWrapper?.canAccessPage" [routerLink]="['/', 'film', film.slug]"  [style.cursor]="this.film?.availabilityWrapper?.canAccessPage ? 'pointer' : 'default'"  [attr.aria-label]="'view film page ' + film.internationalTitle">
      <ng-container *ngTemplateOutlet="poster"></ng-container>
    </a>
    <a *ngIf="!this.film?.availabilityWrapper?.canAccessPage"  [style.cursor]="this.film?.availabilityWrapper?.canAccessPage ? 'pointer' : 'default'"  [attr.aria-label]="'view film page ' + film.internationalTitle">
      <ng-container *ngTemplateOutlet="poster"></ng-container>
    </a>
    <ng-template #poster>
      <div #titleElt class="international" [style.height.px]="shortenDetails ? titleHeightInPixel : 'auto'">{{ title }}</div>
      <div class="original" [ngClass]="{short: shortenDetails}"><span *ngIf="film.originalTitle && !isTitleOnTwoLines">{{ '(' + film.originalTitle + ')' }}</span></div>
    </ng-template>
  </div>
  <div class="details" [ngClass]="{'no-border': !hasActionButton(), 'no-flex': isMyFilmsEnabled, 'hidden': isPermissionsOpened }">
    <div class="director" [title]="film.director" [ngClass]="{short: shortenDetails}"><span *ngIf="(film.director != null && film.director != '') && !isHiddenDirectorName">{{ 'fspro.front.filmcard.label.directed_by' | translate }} {{ film.director }}</span></div>
    <ul class="mixed">
      <li *ngIf="film.countries" [title]="film.countries" class="countries" [ngClass]="{short: shortenDetails}">{{ film.countries }}</li>
      <li *ngIf="film.year">{{ film.year }}</li>
      <li *ngIf="film.duration">{{ film.duration }} min</li>
    </ul>
  </div>
  <div class="actions-container">
    <ul class="actions"
      [ngClass]="{ 'hidden': isPermissionsOpened, 'has-actions': film.availabilityWrapper?.canWatchFilm || (!film.availabilityWrapper?.isExpired && film.availabilityWrapper?.missScreeningRequest && film.availabilityWrapper?.hasMembership) || film.trailerUrl !== null || (film.availabilityWrapper?.isLoggedIn && film.availabilityWrapper?.canWatchFilm), 'dark': theme === 'DarkMode'}"
      *ngIf="!isMyFilmsEnabled">
      <li>
        <a mat-flat-button *ngIf="film.availabilityWrapper?.canWatchFilm" (click)="onPlayFilm()">
          <mat-icon>play_arrow</mat-icon> {{ film.movieMediumTxtBtnPlay? film.movieMediumTxtBtnPlay :  'fspro.front.filmcard.label.play_film' | translate }}
        </a>
        <a mat-flat-button *ngIf="!film.availabilityWrapper?.isExpired && film.availabilityWrapper?.missScreeningRequest && film.availabilityWrapper?.hasMembership" [routerLink]="['/', 'film', film.slug]">
          <mat-icon>play_arrow</mat-icon> {{ film.movieMediumTxtBtnPlay? film.movieMediumTxtBtnPlay :  'fspro.front.filmcard.label.play_film' | translate }}
        </a>
      </li>
      <li *ngIf="film.trailerUrl">
        <a mat-flat-button [routerLink]="film.trailerUrl">
          <mat-icon fontSet="material-icons-outlined">play_arrow</mat-icon> {{ film.trailerMediumTxtBtnPlay? film.trailerMediumTxtBtnPlay : 'fspro.front.homepage.homehero.button.trailer' | translate }}
        </a>
      </li>

      <li *ngIf="isAllowToShare && (film?.InvitationInfoDto?.hasUnlimitedSharesAllowed || (film?.InvitationInfoDto?.sharedCounter < film?.InvitationInfoDto?.maxSharesAllowed))">
        <button mat-flat-button (click)="openShareDialog(film)">
          <!-- TODO: Should create the mat-icon -->
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 -4 24 24">
            <g fill="none" fill-rule="evenodd"> <circle cx="11.5" cy="2.5" r="2.5" fill="currentColor"/>
              <circle cx="11.5" cy="11.5" r="2.5" fill="currentColor"/>
              <circle cx="2.5" cy="6.5" r="2.5" fill="currentColor"/>
              <path stroke="currentColor" stroke-linecap="square" d="M11.3 2.5l-8.8 4M3 7.2l8.5 4.3"/>
            </g>
          </svg> {{ 'fspro.front.filmcard.label.share' | translate }}
        </button>
      </li>

      <li *ngIf="!film.inWatchList && film.availabilityWrapper?.isLoggedIn && film.availabilityWrapper?.canWatchFilm && this.isAllowedToAddToWatchlist">
        <button mat-flat-button (click)="addToWatchList()">
          <mat-icon class="watch-later">playlist_add</mat-icon> {{ 'fspro.front.homepage.recommendation.button.later' | translate }}
        </button>
      </li>


      <li *ngIf="film.inWatchList && film.availabilityWrapper?.isLoggedIn && film.availabilityWrapper?.canWatchFilm && this.isAllowedToAddToWatchlist">
        <button mat-flat-button (click)="removeFromWatchList()">
          <mat-icon class="remove-circle" fontSet="material-icons-outlined">playlist_remove</mat-icon> {{ 'fspro.front.filmcard.label.remove' | translate }}
        </button>
      </li>
    </ul>
    <ul class="actions my-films" *ngIf="isMyFilmsEnabled" [ngClass]="{ 'hidden': isPermissionsOpened }">
      <li class="invite keep-button" *ngIf="film.filmPermission?.canInvite ">
        <a [routerLink]="['/','rh','invitation', 'autoselect','form','on',film.id ]">{{'fspro.front.rhlu.toolbar.invite' | translate}}</a>
      </li>

      <li class="chevron screening-request keep-button">
        <a [routerLink]="film?.numberOfPendingScreeningRequests ? ['/','rh','screening-requests','on',film.id ] : ['/','rh','screening-requests']">
          {{'fspro.front.rhlu.toolbar.all_request' | translate}}
        </a>
        <span class="noti" *ngIf="film?.numberOfPendingScreeningRequests">{{ film?.numberOfPendingScreeningRequests }}</span>
      </li>

      <li class="chevron halfwidth sent-invites">
        <a [routerLink]="film?.numberOfInvitationsSent ? ['/','rh','invitations','sent','on',film.id ] : ['/','rh','invitations','sent']">
          {{'fspro.front.rhlu.toolbar.sent_invites' | translate}}
        </a>
      </li>

      <li class="chevron halfwidth screening-report">
        <a [routerLink]="film?.numberOfScreenings ? ['/','rh','screening-reports','on',film.id ] : ['/','rh','screening-reports']">
          {{'fspro.front.rhlu.toolbar.all_reports' | translate}}
        </a>
      </li>
      <li class="chevron halfwidth booking-conditions">
        <a (click)="openBookingConditions()" style="cursor: pointer;" >
          {{'fspro.front.filmcard.label.my_booking_conditions'| translate}}
        </a>
      </li>
      <li class="chevron halfwidth booking-request keep-button">
        <a [routerLink]="['/','rh','booking-requests','on',film.id ]">
         {{'fspro.front.filmcard.label.booking_request' | translate}}
        </a>
        <span class="noti" *ngIf="film?.numberOfPendingBookingRequests">{{film?.numberOfPendingBookingRequests}}</span>
      </li>
    </ul>

    <section class="permission-section" *ngIf="isMyFilmsEnabled" [ngClass]="{ 'open': isPermissionsOpened }">
      <header class="permission-header" (click)="isPermissionsOpened = !isPermissionsOpened">
        <h1>{{'fspro.front.filmcard.label.permissions' | translate}}</h1>

        <span>{{ isPermissionsOpened? '-':  '+' }}</span>
      </header>

      <div class="permission-details">
        <div class="permission-list">
          <div class="item">
            <div class="category">{{'fspro.front.filmcard.label.available_for' | translate}}</div>
            <div class="tag-list">
              <div class="tag-item" *ngFor="let item of film?.filmPermission?.availableFor">{{item}}</div>
            </div>
          </div>

          <div class="item">
            <div class="category">{{'fspro.front.filmcard.label.on_demand' | translate}}</div>
            <div class="tag-list">
              <div class="tag-item" *ngFor="let item of film?.filmPermission?.availableOnDemandFor">{{item}}</div>
            </div>
          </div>
          <div class="item">
            <div class="category">{{'fspro.front.filmcard.label.not_available_for' | translate}}</div>
            <div class="tag-list">
              <div class="tag-item" *ngFor="let item of film?.filmPermission?.notAvailableFor">{{item}}</div>
            </div>
          </div>

          <div class="item">
            <div class="category">{{  (film?.filmPermission?.whitelist ? 'fspro.front.filmcard.label.whitelist_territories' : 'fspro.front.filmcard.label.excluded_territories') | translate}}</div>
            <div class="tag-list">
              <div class="tag-item" *ngFor="let item of film?.filmPermission?.territories">{{item}}</div>
            </div>
          </div>
        </div>

        <div class="permission-button">
          <button (click)="openRequestUpdate()">{{'fspro.front.filmcard.label.request_update' | translate}}</button>
        </div>
      </div>
    </section>
  </div>
</div>
