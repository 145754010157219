import { Injectable } from "@angular/core";
import { parameters } from "../parameters";
import { HttpClient } from "@angular/common/http";
import { ICinematographerDto } from "../dtos/cinematographer-dto";
import { Observable } from "rxjs";
import { IFilter } from "../entities/filter";
import { FSProService } from "./fspro-service";
import { map } from "rxjs/operators";
import { FilmService } from "./film.service";

@Injectable()
export class CinematographerService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/cinematographers';

  constructor(private http: HttpClient, private filmService: FilmService) {
    super();
  }

  public getCinematographers(start: number, limit: number, filters: IFilter[] = null): Observable<ICinematographerDto[]> {
    const fullUrl = this.buildListUrl(this.urlBase + '/hydrated', start, limit, null, null, filters);
    return this.http.get<ICinematographerDto[]>(fullUrl).pipe(
      map((cinematographers) => {
        cinematographers.map((cinematographer) => {
          cinematographer.films.map((film) => {
            this.filmService.addFilmPageUrl(film);
          });
        });
        return cinematographers;
      })
    );
  }
}
