import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { IProvider } from '../../interfaces/provider.interface';
import { ICinematographerDto } from "../../dtos/cinematographer-dto";

const NUMBER_OF_DIRECTORS_TO_LOAD = 18;

@Component({
  selector: 'fspro-director-grid',
  templateUrl: './director-grid.component.html',
  styleUrls: ['./director-grid.component.scss']
})
export class DirectorGridComponent implements OnDestroy {

  @Input() public isAdmin: boolean = false;
  @Input() public isSection: boolean = true;
  @Input() public showType: boolean = false;

  public cinematographers: ICinematographerDto[] = [];
  public loading: boolean = true;
  private fullyLoaded: boolean = false;
  private page: number = 1;
  private _provider: IProvider;
  private _currentProviderSubscribe: Subscription;

  @Input() hiddenDirectors: ICinematographerDto[] = [];

  @Input() public theme: 'DarkMode' | 'LightMode' = 'LightMode';

  @Input() public set provider(value: IProvider) {
    if (value) {
      // Tobe sure that if the previous call still waiting for the result,
      // We should skip this.
      this._currentProviderSubscribe?.unsubscribe();
      this._provider = value;
      this.cinematographers = [];
      this.page = 1;
      this.fullyLoaded = false;
      this.loading = true;
      this.loadDirectors();
    }
  };

  public get provider(): IProvider {
    return this._provider;
  }

  ngOnDestroy(): void {
    this._currentProviderSubscribe.unsubscribe();
  }

  public shouldDisplay(director: ICinematographerDto): boolean {
    if (this.hiddenDirectors.length === 0 || this.hiddenDirectors[0] === null) {
      return true;
    }
    return this.hiddenDirectors.findIndex(i => i.id === director.id) < 0;
  }

  loadDirectors(): void {
    const start = this.isSection ? 0 : (this.page - 1) * NUMBER_OF_DIRECTORS_TO_LOAD;
    const limit = this.isSection ? 200 : NUMBER_OF_DIRECTORS_TO_LOAD;
    this._currentProviderSubscribe = this.provider.getEntities(start, limit).subscribe(
      (data: ICinematographerDto[]) => {
        data.map((cinematographer) => {
          if (cinematographer.films) {
            cinematographer.films.sort((a, b) => a.year > b.year ? -1 : 1);
          }
        });
        this.cinematographers.push(...data);

        this.loading = false;
        if (data.length === 0 || this.isSection) {
          this.fullyLoaded = true;
        }
        this.page++;
      },
      (error) => console.log(error)
    );
  }

  onScroll(): void {
    if (!this.loading && !this.fullyLoaded) {
      this.loading = true;
      this.loadDirectors();
    }
  }
}
