import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FilmService } from '../../services/film.service';
import { NoteDialog } from '../note-dialog/note-dialog.component';

@Component({
  selector: 'booking-conditions-dialog',
  templateUrl: './booking-conditions-dialog.component.html',
  styleUrls: ['./booking-conditions-dialog.component.scss']
})
export class BookingConditionsDialogComponent {

  public conditionsForm: FormGroup;

  private _destroyed = new Subject<void>();

  isLoading = true;
  isSending = false;

  constructor(public dialogRef: MatDialogRef<BookingConditionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { filmId: number },
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private filmService: FilmService) {

      filmService.getBookingConditions(data?.filmId).pipe(takeUntil(this._destroyed), finalize(() => this.isLoading = false)).subscribe((data: {conditions: string}) => {
      this.conditionsForm.setValue({ conditions: data.conditions });
    },
      (error) => {
        // TODO NEED TO update API
      });

    this.conditionsForm = new FormGroup({
      conditions: new FormControl('')
    });

  }


  onSubmit() {
    if (this.conditionsForm.valid) {
      this.isSending = true;

      this.filmService.setBookingConditions(this.data?.filmId, this.conditionsForm.value).subscribe(() => {
        this.snackbar.open(
          this.translateService.instant('The conditions was successfully saved!'),
          'Close',
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
        );
        this.onClose(this.conditionsForm.value.notes);
        () => {
          this.snackbar.open(
            this.translateService.instant('Cannot set the conditions for the film.'),
            'Close',
            { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
          );
          this.onClose();
        }
      });

    }
  }

  onClose(value: string = null): void {
    this._destroyed.next();
    this._destroyed.complete();
    this.dialogRef.close(value);
  }

}
