import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/authentications/authentication.service";
import { IUserDto } from "src/app/shared/dtos/user-dto";
import { environment } from "src/environments/environment";

@Component({
  selector: 'fspro-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {
  private _supportEmail: string;
  user: IUserDto;

  get logoutLink(): string {
    return `<a href="/logout">${this.translateService.instant('fspro.front.error.four-zero-three.message_logout_link')}</a>`;
  }

  get contactUs(): string {
    return `<a href="mailto:${this._supportEmail}">${this.translateService.instant('fspro.front.error.four-zero-three.message_fourth_line_contact_us')}</a>`
  }
  constructor(
    private readonly authService: AuthenticationService,
    private readonly translateService: TranslateService,
  ) {
    this.user = this.authService.user;
    this._supportEmail = environment.supportEmail;
  }

}
