<div style="max-width: 1060px; margin: 0 auto;">
  <article class="project-item-wrapper">
    <ngx-skeleton-loader class="item" appearance="line" animation="pulse" [theme]="{ height: '30px', width: '100%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader class="item" appearance="line" animation="pulse" [theme]="{ height: '30px', width: '100%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader class="item" appearance="line" animation="pulse" [theme]="{ height: '30px', width: '100%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader class="item" appearance="line" animation="pulse" [theme]="{ height: '30px', width: '100%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader  appearance="line" animation="pulse" [theme]="{ height: '50px', width: '180px'}"></ngx-skeleton-loader>

  </article>
</div>
