<button mat-icon-button disableRipple class="close-btn large" (click)="onClose()"></button>

<div class="form-wrapper">
  <p class="screening-left-text" >
    {{ 'fspro.front.film.fresh_start.message' | translate:{'freshStartAllowed':  isUnlimitedScreen? 'infinity' : freshStartAllowed} }}
  </p>

  <div class="action-button">
    <div class="btn-wrapper">
      <button class="btn-send" type="submit" (click)="onResume()">{{ 'fspro.front.film.fresh_start.button.resume' | translate }}</button>
      <button class="btn-send" type="submit" (click)="createNewScreen()">{{ 'fspro.front.film.fresh_start.button.fresh_start' | translate }}</button>
    </div>
  </div>
</div>
