import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { IFilter } from 'src/app/shared/entities/filter';
import { ISort } from 'src/app/shared/entities/sort';
import { parameters } from 'src/app/shared/parameters';
import { FSProService } from 'src/app/shared/services/fspro-service';

import {ICarouselEntityDto} from '../dtos/carousel-entity-dto';
import { ICollectionDto, IType } from '../dtos/collection-dto';
import { IFilmSummaryDto } from '../dtos/film-dto';

@Injectable()
export class CollectionService  extends FSProService {

  private urlBase =  parameters.baseServiceUrl + 'rest/collections';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Use this service the get a list of collections
   */
  getCollectionList(start = 0, limit= 0, sort: ISort = null, search = null, filters: IFilter[] = null): Observable<any[]> {

    const fullUrl = this.buildListUrl(this.urlBase, start, limit, sort, search, filters);
    return this.http.get<any[]>(fullUrl).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      }));
  }

  /**
   * Use this service to retrieve a single element for the carousel.
   */
  getCarousel(id: number): Observable<ICarouselEntityDto> {
    return this.http.get<ICarouselEntityDto>(this.urlBase +'/' + id + '/carousel').pipe(
      map((element) => {
        switch (element.type) {
          case 'label':
            element.pageUrl = '/label/' + element.slug;
            break;
          case 'lineup':
            element.pageUrl = '/line-up/' + element.slug;
            break;
          case 'festival':
            element.pageUrl = '/festival/show/' + element.slug + '/' + element.year;
            break;
        }

        return element;
      })
    );
  }

  /**
   * Returns an array containing all the films ordered by section and then by name.
   * @param id
   */
  getFilms(id: number): Observable<IFilmSummaryDto[]>{
    return this.http.get<IFilmSummaryDto[]>(this.urlBase + '/' + id + '/films');
  }

  getCollectionFromSlug(slug: string): Observable<ICollectionDto> {
    return this.http.get<ICollectionDto>(this.urlBase + '/slug/' + slug);
  }

  // Use this service to get list for line ups/ labels page
  getList(start = 0, limit= 0, type: IType, search = null, sort: ISort = null): Observable<any[]> {
    const fullUrl = this.buildListUrl(this.urlBase + '/list', start, limit, sort, search, []);

    return this.http.get<any[]>(`${fullUrl}&type=${type}`).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      }));
  }

  getHighlights(type: string): Observable<ICollectionDto[]> {
    return this.http.get<any[]>(this.urlBase + '/highlights?type=' + type);
  }
}
