export enum FilterType{
    Match,
    DoNotMatch
}

export interface IFilter{
    field: string;
    value: string;
    type: FilterType;
}
