import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICountry } from '../entities/country';
import { ISort } from '../entities/sort';
import { parameters } from '../parameters';
import { FSProService } from './fspro-service';

@Injectable({
  providedIn: 'root'
})
export class AwardService  extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/awards';

  constructor(private http: HttpClient) {
    super();
  }

  getOrderedAwardsForSection(sectionIdentifier: string): Observable<any> {
    const url = this.urlBase + '/ordered/' + sectionIdentifier;
    return this.http.get<any>(url);
  }
}
