<div class="main-container">
  <div class="poster-container">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ width: '60%', height: '170px', margin: '30px auto', display: 'block' }"></ngx-skeleton-loader>
  </div>
  <div class="details-container">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '40px', width: '100%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '20px', width: '100%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '20px', width: '100%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '20px', width: '100%'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '35px', width: '115px'}"></ngx-skeleton-loader>
  </div>
</div>
