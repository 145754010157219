<div class="director-header-block" [ngClass]="{ 'multi': blockMode === 'multi' }">
  <picture *ngIf="blockMode === 'single'">
    <img [appCacheImage]="cinematographer.pictureList || cinematographer.pictureLarge" [alt]="cinematographer.firstName + cinematographer.lastName">
  </picture>

  <a [routerLink]="'/director/' + cinematographer.slug" class="photo-link" *ngIf="blockMode === 'multi'">
    <div [style.background-image]="'url(' + cinematographer.pictureLarge.replace('http://', 'https://') + ')'"></div>
  </a>

  <header>
    <h1 class="fullname">
      <a [routerLink]="(cinematographer.type ? '/talent/' : '/director/')+ cinematographer.slug">{{ cinematographer.firstName }} {{ cinematographer.lastName }}</a>
    </h1>

    <h2 class="type" *ngIf="cinematographer.type">{{ cinematographer.type.name }}</h2>

    <h2 class="country" >{{ cinematographer.countries.join(', ') }}</h2>

    <nav *ngIf="isShowSendMessageButton">
      <ul>
        <li>
          <a class="send-message" [ngClass]="{ 'multi': blockMode === 'multi' }" (click)="onClickSendMessage()">{{ 'fspro.front.film.filmdirector.button.send_message' | translate }}</a>
        </li>
      </ul>
    </nav>

    <p class="quote" [ngClass]="{ 'single': blockMode === 'single' }" markdown *ngIf="cinematographer.quote">{{ cinematographer.quote }}</p>

    <ng-container *ngIf="isLinkListEnabled">
      <div class="list-link" *ngIf="cinematographer.linksList && cinematographer.linksList.length > 0">
        <ul>
          <li *ngFor="let item of cinematographer.linksList" style="display: inline-block;">
            <a [href]="item.url" target="_blank">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </ng-container>

  </header>
</div>
