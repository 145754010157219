<div *ngIf="searchResultItems && searchResultItems.length > 0">
  <div class="section-title">
    <span class="section-title-text">
      {{sectionTitle}}
    </span>
  </div>
  <div *ngFor="let searchResultItem of searchResultItems; index as i">
    <a class="section-item-wrapper" href="{{searchResultItem.pageUrl}}">
      <div class="section-item-image-wrapper">
        <img class="section-item-image" [appCacheImage]="searchResultItem.imgUrl" />
        <div
          *ngIf="searchResultItem?.availabilityWrapper?.isHidden && (authenticationService.isAdmin || searchResultItem?.availabilityWrapper?.isRHForFilm)"
          class="error-message" [style.top.px]="'unset'" [style.bottom.px]="0">
          {{ 'fspro.front.film.availability_message.hidden' | translate}}
        </div>
      </div>
      <div class="section-item-captions">
        <div class="main-caption" [ngClass]="nonGeneric ? 'uppercase' : ''">
          {{searchResultItem?.mainCaption}}
        </div>
        <div class="sub-caption">
          {{searchResultItem?.subCaption}}
        </div>
        <div class="sub-caption">
          {{searchResultItem?.additionalInfo1}}
        </div>
      </div>
    </a>
  </div>
</div>
