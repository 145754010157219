import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer:DomSanitizer){}
  transform(value) {
    // TODO: A little bit tricky here! As the value was decoded
    return this.sanitizer.bypassSecurityTrustHtml(value.replaceAll("&gt;", '>')
    .replaceAll("&lt;", '<')
    .replaceAll("&#039;", '\'')
    .replaceAll("&quot;", '"'));
  }

}
