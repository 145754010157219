import { IProvider } from "../interfaces/provider.interface";
import { IFilter } from "../entities/filter";
import { Observable } from "rxjs";
import { CinematographerService } from "../services/cinematographer.service";

export class CinematographerProvider implements IProvider {
  constructor(private cinematographerService: CinematographerService, private parameters: IFilter[]) {
  }

  getEntities(startAt: number, limit: number): Observable<any> {
    return this.cinematographerService.getCinematographers(startAt, limit, this.parameters);
  }
}
