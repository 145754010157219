import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFilter } from 'src/app/shared/entities/filter';
import { ISort } from 'src/app/shared/entities/sort';
import { parameters } from 'src/app/shared/parameters';
import { FSProService } from 'src/app/shared/services/fspro-service';
import { ICarouselEntityDto } from 'src/app/shared/dtos/carousel-entity-dto';
import { IFestivalYearDto } from '../dtos/festival-year-dto';
import { IFilmSummaryDto } from '../dtos/film-dto';

@Injectable()
export class FestivalYearService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/festival-years';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Use this service to retrieve a Festival year
   */
  getCarousel(id: number): Observable<ICarouselEntityDto> {
    return this.http.get<ICarouselEntityDto>(this.urlBase + '/' + id + '/carousel');
  }

  /**
   * Use this service the get a list of festival years
   */
  getList(start = 0, limit = 0, sort: ISort = null, search = null, filters: IFilter[] = null): Observable<any[]> {
    const fullUrl = this.buildListUrl(this.urlBase, start, limit, sort, search, filters);

    return this.getUrlData(fullUrl);
  }

  /**
   * Returns an array containing all the films ordered by section and then by name.
   * @param id
   */
  getFilms(id: number): Observable<IFilmSummaryDto[]> {
    return this.http.get<IFilmSummaryDto[]>(this.urlBase + '/' + id + '/films');
  }

  search(search: string): Observable<IFestivalYearDto[]> {
    return this.http.get<IFestivalYearDto[]>(this.urlBase + '/search/' + search);
  }

  // use this service to get list for festival page
  getListForFestivalPage(start = 0, limit = 0, sort: ISort, coproduction: boolean | null, isUpcoming: boolean, country: string): Observable<any[]> {
    let fullUrl = this.buildListUrl(this.urlBase + '/list', start, limit, sort, null, []);

    let params = new HttpParams();

    if (coproduction !== null) {
      params = params.set('coproduction', coproduction.toString());
    }

    params = params.set('isUpcoming', isUpcoming.toString());

    if (country) {
      params = params.set('country', country.toString());
    }

    fullUrl = fullUrl + '&' + params.toString();

    return this.getUrlData(fullUrl);
  }

  /**
   * Gets a festival year fully loaded with section by festival slug and year
   *
   * @param slug
   * @param year
   */
  getByFestivalSlugAndYear(slug: string, year: number): Observable<IFestivalYearDto> {
    const url = this.urlBase + '/' + slug + '/' + year;
    return this.http.get<IFestivalYearDto>(url);
  }

  getListForSearchPage(start = 0, limit = 0, sort: ISort, search): Observable<any[]> {
    let fullUrl = this.buildListUrl(this.urlBase + '/list', start, limit, sort, null, []);
    let params = new HttpParams();
    params = params.set('searchValue', search);
    fullUrl = fullUrl + '&' + params.toString();
    return this.getUrlData(fullUrl);
  }

  getUrlData = (url: string): Observable<any[]> => {
    return this.http.get<any[]>(url).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      }));
  }

}
