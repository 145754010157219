<main id="main" class="container site-main">
  <div class="site-content">
    <div class="errorpage">
      <header>
        <h1>{{ 'fspro.front.error.four-zero-three.title' | translate }}</h1>
      </header>
      <section>
        <p [innerHtml]="'fspro.front.error.four-zero-three.message_first_line' | translate"></p>
        <p *ngIf="user" [innerHtml]="'fspro.front.error.four-zero-three.message_second_line' | translate: { fullName: user.fullname, email: user.email }"></p>
        <p *ngIf="user" [innerHtml]="'fspro.front.error.four-zero-three.message_third_line' | translate: { logoutLink: logoutLink }"></p>
        <p [innerHtml]="'fspro.front.error.four-zero-three.message_fourth_line' | translate: { contactus :contactUs}"></p>
      </section>
    </div><!-- .box -->
  </div><!-- .site-content -->
</main>
