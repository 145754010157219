import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { PageService } from '../services/page.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authentication: AuthenticationService,
    private snackbar: MatSnackBar,
    private cookieService: CookieService,
    private router: Router,
    private pageService: PageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if account is logged in and request is to the api url
    const isLanguageUrl = request.url.includes('fspro-translations');
    const isAuthenticationProcess = request.url.includes('login_check') ||
      request.url.includes('check-user-device') ||
      request.url.includes('verify-code') ||
      request.url.includes('token/refresh') ||
      request.url.includes('token/invalidate');
    const skip404 = request.headers.get('skip-route-check') === 'true';
    const impersonation = this.cookieService.get(AuthenticationService.IMPERSONATION_COOKIE_NAME).split('|')[0];

    if (impersonation !== null && impersonation !== '' && !isAuthenticationProcess && this.authentication.isAdmin) {
      request = request.clone({
        setHeaders: { 'X-Switch-User': impersonation }
      });
    }

    return next.handle(request).pipe(
      catchError(error => this.handleError(request, next, error, isAuthenticationProcess, isLanguageUrl, skip404)));
  }

  private handleError(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse, isAuthenticationProcess = false, isLanguageUrl = false, skip404 = false): Observable<any> {
    if (isLanguageUrl || skip404) return throwError(error);

    switch (error.status) {
      case 404:
        this.pageService.setPageAsNotFound();
        break;
      case 403:
        if (!isAuthenticationProcess) {
          this.pageService.setPageAsForbidden();
        }
        break;
      case 401:
        if (isAuthenticationProcess) {
          break;
        }
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);

          return this.refreshToken().pipe(
            switchMap((token: any) => {
              this.isRefreshing = false;
              this.refreshTokenSubject.next(token);
              return next.handle(request);
            })
          );
        } else {
          return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            switchMap(() => {
              return next.handle(request);
            })
          );
        }
      default:
        break;
    }
    return throwError(error);
  }

  private refreshToken() {
    return this.authentication.refreshToken().pipe(
      catchError((error) => {
        this.isRefreshing = false;
        this.authentication.signOut();
        this.snackbar.open(
          'Your current session has been expired!',
          'Close',
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
        ).afterDismissed().subscribe(() => {
          window.location.href = '/';
        });

        return throwError(error);
      })
    )
  }
}
