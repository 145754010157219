import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fspro-skeleton-film-card',
  templateUrl: './skeleton-film-card.component.html',
  styleUrls: ['./skeleton-film-card.component.scss']
})
export class SkeletonFilmCardComponent implements OnInit {
  @Input() public theme?: 'DarkMode'| 'LightMode';

  constructor() { }

  ngOnInit(): void {
  }

}
