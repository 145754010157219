<button mat-icon-button disableRipple class="close-btn" (click)="onClose()"></button>

<form class="form-wrapper" [formGroup]="formGroup" (ngSubmit)="onSubmitted()">
  <header class="section-header">
    <h1>{{'fspro.front.modal-request-update.form.title' | translate}}</h1>
  </header>

  <div class="field-wrapper">
    <div class="fspro-control-input-wrapper">
      <mat-form-field>
        <mat-label>{{'fspro.front.film_request_update.form.label.body' | translate}}</mat-label>
        <textarea name="body" matInput autocomplete="off" autocapitalize="off" cdkTextareaAutosize formControlName="body" class="input-note"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="action-button">
    <div class="fspro-control-checkbox-wrapper">
      <mat-checkbox formControlName="sendCopy"> Send a copy</mat-checkbox>
    </div>

    <div class="btn-wrapper">
      <button class="btn-cancel" type="button" (click)="onClose()">Back</button>

      <button  class="btn-send" mat-flat-button color="primary" type="submit" [disabled]="isSending || formGroup.invalid">
        <mat-spinner [color]="'primary'" [diameter]="20" *ngIf="isSending" style="display: inline-block; margin-right: 5px;">
        </mat-spinner>
        <span> {{ (isSending? 'Sending' : 'fspro.front.modal-request-update.button.ok' )| translate }}</span>
      </button>
    </div>
  </div>

</form>
