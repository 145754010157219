import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { parameters } from 'src/app/shared/parameters';
import { FSProService } from 'src/app/shared/services/fspro-service'
import { FilmService } from './film.service';
import { ICollectionSectionDto } from '../dtos/collection-section-dto';

@Injectable()
export class CollectionSectionService  extends FSProService {

  private urlBase =  parameters.baseServiceUrl + 'rest/collection-sections';

  constructor(private http: HttpClient, private _filmService: FilmService) {
    super();
  }

  /**
   * Use this service the get a list of collections
   */
  getSectionForFilms(ids: number[]): Observable<ICollectionSectionDto[]> {
    return this.http.get<ICollectionSectionDto[]>(`${this.urlBase}/get-section-for-films/${ids.join(',')}`);
  }
}
