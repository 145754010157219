import { Component, ElementRef, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { ScreenRequestService } from "../../services/screen-request.service";

@Component({
  selector: 'fspro-send-request-dialog',
  templateUrl: 'send-request-dialog.component.html',
  styleUrls: ['./send-request-dialog.component.scss']
})

export class SendRequestDialog {

  public sendScreenRequestForm: FormGroup;
  public isSending = false;


  constructor(public dialogRef: MatDialogRef<SendRequestDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { filmSlug: string, translations: any },
    private el: ElementRef,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private screenRequestService: ScreenRequestService
  ) {
    this.sendScreenRequestForm = new FormGroup({
      messageField: new FormControl(null),
      sendACopy: new FormControl(false)
    });
    this.isSending = false;
  }

  get messageFieldValue() {
    return this.sendScreenRequestForm.get('messageField').value
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.sendScreenRequestForm.markAllAsTouched();
    if (this.sendScreenRequestForm.valid) {
      const formValue = this.sendScreenRequestForm.value;
      this.isSending = true;
      this.screenRequestService.sendRequest(formValue.messageField, formValue.sendACopy, this.data.filmSlug).subscribe(() => {
        this.snackbar.open(
          this.translateService.instant('fspro.front.screeningrequests.message_sent'),
          'Close',
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
        );

        this.dialogRef.close(true);
      }, (httpError) => {
        const beMessage = httpError.error;
        this.snackbar.open(
          beMessage?.message,
          'Close',
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
        );
        this.dialogRef.close(false);
      });


    } else {
      this.autoFocusInValidField();
    }
  }

  private autoFocusInValidField(): void {
    for (const key of Object.keys(this.sendScreenRequestForm.controls)) {
      if (this.sendScreenRequestForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }
}
