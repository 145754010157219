import { Directive, ElementRef, Input } from "@angular/core";
import { ImageUtils } from "./image-utils";
import { IFilmDto } from "../dtos/film-dto";

@Directive({
  selector: '[pictureSourceCacheUrl]',
})
export class PictureSourceCacheUrlDirective extends ImageUtils {

  @Input('pictureSourceCacheUrl') set pictureSourceCacheUrl(value: { film: IFilmDto, resolution: string }) {
    if (value && 'film' in value && 'resolution' in value) {
      this.film = value.film;
      this.handleFilmDto(value.film, value.resolution);
    } else {
      console.error('Wrong value provided.', value);
    }
  }

  constructor(private el: ElementRef) {
    super();
  }

  private handleFilmDto(film: IFilmDto, resolution: string) {
    const url = this.getUrlFromFilm(film, resolution);
    this.setUrl(url, resolution);
  }

  private setUrl(url: string, resolution: string) {
      this.el.nativeElement.srcset = this.getCacheUrl(url);
      this.el.nativeElement.classList.add(`resolution-${resolution}`);
  }
}
