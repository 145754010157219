<div class="forgot-password-container">
  <ng-container *ngIf="!(isUpdatePasswordInform$ | async); else UpdatePW">
    <form autocomplete="off" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <div class="delimiter-with-content">
        <h1 *ngIf="type === 'reset-password'">{{ 'fspro.front.update_lost_password.header.label.title' | translate }}</h1>
        <h1 *ngIf="type === 'must-change-password'">{{ 'fspro.front.must_change_password.header.label.title' | translate }}</h1>
      </div>

      <p class="description" *ngIf="type === 'reset-password'">{{'fspro.front.update_lost_password.form.instruction' | translate }}</p>
      <p class="description" *ngIf="type === 'must-change-password'">{{'fspro.front.must_change_password.about' | translate }}</p>
      <p class="description">{{'fspro.front.update_lost_password.form.instruction2' | translate }}</p>

      <div class="input-wrapper">

        <div class="fspro-control-input-wrapper">
          <mat-form-field>
            <mat-label>{{ 'fspro.front.user.form.label.password' | translate }}</mat-label>
            <input name="passwordField" type="password" matInput autocomplete="off" autocapitalize="off" class="input-note" formControlName="passwordField">
            <mat-error *ngIf="resetPasswordForm.hasError('required', 'passwordField')">
              {{'Your password cannot be empty!' | translate }}
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.hasError('minlength', 'passwordField')">
              {{'Your password must have at least 12 characters!' | translate }}
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.hasError('patternNotMatched', 'passwordField')">
              {{ resetPasswordForm.get('passwordField').errors['patternNotMatched'].message }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="fspro-control-input-wrapper">
          <mat-form-field>
            <mat-label>{{ 'fspro.front.user.form.label.password_repeat' | translate }}</mat-label>
            <input name="confirmPasswordField" type="password" matInput autocomplete="off" autocapitalize="off" class="input-note" formControlName="confirmPasswordField">
            <mat-error *ngIf="resetPasswordForm.hasError('required', 'confirmPasswordField')">
              {{'Password cannot be empty!' | translate }}
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.hasError('mustMatch', 'confirmPasswordField') && resetPasswordForm.get('confirmPasswordField').dirty">
              {{ 'Passwords do not match.' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>


      <div class="delimiter-with-content submit-wrapper">
        <div>
          <button class="btn-common-send" mat-flat-button color="primary" type="submit" [disabled]="isSending || resetPasswordForm.invalid">
            <mat-spinner [color]="'primary'" [diameter]="20" *ngIf="isSending" style="display: inline-block; margin-right: 5px;">
            </mat-spinner>
            <span> {{ (isSending? 'Sending' : 'fspro.front.signup.form.label.next' )| translate }}</span>
          </button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-template #UpdatePW>
    <div class="delimiter-with-content">
      <h1>{{ 'required-update-password-title' | translate }}</h1>
    </div>

    <p class="description">
      {{ 'required-update-description' | translate }}
    </p>
  </ng-template>

</div>
