import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDirectorDto } from '../dtos/director-dto';
import { IFilter } from '../entities/filter';
import { ISort } from '../entities/sort';
import { parameters } from '../parameters';
import { FSProService } from './fspro-service';
import { map } from "rxjs/operators";
import { FilmService } from "./film.service";

@Injectable()
export class DirectorService extends FSProService {

  private urlBase = parameters.baseServiceUrl + 'rest/directors';

  constructor(private http: HttpClient, private filmService: FilmService) {
    super();
  }

  /**
   * Use this service to retrieve a list of film with all the details including rights.
   * @param start
   * @param limit
   * @param sort
   * @param searchValue
   * @param filters
   */
  getDirectors(start: number, limit: number, sort: ISort = null, searchValue = null, filters: IFilter[] = null): Observable<IDirectorDto[]> {
    let fullUrl = this.buildListUrl(this.urlBase + '/hydrated', start, limit, sort, null, filters);
    let params = new HttpParams();
    if (searchValue !== null) {
      params = params.set('searchValue', searchValue);
    }
    fullUrl = fullUrl + '&' + params.toString();

    return this.http.get<IDirectorDto[]>(fullUrl).pipe(
      map((directors) => {
        directors.map((director) => {
          director.films.map((film) => {
            this.filmService.addFilmPageUrl(film);
          });
        });
        return directors;
      })
    );
  }


  /**
   *
   * @param slug Director's slug
   * @returns Director data
   */
  getDirector(slug: string): Observable<IDirectorDto> {
    const fullUrl = `${this.urlBase}/by-slug/${slug}`;
    return this.http.get<IDirectorDto>(fullUrl);
  }



}
