import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, map, takeUntil, tap } from "rxjs/operators";

export enum PageType {
  Normal,
  NotFound,
  Forbidden
};

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private readonly _destroy$ = new Subject();
  private readonly _type$ = new BehaviorSubject<PageType>(PageType.Normal);

  get type$(): Observable<PageType> {
    return this._type$.asObservable();
  }

  constructor(private readonly router: Router) {
    this.router.events.pipe(
      takeUntil(this._destroy$),
      filter(evt => evt instanceof NavigationStart),
      tap(() => this.setPageAsNormal())
    ).subscribe();
  }

  setPageAsNotFound(): void {
    this._type$.next(PageType.NotFound);
  }

  setPageAsForbidden(): void {
    this._type$.next(PageType.Forbidden);
  }

  setPageAsNormal(): void {
    this._type$.next(PageType.Normal);
  }
}
