import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fspro-skeleton-festival-card',
  templateUrl: './skeleton-festival-card.component.html',
  styleUrls: ['./skeleton-festival-card.component.scss']
})
export class SkeletonFestivalCardComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
