<p class="subscription-status" [innerHTML]="membershipStatusMessage" [ngClass]="{ short: shortCardDisplay }"></p>

<div class="main-container" [ngClass]="{ short: shortCardDisplay }">
  <div *ngIf="canDisplaySwitchToYearly()" class="button-group">
    <a (click)="redirectToBillingPortal()" class="btn-switch-to-year btn-play">
      <fa-icon [icon]="faCaretRight" [classes]="['fa-xs']" ></fa-icon>
      <span>{{ 'fspro.front.my-infos.switch_to_year' | translate }}</span>
    </a>
    <p
      *ngIf="this.getMonthlyPrice() > 0 && this.getYearlyPrice() > 0"
      class="switch-to-yearly-notice"
      [innerHTML]="'fspro.front.my-infos.header.label.cost' | translate:{yearlyPrice: this.getYearlyPrice(), monthlyPrice: this.getMonthlyPrice()}"
    >
    </p>
  </div>
  <!-- Different redirection for legacy accounts, but may have to also display a different text as in this case we currently can also switch to stripe monthly ! -->
  <div *ngIf="canDisplaySwitchToYearlyLegacy()" class="button-group">
    <a (click)="redirectToSubscriptionPage()" class="btn-switch-to-year btn-play">
      <fa-icon [icon]="faCaretRight" [classes]="['fa-xs']" ></fa-icon>
      <span>{{ 'fspro.front.my-infos.switch_to_year' | translate }}</span>
    </a>
    <p
      *ngIf="this.getMonthlyPrice() > 0 && this.getYearlyPrice() > 0"
      class="switch-to-yearly-notice"
      [innerHTML]="'fspro.front.my-infos.header.label.cost' | translate:{yearlyPrice: this.getYearlyPrice(), monthlyPrice: this.getMonthlyPrice()}"
    >
    </p>
  </div>
  <div *ngIf="canDisplayRenewButton()" class="button-group">
    <a (click)="redirectToSubscriptionPage()" class="btn-refresh">
      <fa-icon [icon]="faRetweet" [classes]="['fa-xs']" ></fa-icon>
      <span>{{ 'fspro.front.my-infos.renew_membership' | translate }}</span>
    </a>
  </div>
  <div *ngIf="canDisplayAwaitingPaymentButton()" class="button-group">
    <a (click)="redirectToBillingPortal()" class="btn-refresh">
      <fa-icon [icon]="faRetweet" [classes]="['fa-xs']" ></fa-icon>
      <span>{{ 'fspro.front.my-infos.finalize_payment' | translate }}</span>
    </a>
  </div>
  <div *ngIf="canDisplayUpgradeButton()" class="button-group">
    <a (click)="redirectToSubscriptionPage()" class="btn-refresh">
      <fa-icon [icon]="faRetweet" [classes]="['fa-xs']" ></fa-icon>
      <span>{{ 'fspro.front.my-infos.upgrade_membership' | translate }}</span>
    </a>
  </div>
  <div *ngIf="canDisplayCancelButton()" class="button-group">
    <a (click)="membership.isLegacy ? openCancelDialog() : redirectToBillingPortal()" class="btn-play">
      <fa-icon [icon]="faCaretRight" [classes]="['fa-xs']" ></fa-icon>
      <span>{{ 'fspro.front.my-infos.cancel_membership' | translate }}</span>
    </a>
  </div>
  <div *ngIf="canDisplayChangeCardButton()" class="button-group">
    <a (click)="redirectToBillingPortal()" class="btn-play">
      <fa-icon [icon]="faCaretRight" [classes]="['fa-xs']" ></fa-icon>
      <span *ngIf="membership.hasPaymentMethod">{{ 'fspro.front.my-infos.change_card' | translate }}</span>
      <span *ngIf="!membership.hasPaymentMethod">{{ 'fspro.front.my-infos.add_card' | translate }}</span>
    </a>
  </div>
</div>
