<button mat-icon-button disableRipple class="close-btn" (click)="onClose()"></button>

<form class="form-wrapper" [formGroup]="sendScreenRequestForm" (ngSubmit)="onSubmit()">
  <header class="section-header">
    <h1>{{ 'fspro.front.screeningrequests.form.title' | translate }}</h1>
  </header>

    <div class="field-wrapper">
      <div class="fspro-control-input-wrapper">
        <mat-form-field>
          <mat-label [title]="'fspro.front.screeningrequests.form.label.body' | translate">{{ 'fspro.front.screeningrequests.form.label.body' | translate }}</mat-label>
          <textarea
            name="messageField"
            matInput
            formControlName="messageField"
            autocomplete="off"
            autocapitalize="off"
            cdkTextareaAutosize
            class="input-note"
            [ngClass]="messageFieldValue ? 'has-value' : ''"
          ></textarea>
        </mat-form-field>
      </div>
    </div>


    <div class="action-button">
      <div class="fspro-control-checkbox-wrapper">
        <mat-checkbox formControlName="sendACopy"> {{ 'fspro.front.film.filmproduction.button.send_copy' | translate }}</mat-checkbox>
      </div>
      <div class="btn-wrapper">
        <button class="btn-cancel" type="button" (click)="onClose()">Back</button>
        <button class="btn-send" type="submit" [disabled]="isSending">{{ 'fspro.front.film.filmproduction.button.send' | translate }}</button>
      </div>
    </div>

</form>
