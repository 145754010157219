import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentications/authentication.service';

@Component({
  selector: 'fspro-popup-search-result-section',
  templateUrl: './popup-search-result-section.component.html',
  styleUrls: ['./popup-search-result-section.component.scss']
})
export class PopupSearchResultSectionComponent implements OnInit {
  
  constructor(public authenticationService: AuthenticationService) { }

  @Input() searchResultItems;
  @Input() sectionTitle: string;
  @Input() nonGeneric: boolean = false;

  ngOnInit(): void { }

}
