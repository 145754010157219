import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild, AfterViewInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize, takeUntil } from "rxjs/operators";
import { AuthenticationService } from "src/app/authentications/authentication.service";
import { NavbarItem } from "src/app/interfaces/navbar-item.interface";
import { HeaderWidgetComponent } from "src/app/shared/components/header-widget/header-widget";
import { BubbleComponent } from "../../shared/components/bubble/bubble.component";
import { NavigationStart, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { FsproStripeService } from "../../stripe-subscription/services/fspro-stripe.service";
import { forkJoin, Subject} from "rxjs";
import { MembershipService } from "../../shared/services/membership.service";
import { NotificationsFacade } from "src/app/facades/notifications.facade";
import { UserService } from "../../shared/services/user.service";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from '@angular/material/snack-bar';

const authenticatedUrls = [
  '/my-invitations/list',
  '/rh/invitations/sent',
  '/rh/my-films',
  '/rh/booking-requests',
  '/rh/screening-reports',
  '/rh/screening-requests',
  '/rh/invitation/autoselect/form',
  '/my-account/my-infos',
  '/my-feed',
  '/my-lists/watched',
  '/my-lists/to-watch',
  '/my-notes/list',
  '/my-bookings'
];

@Component({
  selector: 'fspro-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() toggleContactEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('loginWidget', { read: HeaderWidgetComponent }) loginWidget: HeaderWidgetComponent;
  @ViewChild('rubixBox', { read: BubbleComponent }) rubixBox: BubbleComponent;
  @ViewChild('myAccountBox', { read: BubbleComponent }) myAccountBox: BubbleComponent;
  @ViewChild('rubixToggle') rubixToggle: ElementRef;
  @ViewChild('myAccountBoxToggle') myAccountBoxToggle: ElementRef;
  @ViewChild('searchWidget', { read: HeaderWidgetComponent }) searchWidget: HeaderWidgetComponent;

  private readonly _destroy$ = new Subject();

  loginForm: FormGroup;
  canAccessBookingFeature: boolean = false;
  loginErrorMessage: string | null = null;

  verificationCodeForm: FormGroup;

  navLinks: NavbarItem[] = [
    {
      title: 'fspro.front.header.nav.label.festivals',
      route: '/festivals',
      name: 'festival',
      activates: ['/festival/show/:slug', '/coproduction/show/:slug']
    },
    {
      title: 'fspro.front.header.nav.label.labels',
      route: '/labels',
      name: 'labels',
      activates: ['/label/show/:slug']
    },
    {
      title: 'fspro.front.header.nav.label.line_ups',
      route: '/line-ups',
      name: 'lineups',
      activates: ['/line-up/show/:slug'],
      with: () => this.isAuthenticated
    },
    /*{
      title: 'Film Commissions',
      route: '/film-commissions',
      name: 'commissions',
      activates: ['/film-commission/show/:slug']
    },*/
    {
      title: 'fspro.front.header.nav.label.films',
      route: '/films',
      name: 'film',
      activates: ['/film/:slug']
    },
    {
      title: 'fspro.front.header.nav.label.directors',
      route: '/directors',
      name: 'directors',
      activates: ['/director/:slug']
    },
    {
      title: 'fspro.front.header.nav.label.scope_expanded',
      route: '/scope-expanded/show',
      activates: ['/scope-expanded/show/:slug'],
      name: 'scope-expanded'
    },
    {
      title: 'fspro.front.header.nav.label.join',
      route: '/sign-up',
      name: 'sign-up',
      isJoin: true,
      with: () => !this.isAuthenticated
    }
  ];

  isAuthenticated: boolean;
  hasRoleRh: boolean;
  isLoginFailed: boolean;
  isToggleMyAccount: boolean;
  isLogging: boolean;
  isToggleToolbar: boolean;
  displayRhToolbar: boolean = false;
  stripePortalUrl: string | null = null;
  shouldRenewOrUpgrade: string | null;
  shouldDisplayRHNavBar: boolean = false;

  public mustVerifyCode: boolean = false;
  public isVerifyingCode: boolean = false;
  public couldResendCode: boolean = false;
  public isResendingCode: boolean = false;

  get user(): { username: string, fullname: string, membershipUpdateStatus: string } {
    return this.authentication.user;
  }

  get isRenewOrUpgradeUser(): boolean {
    return this.user?.membershipUpdateStatus === 'renew' || this.user?.membershipUpdateStatus === 'upgrade';
  }

  constructor(
    private readonly fb: FormBuilder,
    public readonly authentication: AuthenticationService,
    private renderer: Renderer2,
    public notificationsFacade: NotificationsFacade,
    private cookieService: CookieService,
    private stripeService: FsproStripeService,
    private membershipService: MembershipService,
    private userService: UserService,
    private router: Router,
    private translator: TranslateService,
    private snackbar: MatSnackBar
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart && this.searchWidget.isToggled) {
        this.searchWidget.dismiss();
      }

      if (event instanceof NavigationStart && this.loginWidget.isToggled) {
        this.loginWidget.dismiss();
      }
    });
  }
  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'click', (event) => {
      if (!this.rubixBox.isToggled && (this.myAccountBox === undefined || !this.myAccountBox.isToggled)) {
        return;
      }
      if (this.rubixBox.isToggled && event.target !== this.rubixBox && event.target !== this.rubixToggle.nativeElement) {
        this.rubixBox.close();
      }
      if (this.myAccountBox !== undefined && this.myAccountBox.isToggled && event.target !== this.myAccountBox && event.target !== this.myAccountBoxToggle.nativeElement) {
        this.myAccountBox.close();
      }
    })
  }

  ngOnInit(): void {
    this.isAuthenticated = this.authentication.isAuthenticated;
    this.hasRoleRh = this.authentication.hasRole('ROLE_RHLU');
    this.membershipService.shouldUpdateMembership().subscribe((data) => {
      if (data === 'renew' || data === 'upgrade') {
        this.shouldRenewOrUpgrade = data;
      }
    });
    forkJoin([this.userService.shouldVerify(), this.userService.mustChangePassword()])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([cacheVerifyData, cacheMustChangePasswordData]) => {
        this.shouldDisplayRHNavBar = !cacheVerifyData && !cacheMustChangePasswordData;
      }
    );
    if (this.isAuthenticated) {
      const impersonation = this.cookieService.get(AuthenticationService.IMPERSONATION_COOKIE_NAME);
      if (impersonation !== null && impersonation !== '') {
        const isRHLU = impersonation.split('|')[1] === 'true';
        if (isRHLU) {
          this.displayRhToolbar = true;
        }
      } else {
        this.displayRhToolbar = this.hasRoleRh;
      }
    }

    this.userService.canAccessBookingFeature().pipe(takeUntil(this._destroy$)).subscribe((data) => this.canAccessBookingFeature = data.access);

    this.loginForm = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required],
      remember_me: [1]
    });

    this.verificationCodeForm = this.fb.group({
      verificationCode: ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
    })

    if (this.displayRhToolbar) {
      this.notificationsFacade.loadPendingScreeningRequest();
      this.notificationsFacade.loadPendingBookingRequest();
    }
    if (this.authentication.isAuthenticated && this.authentication.isUserType) {
      this.stripeService.getPortalAddress().subscribe((url) => { this.stripePortalUrl = url.url });
    }
  }

  onToggledLoginChanged(isToggled: boolean): void {
    if (!isToggled) {
      this.isLoginFailed = false;
      this.loginForm.reset();
      this.verificationCodeForm.reset();
      if (this.mustVerifyCode) {
        this.isLogging = false;
      }
      this.mustVerifyCode = false;
      this.isVerifyingCode = false;
    }
  }

  onLogin(): void {
    this.isLogging = true;
    this.loginForm.disable();
    this.userService.checkUserDevice(this.loginForm.get('username').value).pipe(takeUntil(this._destroy$)).subscribe(
      (data) => {
        if (!data.mustVerifyCode) {
          this.login();
        } else {
          this.mustVerifyCode = true;
        }
        if (this.isResendingCode) {
          this.snackbar.open(
            `Code successfully resent!`,
            'Close',
            {duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar'}
          );
        }
        this.couldResendCode = false;
        this.isResendingCode = false;
        this.isLoginFailed = false;
      },
      (error) => {
        console.error(error);
        this.loginErrorMessage = typeof error.error === 'string' ?
          (error.error === 'Your account has been locked' ?
            'Your account has been locked. Please contact <a class="white-link" href="mailto:feedback@festivalscope.com">feedback@festivalscope.com</a>.' :
            error.error) :
          error.error.message;
        this.isLoginFailed = true;
        this.isLogging = false;
        this.mustVerifyCode = false;
        this.loginForm.enable();
      }
    );
  }

  private login() {
    this.authentication.authenticate(this.loginForm.getRawValue())
      .pipe(
        finalize(() => {
          this.isLogging = false;
          this.loginForm.enable();
        })
      )
      .subscribe(
        () => {
          this.userService.logUserAuthentication().toPromise().then(() => {
            this.isAuthenticated = this.authentication.isAuthenticated;
            this.loginWidget.dismiss();
            window.location.reload();
          });
        },
        (error) => {
          this.loginErrorMessage = error.error.message !== 'Invalid credentials.' ? error.error.message + this.translator.instant('fspro.front.header.login_too_many.label.error') : this.translator.instant('fspro.front.header.login.label.error');
          if (error.error.message !== 'Invalid credentials.') {
            console.log(error);
          }
          this.isLoginFailed = true;
        }
      );
  }

  async onSignOut(): Promise<void> {
    this.isToggleMyAccount = false;
    this.isAuthenticated = false;
    await this.authentication.signOut();
    const currentRoute = this.router.routerState.snapshot.url;
    if (!authenticatedUrls.includes(currentRoute)) {
      window.location.reload();
    } else {
      window.location.replace('/');
    }
  }

  public verifyCode() {
    this.isVerifyingCode = true;
    this.userService.verifyCode(
      this.verificationCodeForm.get('verificationCode').value,
      this.loginForm.get('username').value
    ).pipe(takeUntil(this._destroy$)).subscribe(
      (data) => {
        if (data.success) {
          this.verificationCodeForm.reset();
          this.mustVerifyCode = false;
          this.isVerifyingCode = false;
          this.isLoginFailed = false;
          this.couldResendCode = false;
          this.isResendingCode = false;
          this.login();
        }
      },
      (error) => {
        this.isVerifyingCode = false;
        console.error('error', error.error);
        if (error.error.message && error.error.message === 'Too many requests, your account has been locked.') {
          this.loginErrorMessage = 'Too many requests, your account has been locked. Please contact <a class="white-link" href="mailto:feedback@festivalscope.com">feedback@festivalscope.com</a>.';
        } else {
          this.loginErrorMessage = typeof error.error === 'string' ? error.error : error.error.message;
        }

        this.verificationCodeForm.get('verificationCode').setValue('');

        if (error.status && error.status === 410) {
          this.couldResendCode = true;
        }

        this.isLoginFailed = true;
      }
    );
  }

  resendCode() {
    this.isResendingCode = true;
    this.onLogin();
  }
}
