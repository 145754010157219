import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { parameters } from 'src/app/shared/parameters';
import { FSProService } from 'src/app/shared/services/fspro-service';
import { IMembership } from '../entities/membership';

@Injectable()
export class MembershipService  extends FSProService {

  private legacyUrlBase = parameters.baseServiceUrl + 'rest/legacy-memberships';
  private urlBase = parameters.baseServiceUrl + 'rest/memberships';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Use this service to cancel a membership (set will stop to end at expiry date)
   */
  cancel(): Observable<IMembership> {
    return this.http.get<IMembership>(this.legacyUrlBase + '/cancel');
  }

  /**
   * Use this service to get the current user's membership
   */
  getForCurrentUser(): Observable<IMembership> {
    return this.http.get<IMembership>(this.urlBase + '/current');
  }

  /**
   * Use this service to know if the current user is eligible for membership.
   */
  isCurrentUserEligible(): Observable<boolean> {
    return this.http.get<boolean>(this.urlBase + '/eligible');
  }

  shouldUpdateMembership(): Observable<string> {
    return this.http.get<string>(this.urlBase + '/should-update');
  }
}
