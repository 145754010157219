import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { finalize } from "rxjs/operators";
import { IFilmDto } from "../../dtos/film-dto";
import { ContactUsService } from "../../services/contact-us.service";

@Component({
  selector: 'fspro-request-update-dialog',
  templateUrl: 'request-update-dialog.component.html',
  styleUrls: ['./request-update-dialog.component.scss']
})

export class RequestUpdateDialog implements OnInit {
  formGroup: FormGroup;
  isSending = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { film: IFilmDto },
    private readonly dialogRef: MatDialogRef<RequestUpdateDialog>,
    private readonly fb: FormBuilder,
    private readonly contactUsService: ContactUsService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      body: ['', Validators.required],
      sendCopy: [false]
    });
  }

  onSubmitted(): void {
    if (!this.formGroup.valid) return;
    this.isSending = true;
    this.contactUsService.requestUpdate(this.formGroup.getRawValue(), this.data.film).pipe(finalize(() => this.isSending = false)).subscribe(
      () => this.alertMessage(this.translateService.instant('fspro.front.modal-request-update.message_sent'))
      , () => this.alertMessage(this.translateService.instant('Cannot sent the message. Please try again!')));

  }

  onClose(): void {
    this.dialogRef.close();
  }

  private alertMessage(message: string) {
    this.snackbar.open(
      message,
      'Close',
      { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
    );
    this.onClose();
  }
}
