import { Observable } from "rxjs";
import { IProvider } from "../interfaces/provider.interface";
import { IFilter } from "../entities/filter";
import { ISort } from "../entities/sort";
import { IFilmHydratedSearchDto, IFilmDto } from "../dtos/film-dto";
import { FilmService } from "../services/film.service";

interface IFilmProviderOptions {
  method?: string,
  body?: IFilmHydratedSearchDto
}

export class FilmProvider implements IProvider {

  constructor(private filmService: FilmService, private parameters: IFilter[], private sort: ISort | ISort[] = null, private search = null,
    private options: IFilmProviderOptions = {}) {
  }

  getEntities(startAt: number, limit: number): Observable<IFilmDto[]> {
    if(this.options?.method === 'post') {
      return this.filmService.getFilmsPost(startAt, limit, this.sort, this.search, this.parameters, this.options.body)
    }

    return this.filmService.getFilms(startAt, limit, this.sort, this.search, this.parameters);
  }
}
