<div class="fake-director-card">
  <div class="picture-container">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '115px', 'border-radius': 0, width: '123px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  </div>
  <div class="details-container">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '28px', 'border-radius': 0, width: '170px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '19px', 'border-radius': 0, width: '100px', 'margin-top': 0, 'margin-bottom': 0 }"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '18px', 'border-radius': 0, width: '170px', 'margin-top': '10px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '18px', 'border-radius': 0, width: '170px', 'margin-top': 0, 'margin-bottom': 0 }"></ngx-skeleton-loader>
  </div>
</div>
