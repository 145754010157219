import { Component, Inject, Input, OnInit } from '@angular/core';
import { IFilmDto } from '../../dtos/film-dto';
import { AuthenticationService } from 'src/app/authentications/authentication.service';
import { IFestivalDto } from '../../dtos/festival-dto';
import { IDirectorDto } from '../../dtos/director-dto';
import { ICollectionDto } from '../../dtos/collection-dto';
import { DOCUMENT } from '@angular/common';
import { ITalentDto } from "../../dtos/talent-dto";
import { Observable } from "rxjs";
import { AdminUrlService } from "../../services/admin-url.service";
import { tap } from "rxjs/operators";

@Component({
  selector: 'fspro-back-office-access',
  templateUrl: './back-office-access.component.html',
  styleUrls: ['./back-office-access.component.scss']
})
export class BackOfficeAccessComponent implements OnInit {

  public isAdmin = false;
  private rootUrl: string;
  public url: string;
  public url$: Observable<string>
  private _data: IFilmDto | IFestivalDto | IDirectorDto | ICollectionDto | ITalentDto = null;
  private _type: string = null;
  public buttons = [];
  fabButtons = [
    {
      icon: 'edit'
    }
  ];

  @Input() public get type(): string {
    return this._type
  };
  set type(value: string) {
    this._type = value;
    this.generateURL();
  }

  @Input()
  get data(): IFilmDto | IFestivalDto | IDirectorDto | ICollectionDto | ITalentDto {
    return this._data
  }
  set data(value: IFilmDto | IFestivalDto | IDirectorDto | ICollectionDto | ITalentDto) {
    this._data = value;
    this.generateURL();
  }

  constructor(
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document,
    private adminUrlService: AdminUrlService
  ) {
    this.rootUrl = this.document.location.origin;
  }

  ngOnInit(): void {
    this.isAdmin = this.authenticationService.isAdmin && this.authenticationService._impersonation$.value === null;
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  showItems() {
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.buttons = [];
  }

  generateURL() {
    if (this._data && this._type) {
      this.url$ = this.adminUrlService.getAdminUrl(this._type, this._data.id).pipe(
        tap(urlRes => {
          this.url = urlRes;
        })
      );
    }
  }

  redirect(btn) {
    switch (btn.icon) {
      case 'edit':
        if (this.url) {
          window.open(this.url, '_blank');
        }
        break;
      }
  }

}
