<div class="forgot-password-container">
  <form autocomplete="off" [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
    <div class="delimiter-with-content">
      <h1>{{ 'Forgotten password' | translate }}</h1>
    </div>

    <p class="description">
      {{'fspro.front.forgotten_password.form.instruction' | translate}}
    </p>

    <div class="input-wrapper">

      <div class="fspro-control-input-wrapper">
        <mat-form-field>
          <!-- <mat-label>{{ 'forgot-password-email-field' | translate}} <span class="asterisk">*</span></mat-label> -->
          <mat-label>{{ 'Email' | translate}} </mat-label>
          <input name="emailField" matInput autocomplete="off" autocapitalize="off" class="input-note" formControlName="emailField">
          <mat-error *ngIf="forgotForm.get('emailField').hasError('email')">
            Please enter a valid email!
          </mat-error>
        </mat-form-field>
      </div>

    </div>


    <div class="delimiter-with-content submit-wrapper">
      <div>

        <button class="btn-common-send" mat-flat-button color="primary" type="submit" [disabled]="isSending || forgotForm.invalid">
          <mat-spinner [color]="'primary'" [diameter]="20" *ngIf="isSending"
            style="display: inline-block; margin-right: 5px;">
          </mat-spinner>
          <span> {{ (isSending? 'fspro.front.forgotten_password.form.label.send' : 'fspro.front.forgotten_password.form.label.send' )| translate }}</span>
        </button>

      </div>
    </div>
  </form>
</div>
