import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { PageService, PageType } from './services/page.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  get pageType(): typeof PageType {
    return PageType;
  }
  type$!: Observable<PageType>;
  constructor(private readonly pageService: PageService) {
    this.type$ = this.pageService.type$;
  }
}
