import { Component, EventEmitter, Output } from "@angular/core";
import { FilterGroupComponent } from "src/app/shared/components/filter-group/filter-group.component";

@Component({
  selector: 'fspro-film-note-filter',
  templateUrl: './film-note-filter.component.html',
  styleUrls: ['./film-note-filter.component.scss']
})
export class FilmNoteFilterComponent extends FilterGroupComponent {
  @Output() reload: EventEmitter<void> = new EventEmitter<void>();

  /**
   * used to patch values into the filters without emit events
   * @param newValue newValue will patch into the filters
   */
  patchValue(newValue: { [fieldName: string]: unknown }): void {
    this.searchFilmForm.patchValue(newValue, { emitEvent: false });
  }

  /**
   * used it to re-initial filters and its values
   */
  onReload(): void {
    this.loadDataAsOriginal();
    this.reload.emit();
  }
}
