import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export const mustMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const passwordField = control.get('passwordField');
  const confirmPasswordField = control.get('confirmPasswordField');

  if (confirmPasswordField && confirmPasswordField.pristine) {
    return null;
  }

  const passwordMatch = passwordField && confirmPasswordField && passwordField.value === confirmPasswordField.value;

  if (passwordMatch) {
    return null;
  }

  confirmPasswordField.setErrors({ mustMatch : true });

  return null;
};
