import { Component, ElementRef, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";

@Component({
  selector: 'fspro-send-simple-message-dialog',
  templateUrl: 'send-simple-message-dialog.component.html',
  styleUrls: ['./send-simple-message-dialog.component.scss']
})

export class SendSimpleMessageDialog {

  public sendMailForm: FormGroup;
  public isSending = false;

  constructor(public dialogRef: MatDialogRef<SendSimpleMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ISimpleMessageProvider,
    private el: ElementRef,
    private snackbar: MatSnackBar,
  ) {
    if(data.enableSubject){
      this.sendMailForm = new FormGroup({
        messageField: new FormControl(null, Validators.required),
        subjectField: new FormControl(null, Validators.required),
        sendACopy: new FormControl(false)
      });
    }
    else{
      this.sendMailForm = new FormGroup({
        messageField: new FormControl(null, Validators.required),
        sendACopy: new FormControl(false)
      });
    }
    this.isSending = false;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.sendMailForm.markAllAsTouched();
    if (this.sendMailForm.valid) {
      this.isSending = true;
      this.data.sendMessageCallback({subject: this.sendMailForm.value.subjectField, message: this.sendMailForm.value.messageField, sendCopy : this.sendMailForm.value.sendACopy })
        .subscribe(()=> {
          this.alertMessage(this.data.fields.successMessage);
          this.dialogRef.close();
        }, (error) =>{
          this.alertMessage(error?.error?.message || 'Something went wrong!');
        });
    } else {
      this.autoFocusInValidField();
    }
  }

  private alertMessage(message: string) {
    this.snackbar.open(
      message,
      'Close',
      { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' }
    );
  }

  private autoFocusInValidField(): void {
    for (const key of Object.keys(this.sendMailForm.controls)) {
      if (this.sendMailForm.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        invalidControl.focus();
        break;
      }
    }
  }
}

export interface ISimpleMessage{
  subject: string,
  message : string,
  sendCopy : boolean
}

export interface ISimpleMessageProvider{
  enableSubject : boolean,
  sendMessageCallback : (message: ISimpleMessage) => Observable<Object>;
  fields : {
    subject: string;
    title: string;
    body: string;
    sendCopy: string;
    sendButton: string;
    successMessage: string;
    description?: string;
  }
}
