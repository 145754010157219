export enum SortDirection{
    None = 0,
    Desc,
    Asc,
}

export interface ISort{
    field: string;
    direction: SortDirection;
}
