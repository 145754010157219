<div class="header-container"  [ngClass]="{'has-rh-toolbar': displayRhToolbar && shouldDisplayRHNavBar}">
  <header class="rhtoolbar" *ngIf="displayRhToolbar && shouldDisplayRHNavBar">
    <ul class="rhtoolbar__actions">
      <li class="rhtoolbar__actionsitem" [class.rhtoolbar__actionsitem--open]="isToggleToolbar">
        <a routerLink="/rh/my-films" class="rhtoolbar__action rhtoolbar__action--myfilms">{{'fspro.front.rhlu.toolbar.my_films' | translate }}</a>
      </li>
      <li class="rhtoolbar__actionsitem rhtoolbar__actionsitem--highlight" [class.rhtoolbar__actionsitem--open]="isToggleToolbar">
        <a routerLink="/rh/invitation/autoselect/form" class="rhtoolbar__action rhtoolbar__action--invite rhtoolbar__action--highlight">{{'fspro.front.rhlu.toolbar.invite' | translate }}</a>
      </li>
      <li class="rhtoolbar__actionsitem" [class.rhtoolbar__actionsitem--open]="isToggleToolbar">
        <a routerLink="/rh/screening-requests" class="rhtoolbar__action rhtoolbar__action--requests">
          {{'fspro.front.rhlu.toolbar.all_request' | translate }}
          <span *ngIf="notificationsFacade.pendingScreeningRequest$ | async as pendingScreeningRequest" class="rhtoolbar__notif">{{ pendingScreeningRequest }}</span>
        </a>
      </li>
      <li class="rhtoolbar__actionsitem rhtoolbar__actionsitem--mobileonly">
        <a href="javascript:void(0)" (click)="isToggleToolbar = !isToggleToolbar" [class.rhtoolbar__action--reveal--open]="isToggleToolbar" class="rhtoolbar__action rhtoolbar__action--reveal">{{'fspro.front.rhlu.toolbar.reveal_a' | translate }}</a>
      </li>
      <li class="rhtoolbar__actionsitem" [class.rhtoolbar__actionsitem--open]="isToggleToolbar">
        <a routerLink="/rh/invitations/sent" class="rhtoolbar__action rhtoolbar__action--invites">{{'fspro.front.rhlu.toolbar.sent_invites' | translate }}</a>
      </li>
      <li class="rhtoolbar__actionsitem" [class.rhtoolbar__actionsitem--open]="isToggleToolbar">
        <a routerLink="/rh/screening-reports" class="rhtoolbar__action rhtoolbar__action--reports">{{'fspro.front.rhlu.toolbar.all_reports' | translate }}</a>
      </li>
      <li class="rhtoolbar__actionsitem" [class.rhtoolbar__actionsitem--open]="isToggleToolbar">
        <a routerLink="/rh/booking-requests" class="rhtoolbar__action rhtoolbar__action--bookingrequests">
          {{'fspro.front.rhlu.toolbar.booking_request' | translate }}
          <span  class="rhtoolbar__notif" *ngIf="notificationsFacade.pendingBookingRequestRequest$ | async as noOfBooking"> {{ noOfBooking }}</span>
        </a>
      </li>
    </ul>
  </header>
  <!-- shows only when on mobile -->
  <header class="logoheader">
    <div class="mobile-pageheader__logo">
      <a routerLink="/"><img src="/assets/mat-icon/logo-festival-scope-pro.svg" alt="Festival Scope Pro " class="pageheader__logoimage"></a>
    </div>
  </header>
</div>

<header class="pageheader" [ngClass]="{'has-rh-toolbar': displayRhToolbar && shouldDisplayRHNavBar}">
  <div class="pageheader__top">
    <a href="javascript:void(0)" class="pageheader__mainitem pageheader__navigtrigger" (click)="navbar.toggle()">{{'fspro.front.header.top.label.menu' | translate}} </a>
    <a class="pageheader__search pageheader__mainitem"
            (click)="searchWidget.isToggled ? searchWidget.dismiss() : searchWidget.open(); loginWidget.isToggled ? loginWidget.dismiss() : null"
    >{{'fspro.front.header.top.label.search' | translate}}</a>
    <div class="pageheader__logo">
      <a routerLink="/"><img src="/assets/mat-icon/logo-festival-scope-pro.svg" alt="Festival Scope Pro " class="pageheader__logoimage"></a>
    </div>
    <div data-controller="openbubble" class="pageheader__rubixbox no_clone">
      <a href="javascript:void(0)" #rubixToggle (click)="rubixBox.toggle()" class="pageheader__mainitem pageheader__contact">Info</a>
      <fspro-bubble #rubixBox type="rubix" [classes]="['rubixbox', 'posname-rubiks']">
        <button class="pageheader__widgetsclose pageheader__desktop-and-tablet-hide" (click)="rubixBox.close()">{{'fspro.front.header.nav.label.close' | translate}}</button>
        <ul class="rubixbox__menulist">
          <ng-container *ngIf="isAuthenticated">
            <li class="rubixbox__menulist__item">
              <a class="rubixbox__menulist__button rubixbox__menulist__button--invitations" routerLink="/my-invitations/list">{{'fspro.front.header.rubixbox.label.my_invitations' | translate}}</a>
            </li>
            <li class="rubixbox__menulist__item">
              <a class="rubixbox__menulist__button rubixbox__menulist__button--myfeed" routerLink="/my-feed">{{'fspro.front.header.rubixbox.label.my_feeds' | translate}}</a>
            </li>
            <li class="rubixbox__menulist__item">
              <a class="rubixbox__menulist__button rubixbox__menulist__button--watched" routerLink="/my-lists/watched">{{'fspro.front.header.rubixbox.label.watched' | translate}}</a>
            </li>
            <li class="rubixbox__menulist__item">
              <a class="rubixbox__menulist__button rubixbox__menulist__button--later" routerLink="/my-lists/to-watch">{{'fspro.front.header.rubixbox.label.watch_later' | translate}}</a>
            </li>
            <li class="rubixbox__menulist__item">
              <a class="rubixbox__menulist__button rubixbox__menulist__button--notes" routerLink="/my-notes/list">{{'fspro.front.header.rubixbox.label.my_notes' | translate }}</a>
            </li>
          </ng-container>
          <li class="rubixbox__menulist__item">
            <a class="rubixbox__menulist__button rubixbox__menulist__button--newsletter" routerLink="/newsletter/form">{{'fspro.front.header.rubixbox.label.newsletter' | translate}}</a>
          </li>
          <li class="rubixbox__menulist__item" *ngIf="canAccessBookingFeature">
            <a class="rubixbox__menulist__button rubixbox__menulist__button--mybookings" routerLink="/my-bookings">{{'fspro.front.header.rubixbox.label.my_booking' | translate}}</a>
          </li>
          <li class="rubixbox__menulist__item">
            <a class="rubixbox__menulist__button rubixbox__menulist__button--contact" style="cursor: pointer;"
            (click)="toggleContactEmitter.emit(true)">{{'fspro.front.header.rubixbox.label.contact' | translate}}</a>
          </li>

        </ul>
      </fspro-bubble>
    </div>
    <a href="javascript:void(0)"
       *ngIf="!isAuthenticated; else myAccount"
       class="pageheader__mainitem pageheader__userprofile"
       (click)="loginWidget.isToggled ? loginWidget.dismiss() : loginWidget.open();searchWidget.isToggled ? searchWidget.dismiss() : null"
    >{{'fspro.front.header.top.label.login'| translate}}</a>
    <ng-template #myAccount>
      <div class="pageheader__myaccount pageheader__myaccount--loggedin no_clone">
        <a href="javascript:void(0)" #myAccountBoxToggle (click)="myAccountBox.toggle()" class="pageheader__mainitem pageheader__userprofile  pageheader__userprofile--loggedin">{{'fspro.front.header.top.label.my_account' | translate}}</a>
        <fspro-bubble #myAccountBox type="section" [classes]="['myaccountbox', 'posname-user-info']">
          <h1 class="myaccountbox__username" [innerHTML]="user?.fullname"></h1>
          <p class="myaccountbox__registeredmail">{{ (authentication._impersonation$ | async) ? 'Impersonating ' + (authentication._impersonation$ | async) : user?.username}}</p>
          <p><a class="myaccountbox__myinfolink" routerLink="/my-account/my-infos">{{'fspro.front.header.nav.label.my_info' | translate }}</a></p>
          <p><a class="myaccountbox__myinfolink" *ngIf="stripePortalUrl !== null" href="{{ stripePortalUrl }}">{{'fspro.front.header.nav.label.my_invoicing' | translate}}</a></p>
          <div class="myaccountbox__extras">
            <a class="myaccountbox__signout" *ngIf="authentication._impersonation$ | async" href="javascript:void(0)" (click)="authentication.exitImpersonation()">Exit impersonation</a>
            <a class="myaccountbox__signout" *ngIf="!(authentication._impersonation$ | async)" href="javascript:void(0)" (click)="onSignOut()">{{'fspro.front.header.nav.label.sign_out' | translate}}</a>
          </div>
        </fspro-bubble>
      </div>
    </ng-template>
    <a *ngIf="shouldRenewOrUpgrade" routerLink="/subscribe" class="pageheader__upgrade-item pageheader__mobile-hide">
      <div class="pageheader__upgrade-button pageheader__upgrade-button--home ">
        <span>{{ (shouldRenewOrUpgrade === 'renew' ? 'fspro.front.renew_action' : 'fspro.front.upgrade_action')| translate }}</span>
      </div>
    </a>
  </div>

  <fspro-navbar #navbar [items]="navLinks" [isLoggedIn]="isAuthenticated"></fspro-navbar>

  <fspro-header-widget (isToggledChange)="onToggledLoginChanged($event)" #loginWidget [topSpacing]="145">
    <div class="pageheader__account">
      <form class="pageheader__form" [formGroup]="loginForm" (ngSubmit)="onLogin()" *ngIf="!isVerifyingCode && !mustVerifyCode">
        <input class="pageheader__forminput" autocomplete="off" type="email" formControlName="username" name="username" placeholder="E-mail">
        <input class="pageheader__forminput" autocomplete="off" type="password" formControlName="password" name="password" placeholder="Password ">
        <input name="remember_me" type="hidden" formControlName="remember_me" value="1">
        <button class="pageheader__formbutton pageheader__formbutton--home   pageheader__formbutton--centermobile" mat-flat-button color="primary" type="submit" [disabled]="isLogging">
          <mat-spinner [color]="'primary'" [diameter]="20" *ngIf="isLogging"
            style="display: inline-block; margin-right: 5px;">
          </mat-spinner>
          <span> {{ 'fspro.front.header.login.label.ok' | translate }}</span>
        </button>
      </form>
      <form class="pageheader__form pageheader__verification" *ngIf="mustVerifyCode" [formGroup]="verificationCodeForm" (ngSubmit)="verifyCode()">
        <p class="pageheader__form__text">{{ 'fspro.front.header.login.verification-display' | translate }}</p>
        <input autocomplete="off" class="pageheader__forminput" type="text" digitString [maxLength]="6" name="verificationCode" placeholder="Verification Code" formControlName="verificationCode">
        <button class="pageheader__formbutton pageheader__formbutton--home pageheader__formbutton--centermobile" mat-flat-button color="primary" [disabled]="isVerifyingCode">
          <mat-spinner [color]="'primary'" [diameter]="20" *ngIf="isVerifyingCode"
                       style="display: inline-block; margin-right: 5px;">
          </mat-spinner>
          <span> {{ 'fspro.front.header.login.label.verify' | translate }}</span>
        </button>
        <button class="pageheader__formbutton pageheader__formbutton--home pageheader__formbutton--centermobile pageheader__resend_button" *ngIf="couldResendCode" type="button" (click)="resendCode()" mat-flat-button color="primary" [disabled]="isResendingCode">
          <mat-spinner [color]="'primary'" [diameter]="20" *ngIf="isResendingCode"
                       style="display: inline-block; margin-right: 5px;">
          </mat-spinner>
          <span> {{ 'fspro.front.header.login.label.resend' | translate }}</span>
        </button>
      </form>
      <p class="pageheader__acccountforgotline" *ngIf="!isVerifyingCode && !mustVerifyCode">
        <a class="pageheader__accountforgot pageheader__accountforgot--home " (click)="loginWidget.dismiss()" routerLink="/forgotten-password">{{'fspro.front.header.login.label.forget_password' | translate}}</a>
      </p>
      <div *ngIf="isLoginFailed" class="pageheader__errormessage pageheader__errormessage--login">
        <span class="pageheader__errormessage--text" [innerHTML]="loginErrorMessage"></span>
      </div>
      <p class="pageheader__widgetsseparator"><span class="pageheader__widgetsseparatortext">{{'fspro.front.header.login.label.or' | translate}} </span></p>
      <a routerLink="/sign-up" class="pageheader__formbutton pageheader__formbutton--home  pageheader__formbutton--centermobile pageheader__formbutton--join">{{'fspro.front.header.nav.label.join'| translate}}
      </a>
    </div>
  </fspro-header-widget>

  <fspro-header-widget #searchWidget [topSpacing]="145">
    <search-popup *ngIf="searchWidget.isToggled" (onSearchChanged)="searchWidget.dismiss()"></search-popup>
  </fspro-header-widget>

</header>
