import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import { ICinematographerDto } from "../../dtos/cinematographer-dto";

@Component({
  selector: 'fspro-director-card',
  templateUrl: './cinematographer-card.component.html',
  styleUrls: ['./cinematographer-card.component.scss']
})
export class CinematographerCard implements AfterViewInit {

  @Input() public cinematographer: ICinematographerDto;
  @Input() public theme: 'DarkMode' | 'LightMode' = 'LightMode';
  @Input() public showType: boolean = false;

  @ViewChild('picture') pictureEl: ElementRef;
  @ViewChild('filmsEl') filmsEl: ElementRef;
  @ViewChild('nameEl') nameEl: ElementRef;
  @ViewChild('countriesEl') countriesEl: ElementRef;

  isMoreEnable = false;

  constructor(private ref: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    const picHeight = 118; // Fix height of the pic

    let detailsEl = this.computeElementHeight();
    let childCount = this.filmsEl?.nativeElement?.childElementCount;

    while (detailsEl > picHeight && childCount > 1) {
      const lastElement = this.filmsEl.nativeElement.lastElementChild;
      lastElement.remove();
      detailsEl = this.computeElementHeight();
      childCount--;
      this.isMoreEnable = true;
    }
    this.ref.detectChanges();
  }

  computeElementHeight():number {
    return this.nameEl?.nativeElement?.clientHeight
      +  this.countriesEl?.nativeElement?.clientHeight
      + this.filmsEl?.nativeElement?.clientHeight;
  }
}
