import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[digitString]'
})
export class digitStringDirective {
  @Input() maxLength: number = 6;

  constructor(private element: ElementRef) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    value = value.replace(/\D/g, '');
    if (value.length > this.maxLength) {
      value = value.slice(0, this.maxLength);
    }
    this.element.nativeElement.value = value;
  }
}
