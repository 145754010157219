<button mat-icon-button disableRipple class="close-btn" (click)="onClose()"></button>

<form class="form-wrapper" [formGroup]="sendMailForm" (ngSubmit)="onSubmit()">
  <header class="section-header">
    <h1>{{ 'fspro.front.film.filmproduction.form.title' | translate }}</h1>
  </header>

    <div class="field-wrapper">
      <div class="fspro-control-input-wrapper">
        <mat-form-field>
          <mat-label>{{ (isCompanyContact? 'fspro.front.film.filmproduction.label.company_name' : 'fspro.front.film.director.label.director') | translate }}</mat-label>
          <input name="receiver" matInput formControlName="receiver" autocomplete="off">
        </mat-form-field>
      </div>

      <div class="fspro-control-input-wrapper">
        <mat-form-field>
          <mat-label>{{ 'fspro.front.film.filmproduction.label.subject' | translate }}</mat-label>
          <input name="subjectField" matInput formControlName="subjectField" autocomplete="off">
        </mat-form-field>
      </div>

      <div class="fspro-control-input-wrapper">
        <mat-form-field>
          <mat-label>{{ 'fspro.front.film.filmproduction.label.message' | translate }}</mat-label>
          <textarea
            name="messageField"
            matInput
            formControlName="messageField"
            autocomplete="off"
            autocapitalize="off"
            cdkTextareaAutosize
            class="input-note"
          ></textarea>
        </mat-form-field>
      </div>
    </div>


    <div class="action-button">
      <div class="fspro-control-checkbox-wrapper">
        <mat-checkbox formControlName="sendACopy">{{ 'fspro.front.film.filmproduction.button.send_copy' | translate }}</mat-checkbox>
      </div>

      <div class="btn-wrapper">
        <button class="btn-cancel" type="button" (click)="onClose()">Back</button>

        <button class="btn-send" type="submit" [disabled]="isSending">{{ 'fspro.front.film.filmproduction.button.send' | translate }}</button>
      </div>
    </div>

</form>
