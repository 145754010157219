
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { parameters } from 'src/app/shared/parameters';
import { IBillingAddressDto } from '../dtos/billing-address-dto';
import { ICheckoutSessionDto } from '../dtos/checkout-session-dto';
import { IMembershipTrackingDto } from '../dtos/membership-tracking-dto';
import { IPriceDto } from '../dtos/price-dto';
import { ISetupIntentCheckoutDto } from '../dtos/setup-intent-checkout-dto';
import { IStripeCustomerDto } from '../dtos/stripe-customer-dto';
import { IStripeSubscriptionDto } from '../dtos/stripes-subscription-dto';

@Injectable()
export class FsproStripeService {

  private checkoutUrlBase = parameters.baseServiceUrl + 'rest/stripe/setup-intent/checkout';
  private stripeUrlBase = parameters.baseServiceUrl + 'rest/stripe';
  private billingAddressUrlBase = parameters.baseServiceUrl + 'rest/stripe/billing-address';

  constructor(private http: HttpClient) { }

  /**
   * Returns a session Id to use with the stripe API to redirect the user to the setup intent checkout page
   *
   * @param setupIntentCheckoutDto
   */
  checkoutSetupIntent(setupIntentCheckoutDto: ISetupIntentCheckoutDto) {
    return this.http.post<ICheckoutSessionDto>(this.checkoutUrlBase, setupIntentCheckoutDto);
  }

  /**
   * Return the list of avilable prices in the system
   */
  listPrices() {
    const url = `${this.stripeUrlBase}/prices`;
    return this.http.get<IPriceDto[]>(url);
  }
  /**
   * Inform to the BE the setup has been completed
   */
  setupSuccess(session_id: string) {
    const url = `${this.stripeUrlBase}/setup-success/${session_id || ''}`;
    return this.http.post<unknown>(url, null);
  }
  /**
   * Inform to the BE the renewal
   */
  renewal(session_id: string) {
    const url = `${this.stripeUrlBase}/setup-success/${session_id || '' }`;
    return this.http.post<unknown>(url, null);
  }

  /**
   * Returns information about the given stripe customer
   *
   * @param stripeCustomerId
   */
  getCustomer(stripeCustomerId: string) {
    const url = `${this.stripeUrlBase}/customers/${stripeCustomerId}`;
    return this.http.get<IStripeCustomerDto>(url);
  }

  /**
   *
   * @returns IBillingAddressDto
   */
  getBillingAddress(): Observable<IBillingAddressDto> {
    return this.http.get<IBillingAddressDto>(this.billingAddressUrlBase);
  }

  getPortalAddress(): Observable<any> {
    const port = window.location.port !== '' ? ':' + window.location.port : '';
    const currentUrl = window.location.protocol + '//' + window.location.hostname + port;
    const url = this.stripeUrlBase + '/portal-url?frontUrl=' + encodeURIComponent(currentUrl);

    return this.http.get<any>(url);
  }

  getSessionDetails(sessionId: string): Observable<any> {
    const url = this.stripeUrlBase + '/session-details/' + sessionId;
    return this.http.get<any>(url);
  }

  getPrice(priceId: string): Observable<any> {
    const url = this.stripeUrlBase + '/price-details/' + priceId;
    return this.http.get<any>(url);
  }
}
