<div class="fab-container" *ngIf="isAdmin">
    <div class="bottom-left" *ngIf="url$ | async">
        <button mat-fab class="mat-fab-button" (click)="onToggleFab()">
            <mat-icon>settings</mat-icon>
        </button>
    </div>
    <div>
        <button *ngFor="let btn of buttons" mat-mini-fab (click)="redirect(btn)">
            <mat-icon>{{btn.icon}}</mat-icon>
        </button>
    </div>
</div>
