<div class="search-form">
  <div class="search-form-wrapper">
    <!-- <button class="pageheader__widgetsclose" data-action="click->toggleblock#startsearch">Close </button> -->
    <div>
      <div class="search-input-area">
        <input
          name="searchText"
          id="quick-search-input"
          AutoFocus
          (keyup.enter)="goToSearchPage()"
          type="text"
          [(ngModel)]="searchText"
          (ngModelChange)="searchTextChanged()"
          placeholder="Search"
          autocomplete="off"
          class="search-input"
        />
        <div class="search-button-area">
          <select class="search-dropdown" [(ngModel)]="focusedSection" (ngModelChange)="doSearch()">
            <option *ngFor="let item of focusedSections" [value]="item.type">{{ item.name | translate }}</option>
          </select>
          <div class="ok-button-wrap" (click)="goToSearchPage()">
            <input name="okButton" class="ok-button" type="button" value="OK">
          </div>
        </div>
      </div>
    </div>
    <div class="search-result">
      <fspro-popup-search-result-section [searchResultItems]="filmResults | async"
        [sectionTitle]="'fspro.front.search.title.films' | translate "
        [nonGeneric]="true">
      </fspro-popup-search-result-section>
      <fspro-popup-search-result-section [searchResultItems]="directorResults | async"
        [sectionTitle]="'fspro.front.search.title.directors' | translate" >
      </fspro-popup-search-result-section>
      <fspro-popup-search-result-section [searchResultItems]="festivalResults | async"
        [sectionTitle]="'fspro.front.search.title.festivals' | translate" >
      </fspro-popup-search-result-section>
      <fspro-popup-search-result-section [searchResultItems]="labelResults | async"
        [sectionTitle]="'fspro.front.search.title.labels' | translate" >
      </fspro-popup-search-result-section>
      <fspro-popup-search-result-section [searchResultItems]="lineUpResults | async"
        [sectionTitle]="'fspro.front.search.title.line_ups' | translate" >
      </fspro-popup-search-result-section>
      <fspro-popup-search-result-section [searchResultItems]="commissionResults | async"
        [sectionTitle]="'fspro.front.search.title.commission' | translate" >
      </fspro-popup-search-result-section>
    </div>
  </div>
</div>
