import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil, tap } from "rxjs/operators";
import { SearchResultItem, SearchResultSection, SectionType } from "src/app/interfaces/search-result.interface";
import { SearchResultProvider } from "src/app/services/search-result-provider.service";

@Component({
  selector: 'search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss'],
  providers: [SearchResultProvider]
})
export class SearchPopupComponent implements OnInit {

  searchText = "";
  focusedSection: SectionType = "All";

  private _enableLineupSearch = false
  @Input('enableLineupSearch')
  get enableLineupSearch(){
    return this._enableLineupSearch;
  }
  set enableLineupSearch(value: boolean){
    this._enableLineupSearch = "" + value !== "false";
  }

  @Output() onSearchChanged = new EventEmitter<void>();

  private readonly _detroy$ = new Subject();
  filmResults = new BehaviorSubject<SearchResultItem[]>([]);
  lineUpResults = new BehaviorSubject<SearchResultItem[]>([]);
  festivalResults = new BehaviorSubject<SearchResultItem[]>([]);
  labelResults = new BehaviorSubject<SearchResultItem[]>([]);
  directorResults = new BehaviorSubject<SearchResultItem[]>([]);
  commissionResults = new BehaviorSubject<SearchResultItem[]>([]);
  searchTextSubject = new BehaviorSubject<string>(null);

  public focusedSections: { type: SectionType, name: string }[] = [
    {
      type: 'All',
      name: 'All'
    },
    {
      type: 'Film',
      name: 'fspro.front.search.title.films'
    },
    {
      type: 'Director',
      name: 'fspro.front.search.title.directors'
    },
    {
      type: 'Festival',
      name: 'fspro.front.search.title.festivals'
    },
    {
      type: 'Label',
      name: 'fspro.front.search.title.labels'
    },
    {
      type: 'Lineup',
      name: 'fspro.front.search.title.line_ups'
    },
    {
      type: 'Commission',
      name: 'fspro.front.search.title.commissions'
    }
  ];
  constructor(
    private readonly searchResultProvider: SearchResultProvider,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this._detroy$),
      tap(({ searchValue, type }) => {
        this.searchText = searchValue || '';
        this.focusedSection = this.toSectionType(type);
        if(this.searchText) {
          this.doSearch();
        }
      })
    ).subscribe();
  }

  private toSectionType(sequence: string): SectionType {
    if (!sequence) {
      return 'All';
    }
    return `${sequence.charAt(0).toUpperCase() }${sequence.slice(1)}` as SectionType;
  }

  setCommittedSearchStatus() {
    this.festivalResults.next([]);
    this.labelResults.next([]);
    this.lineUpResults.next([]);
    this.filmResults.next([]);
    this.directorResults.next([]);
    this.commissionResults.next([]);
  }

  ngOnInit(): void {
    if(!this.enableLineupSearch)
    {
      this.focusedSections.splice(this.focusedSections.findIndex(i => i.type == "Lineup"), 1);
    }
    this.searchResultProvider.searchResult.subscribe(data => {
      this.handleSearchResult(data);
    });
    this.searchTextSubject.pipe(debounceTime(2000), distinctUntilChanged()).subscribe(() => this.doSearch());
  }

  doSearch(): void {
    if(this.searchText.length >= 3)
    {
      this.setCommittedSearchStatus();
      this.searchResultProvider.search(this.searchText, this.focusedSection, 0, this.enableLineupSearch);
    }
  }

  goToSearchPage() {
    // window.location.href = `/search?searchValue=${this.searchText}&type=${this.focusedSection?.toString()?.toLocaleLowerCase()}`;
    this.router.navigate(['search'], {
      queryParams: {
        searchValue: this.searchText || '',
        type: this.focusedSection || 'All'
      }
    });
    this.onSearchChanged.emit();
  }

  private handleSearchResult(allResults: SearchResultSection[]) {
    if (allResults) {
      this.festivalResults.next(allResults.find(x => x.type === 'Festival')?.searchResultItems);
      this.labelResults.next(allResults.find(x => x.type === 'Label')?.searchResultItems);
      this.lineUpResults.next(allResults.find(x => x.type === 'Lineup')?.searchResultItems);
      this.filmResults.next(allResults.find(x => x.type === 'Film')?.searchResultItems);
      this.directorResults.next(allResults.find(x => x.type === 'Director')?.searchResultItems);
      this.commissionResults.next(allResults.find(x => x.type === 'Commission')?.searchResultItems);
    }
  }

  searchTextChanged() {
    this.searchTextSubject.next(this.searchText);
  }
}
