import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { ContactUsService } from "../../services/contact-us.service";
import { UserService } from "../../services/user.service";
import { IUserNew } from "../../entities/user.new";

@Component({
  selector: 'fspro-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() isToggled: boolean;
  @Output() isToggledChange = new EventEmitter();

  public sendMailForm: FormGroup;
  public isSending = false;
  public user: IUserNew = null;

  constructor(
    private readonly contactUs: ContactUsService,
    private readonly formBuilder: FormBuilder,
    private readonly snackbar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly userService: UserService
  ) {
  }

  ngOnInit(): void {
      this.userService.getCurrentUserDetails().toPromise().then((user) => {
        this.user = user;

        const mustMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
          const emailField = control.get('email');
          const confirmEmailField = control.get('confirmEmail');
          if (confirmEmailField && confirmEmailField.pristine) {
            return null;
          }

          const isMatch = emailField && confirmEmailField && emailField.value === confirmEmailField.value;
          if (isMatch) {
            if (this.sendMailForm.hasError('mustMatch')) {
              this.sendMailForm.updateValueAndValidity();
            }
            return null;
          }
          confirmEmailField.setErrors({ mustMatch: true });

          return { mustMatch: true };
        };

        this.sendMailForm = this.formBuilder.group({
          firstName: [this.user ? this.user?.firstName : ''],
          lastName: [this.user ? this.user?.lastName : ''],
          email: [this.user ? this.user?.email : '', [Validators.required, Validators.email]],
          confirmEmail: [this.user ? this.user?.email : '', [Validators.required, Validators.email]],
          body: ['', [Validators.required]],
          isSendACopy: [false]
        }, { validators: mustMatchValidator });
      });
  }

  onSubmit() {
    if(this.sendMailForm.invalid) {
      return;
    }

    this.contactUs.sendFeedback(this.sendMailForm.value).pipe(
      tap(() => {
        const message = this.translateService.instant('fspro.front.contact.us.submit.success');
        this.snackbar.open(message, 'Close', { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' });
      }),
      catchError(() => {
        const message = this.translateService.instant('fspro.front.contact.us.submit.failed');
        this.snackbar.open(message, 'Close', { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'info-snackbar' });
        return of();
      }),
      finalize(() => {
        this.dismiss();
      })
    ).subscribe();
  }

  hasValue(fieldName: string): boolean {
    return (this.sendMailForm.get(fieldName)?.value || '').length > 0;
  }

  open(): void {
    this.isToggled = true;
    this.isToggledChange.emit(true);
  }

  dismiss(): void {
    this.isToggled = false;
    this.isToggledChange.emit(false);
  }
}
