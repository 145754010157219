<div class="extra-wrapper">
  <header class="extra-header">
    <h1 class="title">
      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="37" fill-rule="evenodd" stroke-linejoin="round"
        stroke-miterlimit="1.4" clip-rule="evenodd" viewBox="0 0 59 60">
        <g fill-rule="nonzero" fill="#fff">
          <path d="M51 45.5H8.1V7.8h42.7v37.7zM.3 1v58.4h58.3V.9H.4z"></path>
          <path
            d="M32.7 23.9L28.1 30l-3.5-3.8-8.7 9.5h27l-10.2-12zm-16.8-3.5c0-1.3 1.2-2.3 2.7-2.3 1.5 0 2.7 1 2.7 2.3 0 1.4-1.2 2.4-2.7 2.4-1.5 0-2.7-1-2.7-2.4z">
          </path>
        </g>
      </svg>
    </h1>

    <button mat-icon-button disableRipple class="close-btn" (click)="onClose()"></button>
  </header>

  <div class="photo-gallery-wrapper">
    <fspro-photo-slider
      [activeItem]="activeItem"
      [photos]="galleryItems"
      (onChangeScrollBar)="onChangeScrollBar($event)"
      (onChangeSource)="onChangeSource($event)"
    ></fspro-photo-slider>
  </div>
</div>
