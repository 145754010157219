<div class="grid film-main-wrapper">
  <form [formGroup]="searchFilmForm">
    <div class="fspro-mat-grid-list-wrapper">
      <mat-grid-list cols="12" rowHeight="45" gutterSize="10">
        <mat-grid-tile *ngFor="let field of listFields; let i = index" [colspan]="6" [responsiveColSpan]="{ xs: 6, sm: 6, md: 4, lg: 3, xl: 3 }">
          <div class="fspro-control-dropdown-wrapper">
            <mat-form-field appearance="outline">
              <mat-select [placeholder]="field.placeholder | translate" [formControlName]="field.fieldName"
              [multiple]="field.multiple"
              [compareWith]="compareSelectValues">
                <mat-select-trigger *ngIf="field.multiple">
                  {{getFormField(field.fieldName).value ?((getFormField(field.fieldName).value[0]?.name) | translate ): ''}}

                  <span *ngIf="getFormField(field.fieldName).value?.length > 1">
                    (+{{getFormField(field.fieldName).value.length - 1}} {{getFormField(field.fieldName).value?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>

                <mat-option *ngFor="let opt of field.data$ | async; let j = index" [value]="opt">
                  <span class="option-text">{{opt?.name | translate}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </form>
</div>

<div class="grid film-main-wrapper search-filter-display" [ngClass]="{'has-value': isThereAnyMultiValuesInFilter, 'no-value': !isThereAnyMultiValuesInFilter}">
  <!-- <ng-container *ngFor="let singleField of getListFieldType('single')">
    <button mat-icon-button disableRipple *ngIf="getFormField(singleField.fieldName).value"
      (click)="onRemoveItemInFilter(getFormField(singleField.fieldName).value, singleField.fieldName)">
      <span class="filter-text">{{(getFormField(singleField.fieldName).value?.name) | translate}}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40"><g stroke="black" fill="transparent" stroke-linejoin="bevel" stroke-width="3"><ellipse cx="19" cy="19" rx="16" ry="16" /><path d="M24.3 13.7l-11 11M13.8 13.8l11 11" /></g></svg>
    </button>
  </ng-container> -->


  <ng-container *ngFor="let multipleField of getListFieldType('multiple')">
    <button mat-icon-button disableRipple *ngFor="let value of getFormField(multipleField.fieldName).value"
      (click)="onRemoveItemInFilter(value, multipleField.fieldName)">
      <span class="filter-text">{{value?.name  | translate}}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40"><g stroke="black" fill="transparent" stroke-linejoin="bevel" stroke-width="3"><ellipse cx="19" cy="19" rx="16" ry="16" /><path d="M24.3 13.7l-11 11M13.8 13.8l11 11" /></g></svg>
  </button>
  </ng-container>
</div>
