import { Component, EventEmitter, Input, Output, TemplateRef, Optional, ElementRef } from "@angular/core";

@Component({
  selector: 'fspro-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss']
})
export class BubbleComponent {
  @Input() isToggled: boolean;
  @Input() type: 'rubix' | 'section';
  @Input() isPopup: boolean = true;
  @Input() set classes(value: string[]) {
    this._additionalClass = value || [];
    this._additionalClassOpened = [...this._additionalClass, ...this._additionalClass.map(_ => `${_}--open`)];
  };
  @Input() data: unknown;

  private _additionalClass: string[] = [];
  private _additionalClassOpened: string[] = [];

  get additionalClass(): string[] {
    return this.isToggled ? this._additionalClassOpened : this._additionalClass;
  }

  @Output() isToggledChange = new EventEmitter<boolean>();

  constructor(
    @Optional() protected readonly templateRef: TemplateRef<unknown>,
    private readonly elementRef: ElementRef
  ) {}

  open(): void {
    this.isToggled = true;
    this.isToggledChange.emit(this.isToggled);
  }

  toggle(): void {
    this.isToggled = !this.isToggled;
    this.isToggledChange.emit(this.isToggled);
  }

  close(): void {
    this.isToggled = false;
    this.isToggledChange.emit(this.isToggled);
  }

}
