import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {forkJoin, Observable, Subject, Subscription} from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { NavbarItem } from "src/app/interfaces/navbar-item.interface";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: 'fspro-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {
  private readonly _destroy$ = new Subject();
  @Input() isToggled: boolean;
  @Output() isToggledChange = new EventEmitter();
  @Input() isLoggedIn = false;

  @Input() items: NavbarItem[] = [];

  activatedItem$: Observable<NavbarItem | null>;
  public shouldDisplayNavBar: boolean = false;
  private subscription : Subscription;

  constructor(private readonly route: Router, private userService: UserService) {
    forkJoin([this.userService.shouldVerify(), this.userService.mustChangePassword()])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([cacheVerifyData, cacheMustChangePasswordData]) => {
          this.shouldDisplayNavBar = !cacheVerifyData && !cacheMustChangePasswordData;
        }
      );
    this.activatedItem$ = this.route.events.pipe(
      takeUntil(this._destroy$),
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let { url } = this.route;
        return (this.items || []).find(({ route, activates }) => route === url || (activates || []).some(_ => this.isMatched(_, url)));
      })
    );
  }

  private isMatched(path: string, url: string): boolean {
    return new RegExp((path || '').replace(/(:[a-zA-Z]+)/gi, '[\\w-]+')).test(url || '');
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggle(): void {
    this.isToggled = !this.isToggled;
    this.isToggledChange.emit(this.isToggled);
  }

  open(): void {
    this.isToggled = true;
    this.isToggledChange.emit(true);
  }

  dismiss(): void {
    this.isToggled = false;
    this.isToggledChange.emit(false);
  }

  itemClicked(): void {
    this.toggle();
  }
}
