import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentications/authentication.service';

@Component({
  selector: 'logout',
  template: '',
})
export class LogoutComponent implements OnInit {

  constructor(private readonly authen: AuthenticationService, private readonly route: Router) { }

  ngOnInit() {
    this.authen.signOut();
    window.location.reload();
  }

}
