<!-- <div class="fake-film-card">
  <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '192px', 'border-radius': 0, width: '318px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '31px', 'border-radius': 0, width: '300px', 'margin-top': '5px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '1px', 'border-radius': 0, width: '318px', 'margin-top': '5px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '18px', 'border-radius': 0, width: '300px', 'margin-top': '10px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '18px', 'border-radius': 0, width: '300px', 'margin-top': '5px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '1px', 'border-radius': 0, width: '318px', 'margin-top': '5px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '30px', width: '73px'}" ></ngx-skeleton-loader>
</div> -->

<div class="fake-film-card" [ngClass]="{'dark': theme === 'DarkMode'}">
  <div class="image">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '100%', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  </div>

  <div class="title">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '100%', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  </div>

  <div class="border">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '1px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  </div>

  <div class="sub">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '100%', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  </div>

  <div class="sub">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '100%', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  </div>

  <div class="border-bot">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '1px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
  </div>

  <div class="button">
    <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ height: '100%', width: '100%'}" ></ngx-skeleton-loader>
  </div>
</div>
