import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICarouselEntityDto } from '../dtos/carousel-entity-dto';
import { IFestivalDto } from '../dtos/festival-dto';
import { IFilter } from '../entities/filter';
import { ISort } from '../entities/sort';
import { parameters } from '../parameters';
import { FSProService } from './fspro-service';

@Injectable()
export class FestivalService  extends FSProService {

  private urlBase =  parameters.baseServiceUrl + 'rest/festivals';

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Use this service the get a list of festivals
   */
  getFestivalList(start = 0, limit= 0, sort: ISort = null, search = null, filters: IFilter[] = null): Observable<any[]> {
    const fullUrl = this.buildListUrl(this.urlBase, start, limit, sort, search, filters);

    return this.http.get<any[]>(fullUrl).pipe(map(
      data => {
        data = data.map((obj) => {
          this.convertDates(obj);
          return obj;
        });
        return data;
      }));
  }

  /**
   * Use this service to retrieve a single element for the carousel.
   */
  getOneForCarousel(id: string): Observable<ICarouselEntityDto> {
    return this.http.get<ICarouselEntityDto>(this.urlBase +'/' + id + '/festival-years/last/carousel');
  }

  getFestivalFromSlug(slug: string): Observable<IFestivalDto> {
    return this.http.get<IFestivalDto>(this.urlBase + '/slug/' + slug);
  }
}
