<div class="extra-wrapper">
  <header class="extra-header">
    <h1 class="title">
      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="37" fill-rule="evenodd" stroke-linejoin="round"
        stroke-miterlimit="1.4" clip-rule="evenodd" viewBox="0 0 59 60">
        <g fill-rule="nonzero" fill="#fff">
          <path d="M51 45.5H8.1V7.8h42.7v37.7zM.3 1v58.4h58.3V.9H.4z"></path>
          <path
            d="M32.7 23.9L28.1 30l-3.5-3.8-8.7 9.5h27l-10.2-12zm-16.8-3.5c0-1.3 1.2-2.3 2.7-2.3 1.5 0 2.7 1 2.7 2.3 0 1.4-1.2 2.4-2.7 2.4-1.5 0-2.7-1-2.7-2.4z">
          </path>
        </g>
      </svg>
    </h1>

    <button mat-icon-button disableRipple class="close-btn" (click)="onClose()"></button>
  </header>

  <nav class="extra-categories">
    <ul class="category-list">
      <li *ngIf="photos && photos.length > 0">
        <a href="javascript:void(0)" [ngClass]="{ 'active': currentTab === 'photo' }" (click)="onTabChange('photo')">
          <span class="cat-name">{{'fspro.front.film.filmextras.menu.photos' | translate}}</span>
          <span class="cat-count">({{ photos.length }})</span>
        </a>
      </li>

      <li *ngIf="videos && videos.length > 0">
        <a href="javascript:void(0)" [ngClass]="{ 'active': currentTab === 'video' }" (click)="onTabChange('video')">
          <span class="cat-name">{{'fspro.front.film.filmextras.menu.videos' | translate }}</span>
          <span class="cat-count">({{ videos.length }})</span>
        </a>
      </li>

      <li *ngIf="documents && documents.length > 0">
        <a href="javascript:void(0)" [ngClass]="{ 'active': currentTab === 'document' }"
          (click)="onTabChange('document')">
          <span class="cat-name">{{ 'fspro.front.film.filmextras.menu.documents' | translate }}</span>
          <span class="cat-count">({{ documents.length }})</span>
        </a>
      </li>

      <li *ngIf="posters && posters.length > 0">
        <a href="javascript:void(0)" [ngClass]="{ 'active': currentTab === 'poster' }" (click)="onTabChange('poster')">
          <span class="cat-name">{{'fspro.front.film.filmextras.menu.posters' | translate}}</span>
          <span class="cat-count">({{ posters.length }})</span>
        </a>
      </li>
    </ul>

    <section class="extra-video" *ngIf="currentTab === 'video' && videos && videos.length > 0">
      <nav class="media-nav">
        <ol class="thumb-list">
          <li *ngFor="let video of videos">
            <a (click)="onChangeConfiguration(video)" style="cursor: pointer;">
              <span [ngClass]="{ 'active': video.id === activeLink }">
                {{ video.textButtonPlay }}
              </span>
            </a>
          </li>
        </ol>
      </nav>

      <ol class="medias">
        <li>
          <a [routerLink]="trailerUrl">
            <div class="trailer-media">
              <mat-icon class="icon">play_arrow</mat-icon>
            </div>
          </a>
        </li>
      </ol>
    </section>

    <section class="extra-photo" *ngIf="currentTab === 'photo' && photos && photos.length > 0">
      <fspro-photo-slider
        [activeItem]="activeItem"
        [photos]="photos"
        [isShowDownloadButton]="selectedItem?.isDownloadable"
        [downloadUrl]="getDownloadExtraUrl(selectedItem.id)"
        (onChangeScrollBar)="onChangeScrollBar($event)"
        (onChangeSource)="onChangeSource($event)"
      ></fspro-photo-slider>
    </section>

    <section class="extra-document" *ngIf="currentTab === 'document' && documents && documents.length > 0">
      <figure *ngFor="let item of documents">
        <div class="actions">
          <a href="javascript:void(0)" [href]="item.fileLocation" class="view" target="_blank"
            rel="noreferrer noopener">
          </a>

          <a href="javascript:void(0)" class="download" *ngIf="selectedItem?.isDownloadable"  [href]="getDownloadExtraUrl(selectedItem.id)"></a>
        </div>

        <figcaption>{{ item.fileTitle }}</figcaption>
      </figure>
    </section>

    <section class="extra-poster" *ngIf="currentTab === 'poster' && posters && posters.length > 0">
      <figure class="image-item" *ngFor="let poster of posters">
        <img [appCacheImage]="poster.fileLocation" [alt]="poster.fileName" />
        <a target="_blank" [href]="getDownloadExtraUrl(selectedItem.id)" *ngIf="selectedItem?.isDownloadable" >{{ 'fspro.front.film.extrasposters.button.download' | translate }}</a>
        <!-- <figcaption> {{ poster.fileName}}</figcaption> -->
      </figure>
    </section>
  </nav>
</div>
