import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ActiveAccountComponent } from './components/active-account/active-account.component';
import { ForgotPasswordPageComponent } from './components/forgot-password-page/forgot-password-page.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ResetPasswordPageComponent } from './components/reset-password-page/reset-password-page.component';
import { IsAuthenticatedGuard } from "./guards/is-authenticated-guard";
import { UserBlockedGuard } from './guards/user-blocked-guard.service';


const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'forgotten-password',
    component: ForgotPasswordPageComponent
  },
  {
    path: 'my-bookings',
    loadChildren: () => import('./my-bookings/my-bookings.module').then(m => m.MyBookingsModule),
    canActivate: [IsAuthenticatedGuard, UserBlockedGuard]
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up-profile/sign-up-profile.module').then(m => m.SignUpProfileModule),
    data: { kind: 'join' }
  },
  {
    path: 'my-account/change-password/:encodedEmail/:userHash',
    data: { type: 'reset-password' },
    component: ResetPasswordPageComponent
  },
  {
    path: 'my-account/must-change-password',
    data: { type: 'must-change-password' },
    component: ResetPasswordPageComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'my-account/active/:encodedEmail/:userHash',
    component: ActiveAccountComponent
  },
  {
    path: 'my-account',
    loadChildren: () => import('./sign-up-profile/sign-up-profile.module').then(m => m.SignUpProfileModule),
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'my-feed',
    loadChildren: () => import('./my-feed/my-feed.module').then(m => m.MyFeedModule),
    canActivate: [IsAuthenticatedGuard, UserBlockedGuard]
  },
  {
    path: 'rh',
    loadChildren: () => import('./rh/rh.module').then(m => m.RhModule)
  },
  {
    path: 'i',
    loadChildren: () => import('./rh/rh.module').then(m => m.RhModule)
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./stripe-subscription/stripe-subscription.module').then(m => m.StripeSubscriptionModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canActivate: [UserBlockedGuard]
  },
  {
    path: 'page',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'my-lists',
    loadChildren: () => import('./my-lists/my-lists.module').then(m => m.MyListsModule),
    canActivate: [IsAuthenticatedGuard, UserBlockedGuard]
  },
  {
    path: 'my-notes',
    loadChildren: () => import('./my-notes/my-notes.module').then(m => m.MyNotesModule),
    canActivate: [IsAuthenticatedGuard, UserBlockedGuard]
  },
  {
    path: 'my-invitations',
    loadChildren: () => import('./my-invitation/my-invitation.module').then(m => m.MyInvitationModule),
    canActivate: [UserBlockedGuard]
  },
  {
    path: 'player',
    loadChildren: () => import('./player/player.module').then(m => m.PlayerModule),
    canActivate: [UserBlockedGuard]
  },
  {
    path: ':category',
    loadChildren: () => import('./categories-page/categories-page.module').then(m => m.CategoriesPageModule),
    canActivate: [UserBlockedGuard]
  },
  {
    path: '',
    loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),
    canActivate: [UserBlockedGuard]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        enableTracing: false,
        preloadingStrategy: PreloadAllModules
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
