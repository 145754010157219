import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthenticationService } from "./authentications/authentication.service";

import * as packageJson from './../../package.json';

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient) {
  const { version } = packageJson;

  //return new TranslateHttpLoader(http, '/fspro-translations/dev/');
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?version=${version}`);
}

export function ApplicationInitializerFactory(translate: TranslateService, injector: Injector, authenticationService: AuthenticationService) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    const defaultLang = 'en';
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang(defaultLang);
    try {
      await translate.use(defaultLang).toPromise();
    } catch (err) {
      console.log(err);
    }

    await authenticationService.refreshToken().toPromise().then(
      () => {},
      (error) => {
        console.log('an error occurred refreshing the token: ', error);
        authenticationService.signOut();
      }
    );

  };
}

