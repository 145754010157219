import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IFilmDto } from '../../dtos/film-dto';
import { IProvider } from '../../interfaces/provider.interface';
import { ICollectionDto } from '../../dtos/collection-dto';

const NUMBER_OF_COLLECTION_TO_LOAD = 30;

@Component({
  selector: 'fspro-collection-grid',
  templateUrl: './collection-grid.component.html',
  styleUrls: ['./collection-grid.component.scss']
})
export class CollectionGridComponent {

  @Input() set provider(value: IProvider) {
    if (value) {
      // Tobe sure that if the previous call still waiting for the result,
      // We should skip this.
      this._currentProviderSubscribe?.unsubscribe();
      this._provider = value;
      this.listData = [];
      this.page = 1;
      this.fullyLoaded = false;
      this.loading = true;
      this.loadList();
    }
  };

  get provider(): IProvider {
    return this._provider;
  }

  public listData: IFilmDto[] = [];
  public loading: boolean = true;

  private fullyLoaded: boolean = false;
  private page: number = 1;
  private _provider: IProvider;
  private _currentProviderSubscribe: Subscription;

  constructor() { }

  loadList(): void {
     this._currentProviderSubscribe = this.provider.getEntities((this.page - 1) * NUMBER_OF_COLLECTION_TO_LOAD, NUMBER_OF_COLLECTION_TO_LOAD).subscribe(
      (data: IFilmDto[]) => {
        data.map((item) => this.listData.push(item));
        this.loading = false;
        if (data.length === 0) {
          this.fullyLoaded = true;
        }
        this.page++;
      },
      (error) => console.log(error)
    );
  }

  toCollection = item => item as ICollectionDto;

  onScroll(): void {
    if (!this.loading && !this.fullyLoaded) {
      this.loading = true;
      this.loadList();
    }
  }
}
