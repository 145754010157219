import { Component, Input, OnChanges, OnInit, SimpleChanges, Inject } from '@angular/core';
import { DOCUMENT, formatDate } from '@angular/common';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faRetweet } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IProfessionalField } from 'src/app/shared/entities/professional-field';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { IMembership } from '../../entities/membership';
import { IPrice } from '../../entities/price';
import { MembershipService } from '../../services/membership.service';
import { PriceService } from '../../services/price.service';
import {FsproStripeService} from "../../../stripe-subscription/services/fspro-stripe.service";

export interface IDialogCancelData {
  expiryDate: string;
}

@Component({
  selector: 'fspro-subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.scss']
})
export class SubscriptionStatusComponent implements OnInit {

  public membership: IMembership = null;
  @Input() professionalCategory: IProfessionalField = null;
  @Input() shortCardDisplay: boolean = false;
  private isEligibleForMembership = false;
  billingPortalUrl = null;

  private readonly subscriptionUrl = '/subscribe';

  public membershipStatusMessage;
  private priceList: IPrice[] = null;
  faCaretRight = faCaretRight;
  faRetweet = faRetweet;

  constructor(
    private readonly translateService: TranslateService,
    private readonly priceService: PriceService,
    private readonly dialog: MatDialog,
    private readonly _snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly membershipService: MembershipService,
    private readonly stripeService: FsproStripeService,
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  ngOnInit(): void {
    this.priceService.getList().subscribe(list => this.priceList = list, error => console.log(error));
    this.membershipService.getForCurrentUser().subscribe((membership: IMembership) => {
      this.membership = membership;
      this.computeMembershipStatusMessage();
    });
    this.membershipService.isCurrentUserEligible().subscribe((isEligible) => this.isEligibleForMembership = isEligible);
    this.stripeService.getPortalAddress().subscribe((url) => { this.billingPortalUrl = url.url });
  }

  currentMembershipEndDate(): string {
    return this.membership.lastApprovedMembershipEndDate ?? this.membership.endDate;
  }

  shouldDisplayCurrentStatus(): boolean {
    return this.membership.lastApprovedMembershipEndDate == null;
  }


  computeMembershipStatusMessage(): void {
    if (this.membership === null || Object.keys(this.membership).length === 0) {
      if (this.professionalCategory && this.professionalCategory.name === 'Empty') {
        this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.empty_professional_category');
      } else {
        this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.membership_not_found');
      }
      return;
    }
    const formattedEndDate = formatDate(this.currentMembershipEndDate(), 'dd MMMM yyyy', 'en');

    if (this.membership.type === 'trial') {
      switch (this.membership.status) {
        case 'active':

          let translationKey = 'fspro.front.my-infos.description.membership.active';
          if (this.shouldDisplayCurrentStatus()) {
            translationKey = 'fspro.front.my-infos.description.membership.trial.active';
          }

          this.membershipStatusMessage = this.translateService
            .instant(translationKey, { endDate: formattedEndDate });
          break;
        case 'expired':
          this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.membership_not_found');
          break;
      }
    }

    if (this.membership.type === 'yearly') {
      switch (this.membership.status) {
        case 'active':
        case 'approved':
          if (this.membership.isTrialing) {
            const endDate2 = new Date(this.currentMembershipEndDate());
            endDate2.setDate(endDate2.getDate() + 1);

            if(this.membership.hasPaymentMethod) {
              this.membershipStatusMessage = this.translateService
                .instant('fspro.front.my-infos.description.membership.yearly.active.with.trial',
                  {
                    endDate: formattedEndDate,
                    endDate2: formatDate(endDate2, 'dd MMMM YYYY', 'en')
                  }
                );
            } else {
              this.membershipStatusMessage = this.translateService
                .instant('fspro.front.my-infos.description.membership.yearly.active.with.trial.without.payment.method',
                  {
                    endDate: formattedEndDate,
                    endDate2: formatDate(endDate2, 'dd MMMM YYYY', 'en')
                  }
                );
            }

          } else {

            let translationKey = 'fspro.front.my-infos.description.membership.active';
            if (this.shouldDisplayCurrentStatus()) {
              translationKey = 'fspro.front.my-infos.description.membership.yearly.active';
            }

            this.membershipStatusMessage = this.translateService
              .instant(translationKey, { endDate: formattedEndDate });
          }
          break;
        case 'expired':
          this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.membership.yearly.expired');
          break;
        case 'canceled':
          this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.membership.yearly.canceled');
          break;
        default:
          this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.membership_not_found');
          break;
      }
    }

    if (this.membership.type === 'monthly') {
      switch (this.membership.status) {
        case 'active':
        case 'approved':

          let translationKey = 'fspro.front.my-infos.description.membership.active';
          if (this.shouldDisplayCurrentStatus()) {
            translationKey = 'fspro.front.my-infos.description.membership.monthly.active';
          }

          this.membershipStatusMessage = this.translateService
            .instant(translationKey, { endDate: formattedEndDate });
          break;
        case 'expired':
          this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.membership.monthly.expired');
          break;
        case 'canceled':
          this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.membership.monthly.canceled');
          break;
        default:
          this.membershipStatusMessage = this.translateService.instant('fspro.front.my-infos.description.membership_not_found');
          break;
      }
    }
  }

  getMonthlyPrice(): number {
    if (this.priceList !== null) {
      const monthlyPrices = this.priceList.filter((price: IPrice) => price.type === 'Monthly');
      if (monthlyPrices.length) {
        return (monthlyPrices[0].amountInCents / 100) * 12;
      }
    }
    return 0;
  }

  getYearlyPrice(): number {
    if (this.priceList !== null) {
      const yearlyPrices = this.priceList.filter((price: IPrice) => price.type === 'Yearly');
      if (yearlyPrices.length) {
        return yearlyPrices[0].amountInCents / 100;
      }
    }
    return 0;
  }

  canDisplaySwitchToYearly(): boolean {
    if (this.membership === null || this.shortCardDisplay) {
      return false;
    }
    return (!this.membership.isLegacy && this.membership.isRecurring && this.membership.type === 'monthly' && this.membership.status === 'active');
  }

  canDisplaySwitchToYearlyLegacy(): boolean {
    if (this.membership === null || this.shortCardDisplay) {
      return false;
    }
    return (this.membership.isLegacy && this.membership.isRecurring && this.membership.type === 'monthly' && this.membership.status === 'active');
  }

  canDisplayAwaitingPaymentButton(): boolean {
    if (this.membership === null) {
      return false;
    }
    return !this.membership.isLegacy && this.membership.status === 'awaiting_payment';
  }

  canDisplayUpgradeButton(): boolean {
    return (this.membership === null && this.isEligibleForMembership);
  }

  /**
   * Should be displayed only if not recurring and membership will be ended in 4 months. Otherwise if status is expired.
   */
  canDisplayRenewButton(): boolean {
    if (this.membership === null) {
      return false;
    }
    if (this.membership.status === 'expired' || this.membership.status === 'canceled') {
      return true;
    }
    if (this.membership.isLegacy && !this.membership.isRecurring) {
      const membershipEndDate = new Date(this.currentMembershipEndDate());
      const today = new Date();
      const in4MonthsDate = new Date(today.setMonth(today.getMonth() + 4));
      return membershipEndDate.getTime() <= in4MonthsDate.getTime();
    }
    if (!this.membership.isLegacy && this.membership.willStop && this.membership.status === 'active') {
      return true;
    }
    return false;
  }

  canDisplayCancelButton(): boolean {
    return this.membership !== null && this.membership.hasPaymentMethod && !this.membership.willStop && this.membership.isRecurring && this.membership.status === 'active' && !this.shortCardDisplay;
  }

  canDisplayChangeCardButton(): boolean {
    return this.membership !== null && !this.membership.willStop && this.membership.isRecurring && this.membership.status === 'active' && !this.membership.isLegacy && !this.shortCardDisplay;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if ('membership' in changes) {
  //     this.computeMembershipStatusMessage();
  //   }
  // }

  redirectToSubscriptionPage(): void {
    this.subscriptionUrl.includes('http') || this.subscriptionUrl.includes('https') ?
      this.router.navigateByUrl(this.subscriptionUrl)
      : this.router.navigate([this.subscriptionUrl]);

  }

  redirectToBillingPortal(): void {
    this.document.location.href = this.billingPortalUrl;
  }

  openCancelDialog(): void {
    const endDate = formatDate(this.membership.endDate, 'dd MMMM YYYY', 'en');
    const dialogRef = this.dialog.open(MembershipCancelDialogComponent, {
      width: '600px',
      data: {
        expiryDate: endDate
      }
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result != null) {
          this.membership = result;
          this._snackBar.open('Your membership has been canceled', 'Close', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'info-snackbar'
          });
        }
      }
    );
  }
}

@Component({
  selector: 'fspro-cancel-dialog',
  templateUrl: 'membership-cancel-dialog.html',
  styleUrls: ['./membership-cancel-dialog.scss']
})
export class MembershipCancelDialogComponent {

  public loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<MembershipCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogCancelData,
    private membershipService: MembershipService
  ) { }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  cancelMembership(): void {
    this.loading = true;
    this.membershipService.cancel().subscribe(
      result => {
        this.dialogRef.close(result);
        this.loading = false;
      },
      errorReturned => console.log(errorReturned)
    );
  }
}
