import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { IMediumDto } from "../../dtos/medium-dto";
import {FilmService} from "../../services/film.service";

@Component({
  selector: 'fspro-film-details-extra-dialog',
  templateUrl: 'film-details-extra-dialog.component.html',
  styleUrls: ['./film-details-extra-dialog.component.scss']
})

export class FilmDetailsExtraDialog {
  currentTab: string;

  videos: IMediumDto[];
  photos: IMediumDto[];
  documents: IMediumDto[];
  posters: IMediumDto[];

  activeItem = 0;

  // configurations: IPlayerConfiguration;

  trailerUrl: string;

  selectedItem: IMediumDto;
  public activeLink : number;

  constructor(public dialogRef: MatDialogRef<FilmDetailsExtraDialog>,
              private filmService: FilmService,
              @Inject(MAT_DIALOG_DATA) public data: { medium: IMediumDto[] }
  ) {

    this.videos = [...data.medium.filter(m => m.role === 'trailer' && m.isMainTrailer === false )];
    this.photos = [...data.medium.filter(m => (m.role == 'photo') && m.isPoster === false && m.displayedInGallery === false)];
    this.posters = [...data.medium.filter(m => (m.role === 'picture' || m.role == 'photo') && m.isPoster === true && m.displayedInGallery === false)];
    this.documents = [...data.medium.filter(m => m.role === 'document')];

    const defaultTab = this.photos.length > 0 ? 'photo'
      : this.videos.length > 0 ? 'video'
        : this.documents.length > 0 ? 'document'
          : this.posters.length > 0 ? 'poster' : null;

    this.onTabChange(defaultTab);

    // Set default video configuration
    if (this.videos && this.videos.length > 0) {
      this.onChangeConfiguration(this.videos[0]);
    }
  }

  onChangeScrollBar(index: number) {
    this.activeItem = index;
    this.setSelectedItem();
  }

  onChangeSource(index: number) {
    this.activeItem = index;
    this.setSelectedItem();
  }

  onTabChange(tab: string) {
    this.currentTab = tab;
    // reset to first item of the tab
    this.onChangeSource(0);
  }


  private setSelectedItem() {
    const currentMediumList = this.currentTab === 'video' ? this.videos
      : this.currentTab === 'document' ? this.documents
        : this.currentTab === 'poster' ? this.posters
          : this.currentTab === 'photo' ? this.photos
            : null;
    if (currentMediumList) {
      this.selectedItem = currentMediumList[this.activeItem];
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onChangeConfiguration(data: IMediumDto) {
    this.activeLink = data.id;
    this.trailerUrl = `/player/${data.encodedId}`;
  }

  getDownloadExtraUrl(id: number): string {
    return this.filmService.getExtraDownloadUrl(id);
  }
}
