import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordPatternValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const patterns = [
    { regex: /[a-z]/, message: 'one lowercase letter' },
    { regex: /[A-Z]/, message: 'one uppercase letter' },
    { regex: /\d/, message: 'one number' },
    { regex: /[!@#$%^&*(),.?":{}|<>]/, message: 'one special character' }
  ];

  let feedbackMessage = 'Your password must contain at least ';
  let errorMessages = [];
  let failure = 0;
  for (const pattern of patterns) {
    if (!pattern.regex.test(control.value)) {
      failure++;
      errorMessages.push(pattern.message);
    }
  }

  if (failure > 0) {
    errorMessages.forEach((message, index) => {
      if (index === 0) {
        feedbackMessage += message;
      } else if (index < (errorMessages.length -1)) {
        feedbackMessage += ', ' + message;
      } else {
        feedbackMessage += ' and ' + message;
      }
    });

    feedbackMessage += '.';

    return { patternNotMatched: { message: feedbackMessage } };
  }

  return null;
};
