<article class="film-details-container">
  <section class="film-summary">
    <div class="layout">
      <div class="left">
        <div class="thumb">
          <ngx-skeleton-loader appearance="line" animation="pulse"
            [theme]="{ minWidth: '100px', height: '255px',  'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
          </ngx-skeleton-loader>
        </div>
      </div>

      <div class="right">
        <div class="above">
          <div class="title">
            <ngx-skeleton-loader appearance="line" animation="pulse"
              [theme]="{ minWidth: '100px', height: '70px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader appearance="line" animation="pulse"
            [theme]="{  height: '15px', 'border-radius': 0, width: '60px', 'margin-bottom': 0 }">
          </ngx-skeleton-loader>
          </div>

        </div>

        <div class="subtitle-2">
          <ngx-skeleton-loader appearance="line" animation="pulse"
            [theme]="{ minWidth: '100px', height: '15px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" animation="pulse"
            [theme]="{ minWidth: '100px', height: '15px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" animation="pulse"
            [theme]="{ minWidth: '100px', height: '15px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader appearance="line" animation="pulse"
            [theme]="{ minWidth: '100px', height: '15px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>

    <div class="layout bio-wrapper" *ngIf="hasBio">
      <div class="bio">
        <ngx-skeleton-loader appearance="line" animation="pulse"
          [theme]="{ minWidth: '100px', height: '15px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" animation="pulse"
          [theme]="{ minWidth: '100px', height: '15px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" animation="pulse"
          [theme]="{ minWidth: '100px', height: '15px',  'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" animation="pulse"
          [theme]="{ minWidth: '100px', height: '15px', 'border-radius': 0, width: '100%', 'margin-bottom': 0 }">
        </ngx-skeleton-loader>
      </div>
    </div>
  </section>
</article>
