import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { IAvailabilityWrapperDto } from '../dtos/availability-wrapper-dto';
import { AuthenticationService } from 'src/app/authentications/authentication.service';

@Injectable()
export class FilmHelperService {

  constructor(private translateService: TranslateService, private datePipe: DatePipe, private authenticationService: AuthenticationService) { }

  public computeRestrictionMessage(availabilityWrapper: IAvailabilityWrapperDto): string {

    let message = null;
    let restrictionOtherThanPublication = false;

    let translatedMessage = null;

    if (availabilityWrapper) {
      if (availabilityWrapper?.canWatchFilm) {
        if (availabilityWrapper?.isHidden) {
          if (this.authenticationService.isAdmin || availabilityWrapper.isRHForFilm) {
            message = 'fspro.front.film.availability_message.hidden';
          }
        }
      } else {
        if (availabilityWrapper?.isComingSoon) {
          if (availabilityWrapper?.isGeoBlocked) {
            message = 'fspro.front.film.availability_message.geoblocked';
          } else {
            message = 'fspro.front.film.availability_message.coming_soon';
          }
          restrictionOtherThanPublication = true;
        } else if (availabilityWrapper?.isExpired) {
          message = 'fspro.front.film.availability_message.expired';
          restrictionOtherThanPublication = true;
        } else if (availabilityWrapper?.hasInvitationExpired) {
          message = 'fspro.front.film.availability_message.invitation';
        } else if (availabilityWrapper?.isCategoryBlocked) {
          message = 'fspro.front.film.availability_message.professional_category';
          restrictionOtherThanPublication = true;
        } else if (availabilityWrapper?.isGeoBlocked) {
          message = 'fspro.front.film.availability_message.geoblocked';
          restrictionOtherThanPublication = true;
        } else if (availabilityWrapper?.isScreeningRequestDenied) {
          message = 'fspro.front.film.availability_message.request_denied';
          restrictionOtherThanPublication = true;
        } else if (!availabilityWrapper?.isLoggedIn) {
          message = 'fspro.front.film.availability_message.login';
        }/* else if (!availabilityWrapper?.hasMembership) {
        message = 'fspro.front.film.availability_message.membership_builder';
        restrictionOtherThanPublication = true;
      }*/ else if (availabilityWrapper?.isHidden) {
          message = 'fspro.front.film.availability_message.hidden';
        }
      }
      if (message !== null) {
        this.translateService.get(message).subscribe((text) => translatedMessage = text);
      }
      if (availabilityWrapper?.isPublished && availabilityWrapper?.publishedAt && !restrictionOtherThanPublication) {
        const now = new Date();
        const publicationDate = new Date(availabilityWrapper?.publishedAt);
        if (now < publicationDate) {
          const availableOnMessage = '<br>Available on ' + this.datePipe.transform(availabilityWrapper?.publishedAt, 'dd MMMM yyyy HH:mm', null, 'en-US');
          if (translatedMessage === null) {
            translatedMessage = availableOnMessage;
          } else {
            translatedMessage += availableOnMessage;
          }
        }
      }
      if (availabilityWrapper?.isPublished && availabilityWrapper?.isHidden && availabilityWrapper?.expiredAt && !restrictionOtherThanPublication) {
        const expirationDate = new Date(availabilityWrapper?.expiredAt);
        const now = new Date();
        if (now < expirationDate) {
          const availableUntilMessage = '<br>Available until ' + this.datePipe.transform(availabilityWrapper?.expiredAt, 'dd MMMM yyyy HH:mm', null, 'en-US');

          if (translatedMessage === null) {
            translatedMessage = availableUntilMessage;
          } else {
            translatedMessage += availableUntilMessage;
          }
        }
      }
    }

    return translatedMessage;
  }

}
