import {IFilmDto} from "../dtos/film-dto";

export class ImageUtils {

  protected originalUrl: string|null = null;
  protected film: IFilmDto|null = null;

  getCacheUrl(url: string): string {
    const cachePath = '/media/cache/resolve/';
    const startIndex = url.indexOf(cachePath);

    if (startIndex !== -1) {
      const relativePath = url.substring(startIndex + cachePath.length);
      return '/cache/' + relativePath + '.webp';
    } else {
      return url;
    }
  }

  protected getUrlFromFilm(film: IFilmDto, resolution: string): string {
    if (film.pictures && film.pictures.length > 0) {
      if (resolution === '') {
        return '';
      }
      const selectedPictureDto = film.pictures.find(
        (pictureDto) => pictureDto.size === resolution
      );
      if (selectedPictureDto) {
        this.originalUrl = selectedPictureDto.url.replace('http://', 'https://');
        return selectedPictureDto.url;
      }
    }
    return '';
  }

  protected getPictureResolution(): string {
    const pixelRatio = window.devicePixelRatio;
    const width = window.innerWidth;
    let resolution : string;
    if (width < 768) {
      resolution = 'less_767';
    } else if (width < 1080 && pixelRatio < 2) {
      resolution = '768_1079';
    } else if (width < 1440 && pixelRatio < 2) {
      resolution = '1080_1439';
    } else if (width >= 1440 && pixelRatio < 2) {
      resolution = 'great_1440';
    }  else if (width < 1080 && pixelRatio >= 2) {
      resolution = '768_1079_retina';
    } else if (width < 1440 && pixelRatio >= 2) {
      resolution = '1080_1439_retina';
    } else if (width >= 1440 && pixelRatio >= 2) {
      resolution = 'great_1440_retina';
    } else {
      resolution = 'less_767';
    }
    return resolution;
  }

}
