import { Injectable } from "@angular/core";
import { FSProService } from "./fspro-service";
import { parameters } from "../parameters";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AdminUrlService extends FSProService {
  private urlBase = parameters.baseServiceUrl + 'rest/admin-url';

  constructor(private http: HttpClient) {
    super();
  }

  public getAdminUrl(entityType: string, entityId: number): Observable<string> {
    return this.http.get<string>(`${this.urlBase}/${entityType}/${entityId}`);
  }
}
