import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IMediumDto } from "../../dtos/medium-dto";

@Component({
  selector: 'fspro-photo-gallery-dialog',
  templateUrl: 'photo-gallery-dialog.component.html',
  styleUrls: ['./photo-gallery-dialog.component.scss']
})

export class PhotoGalleryDialogComponent {

  activeItem = 0;
  galleryItems: IMediumDto[];

  constructor(public dialogRef: MatDialogRef<PhotoGalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { medium: IMediumDto[], activeItem: number}) {

    if (data && data.activeItem) {
     this.activeItem = data.activeItem;
    }

    this.galleryItems = data?.medium || [];

  }

  onChangeScrollBar(index: number) {
    this.activeItem = index;
  }

  onChangeSource(index: number) {
    this.activeItem = index;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
