import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parameters } from '../parameters';
import {IFilmDto} from "../dtos/film-dto";

@Injectable()
export class ContactUsService {

  private readonly baseContactUrl = parameters.baseServiceUrl + 'rest/contact';
  constructor(private http: HttpClient) { }

  public sendFeedback(feedback: unknown) {
    return this.http.post<unknown>(`${this.baseContactUrl}/feedback`, feedback);
  }

  public requestUpdate(request: unknown, film: IFilmDto) {
    request['film'] = film.internationalTitle;
    return this.http.post<unknown>(`${this.baseContactUrl}/request-update`, request);
  }

}
